export const ICONS = {
  ab: "IconAB",
  "ab-2": "IconAB2",
  "ab-off": "IconABOff",
  abacus: "IconAbacus",
  "abacus-off": "IconAbacusOff",
  abc: "IconAbc",
  "access-point": "IconAccessPoint",
  "access-point-off": "IconAccessPointOff",
  accessible: "IconAccessible",
  "accessible-filled": "IconAccessibleFilled",
  "accessible-off": "IconAccessibleOff",
  activity: "IconActivity",
  "activity-heartbeat": "IconActivityHeartbeat",
  ad: "IconAd",
  "ad-2": "IconAd2",
  "ad-circle": "IconAdCircle",
  "ad-circle-filled": "IconAdCircleFilled",
  "ad-circle-off": "IconAdCircleOff",
  "ad-filled": "IconAdFilled",
  "ad-off": "IconAdOff",
  "address-book": "IconAddressBook",
  "address-book-off": "IconAddressBookOff",
  adjustments: "IconAdjustments",
  "adjustments-alt": "IconAdjustmentsAlt",
  "adjustments-bolt": "IconAdjustmentsBolt",
  "adjustments-cancel": "IconAdjustmentsCancel",
  "adjustments-check": "IconAdjustmentsCheck",
  "adjustments-code": "IconAdjustmentsCode",
  "adjustments-cog": "IconAdjustmentsCog",
  "adjustments-dollar": "IconAdjustmentsDollar",
  "adjustments-down": "IconAdjustmentsDown",
  "adjustments-exclamation": "IconAdjustmentsExclamation",
  "adjustments-filled": "IconAdjustmentsFilled",
  "adjustments-heart": "IconAdjustmentsHeart",
  "adjustments-horizontal": "IconAdjustmentsHorizontal",
  "adjustments-minus": "IconAdjustmentsMinus",
  "adjustments-off": "IconAdjustmentsOff",
  "adjustments-pause": "IconAdjustmentsPause",
  "adjustments-pin": "IconAdjustmentsPin",
  "adjustments-plus": "IconAdjustmentsPlus",
  "adjustments-question": "IconAdjustmentsQuestion",
  "adjustments-search": "IconAdjustmentsSearch",
  "adjustments-share": "IconAdjustmentsShare",
  "adjustments-star": "IconAdjustmentsStar",
  "adjustments-up": "IconAdjustmentsUp",
  "adjustments-x": "IconAdjustmentsX",
  "aerial-lift": "IconAerialLift",
  affiliate: "IconAffiliate",
  "affiliate-filled": "IconAffiliateFilled",
  ai: "IconAi",
  "air-balloon": "IconAirBalloon",
  "air-conditioning": "IconAirConditioning",
  "air-conditioning-disabled": "IconAirConditioningDisabled",
  "air-traffic-control": "IconAirTrafficControl",
  alarm: "IconAlarm",
  "alarm-average": "IconAlarmAverage",
  "alarm-filled": "IconAlarmFilled",
  "alarm-minus": "IconAlarmMinus",
  "alarm-minus-filled": "IconAlarmMinusFilled",
  "alarm-off": "IconAlarmOff",
  "alarm-plus": "IconAlarmPlus",
  "alarm-plus-filled": "IconAlarmPlusFilled",
  "alarm-snooze": "IconAlarmSnooze",
  "alarm-snooze-filled": "IconAlarmSnoozeFilled",
  album: "IconAlbum",
  "album-off": "IconAlbumOff",
  "alert-circle": "IconAlertCircle",
  "alert-circle-filled": "IconAlertCircleFilled",
  "alert-circle-off": "IconAlertCircleOff",
  "alert-hexagon": "IconAlertHexagon",
  "alert-hexagon-filled": "IconAlertHexagonFilled",
  "alert-hexagon-off": "IconAlertHexagonOff",
  "alert-octagon": "IconAlertOctagon",
  "alert-octagon-filled": "IconAlertOctagonFilled",
  "alert-small": "IconAlertSmall",
  "alert-small-off": "IconAlertSmallOff",
  "alert-square": "IconAlertSquare",
  "alert-square-filled": "IconAlertSquareFilled",
  "alert-square-rounded": "IconAlertSquareRounded",
  "alert-square-rounded-filled": "IconAlertSquareRoundedFilled",
  "alert-square-rounded-off": "IconAlertSquareRoundedOff",
  "alert-triangle": "IconAlertTriangle",
  "alert-triangle-filled": "IconAlertTriangleFilled",
  "alert-triangle-off": "IconAlertTriangleOff",
  alien: "IconAlien",
  "alien-filled": "IconAlienFilled",
  "align-box-bottom-center": "IconAlignBoxBottomCenter",
  "align-box-bottom-center-filled": "IconAlignBoxBottomCenterFilled",
  "align-box-bottom-left": "IconAlignBoxBottomLeft",
  "align-box-bottom-left-filled": "IconAlignBoxBottomLeftFilled",
  "align-box-bottom-right": "IconAlignBoxBottomRight",
  "align-box-bottom-right-filled": "IconAlignBoxBottomRightFilled",
  "align-box-center-bottom": "IconAlignBoxCenterBottom",
  "align-box-center-middle": "IconAlignBoxCenterMiddle",
  "align-box-center-middle-filled": "IconAlignBoxCenterMiddleFilled",
  "align-box-center-stretch": "IconAlignBoxCenterStretch",
  "align-box-center-top": "IconAlignBoxCenterTop",
  "align-box-left-bottom": "IconAlignBoxLeftBottom",
  "align-box-left-bottom-filled": "IconAlignBoxLeftBottomFilled",
  "align-box-left-middle": "IconAlignBoxLeftMiddle",
  "align-box-left-middle-filled": "IconAlignBoxLeftMiddleFilled",
  "align-box-left-stretch": "IconAlignBoxLeftStretch",
  "align-box-left-top": "IconAlignBoxLeftTop",
  "align-box-left-top-filled": "IconAlignBoxLeftTopFilled",
  "align-box-right-bottom": "IconAlignBoxRightBottom",
  "align-box-right-bottom-filled": "IconAlignBoxRightBottomFilled",
  "align-box-right-middle": "IconAlignBoxRightMiddle",
  "align-box-right-middle-filled": "IconAlignBoxRightMiddleFilled",
  "align-box-right-stretch": "IconAlignBoxRightStretch",
  "align-box-right-top": "IconAlignBoxRightTop",
  "align-box-right-top-filled": "IconAlignBoxRightTopFilled",
  "align-box-top-center": "IconAlignBoxTopCenter",
  "align-box-top-center-filled": "IconAlignBoxTopCenterFilled",
  "align-box-top-left": "IconAlignBoxTopLeft",
  "align-box-top-left-filled": "IconAlignBoxTopLeftFilled",
  "align-box-top-right": "IconAlignBoxTopRight",
  "align-box-top-right-filled": "IconAlignBoxTopRightFilled",
  "align-center": "IconAlignCenter",
  "align-justified": "IconAlignJustified",
  "align-left": "IconAlignLeft",
  "align-left-2": "IconAlignLeft2",
  "align-right": "IconAlignRight",
  "align-right-2": "IconAlignRight2",
  alpha: "IconAlpha",
  "alphabet-arabic": "IconAlphabetArabic",
  "alphabet-bangla": "IconAlphabetBangla",
  "alphabet-cyrillic": "IconAlphabetCyrillic",
  "alphabet-greek": "IconAlphabetGreek",
  "alphabet-hebrew": "IconAlphabetHebrew",
  "alphabet-korean": "IconAlphabetKorean",
  "alphabet-latin": "IconAlphabetLatin",
  "alphabet-thai": "IconAlphabetThai",
  alt: "IconAlt",
  ambulance: "IconAmbulance",
  ampersand: "IconAmpersand",
  analyze: "IconAnalyze",
  "analyze-filled": "IconAnalyzeFilled",
  "analyze-off": "IconAnalyzeOff",
  anchor: "IconAnchor",
  "anchor-off": "IconAnchorOff",
  angle: "IconAngle",
  ankh: "IconAnkh",
  antenna: "IconAntenna",
  "antenna-bars-1": "IconAntennaBars1",
  "antenna-bars-2": "IconAntennaBars2",
  "antenna-bars-3": "IconAntennaBars3",
  "antenna-bars-4": "IconAntennaBars4",
  "antenna-bars-5": "IconAntennaBars5",
  "antenna-bars-off": "IconAntennaBarsOff",
  "antenna-off": "IconAntennaOff",
  aperture: "IconAperture",
  "aperture-off": "IconApertureOff",
  api: "IconApi",
  "api-app": "IconApiApp",
  "api-app-off": "IconApiAppOff",
  "api-off": "IconApiOff",
  "app-window": "IconAppWindow",
  "app-window-filled": "IconAppWindowFilled",
  apple: "IconApple",
  apps: "IconApps",
  "apps-filled": "IconAppsFilled",
  "apps-off": "IconAppsOff",
  "archery-arrow": "IconArcheryArrow",
  archive: "IconArchive",
  "archive-filled": "IconArchiveFilled",
  "archive-off": "IconArchiveOff",
  armchair: "IconArmchair",
  "armchair-2": "IconArmchair2",
  "armchair-2-off": "IconArmchair2Off",
  "armchair-off": "IconArmchairOff",
  "arrow-autofit-content": "IconArrowAutofitContent",
  "arrow-autofit-content-filled": "IconArrowAutofitContentFilled",
  "arrow-autofit-down": "IconArrowAutofitDown",
  "arrow-autofit-height": "IconArrowAutofitHeight",
  "arrow-autofit-left": "IconArrowAutofitLeft",
  "arrow-autofit-right": "IconArrowAutofitRight",
  "arrow-autofit-up": "IconArrowAutofitUp",
  "arrow-autofit-width": "IconArrowAutofitWidth",
  "arrow-back": "IconArrowBack",
  "arrow-back-up": "IconArrowBackUp",
  "arrow-back-up-double": "IconArrowBackUpDouble",
  "arrow-badge-down": "IconArrowBadgeDown",
  "arrow-badge-down-filled": "IconArrowBadgeDownFilled",
  "arrow-badge-left": "IconArrowBadgeLeft",
  "arrow-badge-left-filled": "IconArrowBadgeLeftFilled",
  "arrow-badge-right": "IconArrowBadgeRight",
  "arrow-badge-right-filled": "IconArrowBadgeRightFilled",
  "arrow-badge-up": "IconArrowBadgeUp",
  "arrow-badge-up-filled": "IconArrowBadgeUpFilled",
  "arrow-bar-both": "IconArrowBarBoth",
  "arrow-bar-down": "IconArrowBarDown",
  "arrow-bar-left": "IconArrowBarLeft",
  "arrow-bar-right": "IconArrowBarRight",
  "arrow-bar-to-down": "IconArrowBarToDown",
  "arrow-bar-to-left": "IconArrowBarToLeft",
  "arrow-bar-to-right": "IconArrowBarToRight",
  "arrow-bar-to-up": "IconArrowBarToUp",
  "arrow-bar-up": "IconArrowBarUp",
  "arrow-bear-left": "IconArrowBearLeft",
  "arrow-bear-left-2": "IconArrowBearLeft2",
  "arrow-bear-right": "IconArrowBearRight",
  "arrow-bear-right-2": "IconArrowBearRight2",
  "arrow-big-down": "IconArrowBigDown",
  "arrow-big-down-filled": "IconArrowBigDownFilled",
  "arrow-big-down-line": "IconArrowBigDownLine",
  "arrow-big-down-line-filled": "IconArrowBigDownLineFilled",
  "arrow-big-down-lines": "IconArrowBigDownLines",
  "arrow-big-down-lines-filled": "IconArrowBigDownLinesFilled",
  "arrow-big-left": "IconArrowBigLeft",
  "arrow-big-left-filled": "IconArrowBigLeftFilled",
  "arrow-big-left-line": "IconArrowBigLeftLine",
  "arrow-big-left-line-filled": "IconArrowBigLeftLineFilled",
  "arrow-big-left-lines": "IconArrowBigLeftLines",
  "arrow-big-left-lines-filled": "IconArrowBigLeftLinesFilled",
  "arrow-big-right": "IconArrowBigRight",
  "arrow-big-right-filled": "IconArrowBigRightFilled",
  "arrow-big-right-line": "IconArrowBigRightLine",
  "arrow-big-right-line-filled": "IconArrowBigRightLineFilled",
  "arrow-big-right-lines": "IconArrowBigRightLines",
  "arrow-big-right-lines-filled": "IconArrowBigRightLinesFilled",
  "arrow-big-up": "IconArrowBigUp",
  "arrow-big-up-filled": "IconArrowBigUpFilled",
  "arrow-big-up-line": "IconArrowBigUpLine",
  "arrow-big-up-line-filled": "IconArrowBigUpLineFilled",
  "arrow-big-up-lines": "IconArrowBigUpLines",
  "arrow-big-up-lines-filled": "IconArrowBigUpLinesFilled",
  "arrow-bounce": "IconArrowBounce",
  "arrow-capsule": "IconArrowCapsule",
  "arrow-curve-left": "IconArrowCurveLeft",
  "arrow-curve-right": "IconArrowCurveRight",
  "arrow-down": "IconArrowDown",
  "arrow-down-bar": "IconArrowDownBar",
  "arrow-down-circle": "IconArrowDownCircle",
  "arrow-down-from-arc": "IconArrowDownFromArc",
  "arrow-down-left": "IconArrowDownLeft",
  "arrow-down-left-circle": "IconArrowDownLeftCircle",
  "arrow-down-rhombus": "IconArrowDownRhombus",
  "arrow-down-right": "IconArrowDownRight",
  "arrow-down-right-circle": "IconArrowDownRightCircle",
  "arrow-down-square": "IconArrowDownSquare",
  "arrow-down-tail": "IconArrowDownTail",
  "arrow-down-to-arc": "IconArrowDownToArc",
  "arrow-elbow-left": "IconArrowElbowLeft",
  "arrow-elbow-right": "IconArrowElbowRight",
  "arrow-fork": "IconArrowFork",
  "arrow-forward": "IconArrowForward",
  "arrow-forward-up": "IconArrowForwardUp",
  "arrow-forward-up-double": "IconArrowForwardUpDouble",
  "arrow-guide": "IconArrowGuide",
  "arrow-iteration": "IconArrowIteration",
  "arrow-left": "IconArrowLeft",
  "arrow-left-bar": "IconArrowLeftBar",
  "arrow-left-circle": "IconArrowLeftCircle",
  "arrow-left-from-arc": "IconArrowLeftFromArc",
  "arrow-left-rhombus": "IconArrowLeftRhombus",
  "arrow-left-right": "IconArrowLeftRight",
  "arrow-left-square": "IconArrowLeftSquare",
  "arrow-left-tail": "IconArrowLeftTail",
  "arrow-left-to-arc": "IconArrowLeftToArc",
  "arrow-loop-left": "IconArrowLoopLeft",
  "arrow-loop-left-2": "IconArrowLoopLeft2",
  "arrow-loop-right": "IconArrowLoopRight",
  "arrow-loop-right-2": "IconArrowLoopRight2",
  "arrow-merge": "IconArrowMerge",
  "arrow-merge-alt-left": "IconArrowMergeAltLeft",
  "arrow-merge-alt-right": "IconArrowMergeAltRight",
  "arrow-merge-both": "IconArrowMergeBoth",
  "arrow-merge-left": "IconArrowMergeLeft",
  "arrow-merge-right": "IconArrowMergeRight",
  "arrow-move-down": "IconArrowMoveDown",
  "arrow-move-left": "IconArrowMoveLeft",
  "arrow-move-right": "IconArrowMoveRight",
  "arrow-move-up": "IconArrowMoveUp",
  "arrow-narrow-down": "IconArrowNarrowDown",
  "arrow-narrow-left": "IconArrowNarrowLeft",
  "arrow-narrow-right": "IconArrowNarrowRight",
  "arrow-narrow-up": "IconArrowNarrowUp",
  "arrow-ramp-left": "IconArrowRampLeft",
  "arrow-ramp-left-2": "IconArrowRampLeft2",
  "arrow-ramp-left-3": "IconArrowRampLeft3",
  "arrow-ramp-right": "IconArrowRampRight",
  "arrow-ramp-right-2": "IconArrowRampRight2",
  "arrow-ramp-right-3": "IconArrowRampRight3",
  "arrow-right": "IconArrowRight",
  "arrow-right-bar": "IconArrowRightBar",
  "arrow-right-circle": "IconArrowRightCircle",
  "arrow-right-from-arc": "IconArrowRightFromArc",
  "arrow-right-rhombus": "IconArrowRightRhombus",
  "arrow-right-square": "IconArrowRightSquare",
  "arrow-right-tail": "IconArrowRightTail",
  "arrow-right-to-arc": "IconArrowRightToArc",
  "arrow-rotary-first-left": "IconArrowRotaryFirstLeft",
  "arrow-rotary-first-right": "IconArrowRotaryFirstRight",
  "arrow-rotary-last-left": "IconArrowRotaryLastLeft",
  "arrow-rotary-last-right": "IconArrowRotaryLastRight",
  "arrow-rotary-left": "IconArrowRotaryLeft",
  "arrow-rotary-right": "IconArrowRotaryRight",
  "arrow-rotary-straight": "IconArrowRotaryStraight",
  "arrow-roundabout-left": "IconArrowRoundaboutLeft",
  "arrow-roundabout-right": "IconArrowRoundaboutRight",
  "arrow-sharp-turn-left": "IconArrowSharpTurnLeft",
  "arrow-sharp-turn-right": "IconArrowSharpTurnRight",
  "arrow-up": "IconArrowUp",
  "arrow-up-bar": "IconArrowUpBar",
  "arrow-up-circle": "IconArrowUpCircle",
  "arrow-up-from-arc": "IconArrowUpFromArc",
  "arrow-up-left": "IconArrowUpLeft",
  "arrow-up-left-circle": "IconArrowUpLeftCircle",
  "arrow-up-rhombus": "IconArrowUpRhombus",
  "arrow-up-right": "IconArrowUpRight",
  "arrow-up-right-circle": "IconArrowUpRightCircle",
  "arrow-up-square": "IconArrowUpSquare",
  "arrow-up-tail": "IconArrowUpTail",
  "arrow-up-to-arc": "IconArrowUpToArc",
  "arrow-wave-left-down": "IconArrowWaveLeftDown",
  "arrow-wave-left-up": "IconArrowWaveLeftUp",
  "arrow-wave-right-down": "IconArrowWaveRightDown",
  "arrow-wave-right-up": "IconArrowWaveRightUp",
  "arrow-zig-zag": "IconArrowZigZag",
  "arrows-cross": "IconArrowsCross",
  "arrows-diagonal": "IconArrowsDiagonal",
  "arrows-diagonal-2": "IconArrowsDiagonal2",
  "arrows-diagonal-minimize": "IconArrowsDiagonalMinimize",
  "arrows-diagonal-minimize-2": "IconArrowsDiagonalMinimize2",
  "arrows-diff": "IconArrowsDiff",
  "arrows-double-ne-sw": "IconArrowsDoubleNeSw",
  "arrows-double-nw-se": "IconArrowsDoubleNwSe",
  "arrows-double-se-nw": "IconArrowsDoubleSeNw",
  "arrows-double-sw-ne": "IconArrowsDoubleSwNe",
  "arrows-down": "IconArrowsDown",
  "arrows-down-up": "IconArrowsDownUp",
  "arrows-exchange": "IconArrowsExchange",
  "arrows-exchange-2": "IconArrowsExchange2",
  "arrows-horizontal": "IconArrowsHorizontal",
  "arrows-join": "IconArrowsJoin",
  "arrows-join-2": "IconArrowsJoin2",
  "arrows-left": "IconArrowsLeft",
  "arrows-left-down": "IconArrowsLeftDown",
  "arrows-left-right": "IconArrowsLeftRight",
  "arrows-maximize": "IconArrowsMaximize",
  "arrows-minimize": "IconArrowsMinimize",
  "arrows-move": "IconArrowsMove",
  "arrows-move-horizontal": "IconArrowsMoveHorizontal",
  "arrows-move-vertical": "IconArrowsMoveVertical",
  "arrows-random": "IconArrowsRandom",
  "arrows-right": "IconArrowsRight",
  "arrows-right-down": "IconArrowsRightDown",
  "arrows-right-left": "IconArrowsRightLeft",
  "arrows-shuffle": "IconArrowsShuffle",
  "arrows-shuffle-2": "IconArrowsShuffle2",
  "arrows-sort": "IconArrowsSort",
  "arrows-split": "IconArrowsSplit",
  "arrows-split-2": "IconArrowsSplit2",
  "arrows-transfer-down": "IconArrowsTransferDown",
  "arrows-transfer-up": "IconArrowsTransferUp",
  "arrows-up": "IconArrowsUp",
  "arrows-up-down": "IconArrowsUpDown",
  "arrows-up-left": "IconArrowsUpLeft",
  "arrows-up-right": "IconArrowsUpRight",
  "arrows-vertical": "IconArrowsVertical",
  artboard: "IconArtboard",
  "artboard-filled": "IconArtboardFilled",
  "artboard-off": "IconArtboardOff",
  article: "IconArticle",
  "article-filled": "IconArticleFilled",
  "article-off": "IconArticleOff",
  "aspect-ratio": "IconAspectRatio",
  "aspect-ratio-filled": "IconAspectRatioFilled",
  "aspect-ratio-off": "IconAspectRatioOff",
  assembly: "IconAssembly",
  "assembly-filled": "IconAssemblyFilled",
  "assembly-off": "IconAssemblyOff",
  asset: "IconAsset",
  "asset-filled": "IconAssetFilled",
  asterisk: "IconAsterisk",
  "asterisk-simple": "IconAsteriskSimple",
  at: "IconAt",
  "at-off": "IconAtOff",
  atom: "IconAtom",
  "atom-2": "IconAtom2",
  "atom-2-filled": "IconAtom2Filled",
  "atom-off": "IconAtomOff",
  "augmented-reality": "IconAugmentedReality",
  "augmented-reality-2": "IconAugmentedReality2",
  "augmented-reality-off": "IconAugmentedRealityOff",
  "auth-2-fa": "IconAuth2fa",
  "automatic-gearbox": "IconAutomaticGearbox",
  automation: "IconAutomation",
  avocado: "IconAvocado",
  award: "IconAward",
  "award-filled": "IconAwardFilled",
  "award-off": "IconAwardOff",
  axe: "IconAxe",
  "axis-x": "IconAxisX",
  "axis-y": "IconAxisY",
  "baby-bottle": "IconBabyBottle",
  "baby-carriage": "IconBabyCarriage",
  "baby-carriage-filled": "IconBabyCarriageFilled",
  background: "IconBackground",
  backhoe: "IconBackhoe",
  backpack: "IconBackpack",
  "backpack-off": "IconBackpackOff",
  backslash: "IconBackslash",
  backspace: "IconBackspace",
  "backspace-filled": "IconBackspaceFilled",
  badge: "IconBadge",
  "badge-3-d": "IconBadge3d",
  "badge-3-d-filled": "IconBadge3dFilled",
  "badge-4-k": "IconBadge4k",
  "badge-4-k-filled": "IconBadge4kFilled",
  "badge-8-k": "IconBadge8k",
  "badge-8-k-filled": "IconBadge8kFilled",
  "badge-ad": "IconBadgeAd",
  "badge-ad-filled": "IconBadgeAdFilled",
  "badge-ad-off": "IconBadgeAdOff",
  "badge-ar": "IconBadgeAr",
  "badge-ar-filled": "IconBadgeArFilled",
  "badge-cc": "IconBadgeCc",
  "badge-cc-filled": "IconBadgeCcFilled",
  "badge-filled": "IconBadgeFilled",
  "badge-hd": "IconBadgeHd",
  "badge-hd-filled": "IconBadgeHdFilled",
  "badge-off": "IconBadgeOff",
  "badge-sd": "IconBadgeSd",
  "badge-sd-filled": "IconBadgeSdFilled",
  "badge-tm": "IconBadgeTm",
  "badge-tm-filled": "IconBadgeTmFilled",
  "badge-vo": "IconBadgeVo",
  "badge-vo-filled": "IconBadgeVoFilled",
  "badge-vr": "IconBadgeVr",
  "badge-vr-filled": "IconBadgeVrFilled",
  "badge-wc": "IconBadgeWc",
  "badge-wc-filled": "IconBadgeWcFilled",
  badges: "IconBadges",
  "badges-filled": "IconBadgesFilled",
  "badges-off": "IconBadgesOff",
  baguette: "IconBaguette",
  "ball-american-football": "IconBallAmericanFootball",
  "ball-american-football-off": "IconBallAmericanFootballOff",
  "ball-baseball": "IconBallBaseball",
  "ball-basketball": "IconBallBasketball",
  "ball-bowling": "IconBallBowling",
  "ball-football": "IconBallFootball",
  "ball-football-off": "IconBallFootballOff",
  "ball-tennis": "IconBallTennis",
  "ball-volleyball": "IconBallVolleyball",
  balloon: "IconBalloon",
  "balloon-filled": "IconBalloonFilled",
  "balloon-off": "IconBalloonOff",
  ballpen: "IconBallpen",
  "ballpen-filled": "IconBallpenFilled",
  "ballpen-off": "IconBallpenOff",
  ban: "IconBan",
  bandage: "IconBandage",
  "bandage-filled": "IconBandageFilled",
  "bandage-off": "IconBandageOff",
  barbell: "IconBarbell",
  "barbell-filled": "IconBarbellFilled",
  "barbell-off": "IconBarbellOff",
  barcode: "IconBarcode",
  "barcode-off": "IconBarcodeOff",
  barrel: "IconBarrel",
  "barrel-off": "IconBarrelOff",
  "barrier-block": "IconBarrierBlock",
  "barrier-block-filled": "IconBarrierBlockFilled",
  "barrier-block-off": "IconBarrierBlockOff",
  baseline: "IconBaseline",
  "baseline-density-large": "IconBaselineDensityLarge",
  "baseline-density-medium": "IconBaselineDensityMedium",
  "baseline-density-small": "IconBaselineDensitySmall",
  basket: "IconBasket",
  "basket-bolt": "IconBasketBolt",
  "basket-cancel": "IconBasketCancel",
  "basket-check": "IconBasketCheck",
  "basket-code": "IconBasketCode",
  "basket-cog": "IconBasketCog",
  "basket-discount": "IconBasketDiscount",
  "basket-dollar": "IconBasketDollar",
  "basket-down": "IconBasketDown",
  "basket-exclamation": "IconBasketExclamation",
  "basket-filled": "IconBasketFilled",
  "basket-heart": "IconBasketHeart",
  "basket-minus": "IconBasketMinus",
  "basket-off": "IconBasketOff",
  "basket-pause": "IconBasketPause",
  "basket-pin": "IconBasketPin",
  "basket-plus": "IconBasketPlus",
  "basket-question": "IconBasketQuestion",
  "basket-search": "IconBasketSearch",
  "basket-share": "IconBasketShare",
  "basket-star": "IconBasketStar",
  "basket-up": "IconBasketUp",
  "basket-x": "IconBasketX",
  bat: "IconBat",
  bath: "IconBath",
  "bath-filled": "IconBathFilled",
  "bath-off": "IconBathOff",
  battery: "IconBattery",
  "battery-1": "IconBattery1",
  "battery-1-filled": "IconBattery1Filled",
  "battery-2": "IconBattery2",
  "battery-2-filled": "IconBattery2Filled",
  "battery-3": "IconBattery3",
  "battery-3-filled": "IconBattery3Filled",
  "battery-4": "IconBattery4",
  "battery-4-filled": "IconBattery4Filled",
  "battery-automotive": "IconBatteryAutomotive",
  "battery-charging": "IconBatteryCharging",
  "battery-charging-2": "IconBatteryCharging2",
  "battery-eco": "IconBatteryEco",
  "battery-exclamation": "IconBatteryExclamation",
  "battery-filled": "IconBatteryFilled",
  "battery-off": "IconBatteryOff",
  "battery-vertical": "IconBatteryVertical",
  "battery-vertical-1": "IconBatteryVertical1",
  "battery-vertical-2": "IconBatteryVertical2",
  "battery-vertical-3": "IconBatteryVertical3",
  "battery-vertical-4": "IconBatteryVertical4",
  "battery-vertical-charging": "IconBatteryVerticalCharging",
  "battery-vertical-charging-2": "IconBatteryVerticalCharging2",
  "battery-vertical-eco": "IconBatteryVerticalEco",
  "battery-vertical-exclamation": "IconBatteryVerticalExclamation",
  "battery-vertical-off": "IconBatteryVerticalOff",
  beach: "IconBeach",
  "beach-off": "IconBeachOff",
  bed: "IconBed",
  "bed-filled": "IconBedFilled",
  "bed-flat": "IconBedFlat",
  "bed-flat-filled": "IconBedFlatFilled",
  "bed-off": "IconBedOff",
  beer: "IconBeer",
  "beer-filled": "IconBeerFilled",
  "beer-off": "IconBeerOff",
  bell: "IconBell",
  "bell-bolt": "IconBellBolt",
  "bell-cancel": "IconBellCancel",
  "bell-check": "IconBellCheck",
  "bell-code": "IconBellCode",
  "bell-cog": "IconBellCog",
  "bell-dollar": "IconBellDollar",
  "bell-down": "IconBellDown",
  "bell-exclamation": "IconBellExclamation",
  "bell-filled": "IconBellFilled",
  "bell-heart": "IconBellHeart",
  "bell-minus": "IconBellMinus",
  "bell-minus-filled": "IconBellMinusFilled",
  "bell-off": "IconBellOff",
  "bell-pause": "IconBellPause",
  "bell-pin": "IconBellPin",
  "bell-plus": "IconBellPlus",
  "bell-plus-filled": "IconBellPlusFilled",
  "bell-question": "IconBellQuestion",
  "bell-ringing": "IconBellRinging",
  "bell-ringing-2": "IconBellRinging2",
  "bell-ringing-2-filled": "IconBellRinging2Filled",
  "bell-ringing-filled": "IconBellRingingFilled",
  "bell-school": "IconBellSchool",
  "bell-search": "IconBellSearch",
  "bell-share": "IconBellShare",
  "bell-star": "IconBellStar",
  "bell-up": "IconBellUp",
  "bell-x": "IconBellX",
  "bell-x-filled": "IconBellXFilled",
  "bell-z": "IconBellZ",
  "bell-z-filled": "IconBellZFilled",
  beta: "IconBeta",
  bible: "IconBible",
  bike: "IconBike",
  "bike-off": "IconBikeOff",
  binary: "IconBinary",
  "binary-off": "IconBinaryOff",
  "binary-tree": "IconBinaryTree",
  "binary-tree-2": "IconBinaryTree2",
  "binary-tree-2-filled": "IconBinaryTree2Filled",
  "binary-tree-filled": "IconBinaryTreeFilled",
  binoculars: "IconBinoculars",
  "binoculars-filled": "IconBinocularsFilled",
  biohazard: "IconBiohazard",
  "biohazard-filled": "IconBiohazardFilled",
  "biohazard-off": "IconBiohazardOff",
  blade: "IconBlade",
  "blade-filled": "IconBladeFilled",
  bleach: "IconBleach",
  "bleach-chlorine": "IconBleachChlorine",
  "bleach-no-chlorine": "IconBleachNoChlorine",
  "bleach-off": "IconBleachOff",
  "blend-mode": "IconBlendMode",
  blender: "IconBlender",
  blob: "IconBlob",
  "blob-filled": "IconBlobFilled",
  blockquote: "IconBlockquote",
  bluetooth: "IconBluetooth",
  "bluetooth-connected": "IconBluetoothConnected",
  "bluetooth-off": "IconBluetoothOff",
  "bluetooth-x": "IconBluetoothX",
  blur: "IconBlur",
  "blur-off": "IconBlurOff",
  bmp: "IconBmp",
  "body-scan": "IconBodyScan",
  bold: "IconBold",
  "bold-off": "IconBoldOff",
  bolt: "IconBolt",
  "bolt-off": "IconBoltOff",
  bomb: "IconBomb",
  "bomb-filled": "IconBombFilled",
  bone: "IconBone",
  "bone-filled": "IconBoneFilled",
  "bone-off": "IconBoneOff",
  bong: "IconBong",
  "bong-off": "IconBongOff",
  book: "IconBook",
  "book-2": "IconBook2",
  "book-download": "IconBookDownload",
  "book-filled": "IconBookFilled",
  "book-off": "IconBookOff",
  "book-upload": "IconBookUpload",
  bookmark: "IconBookmark",
  "bookmark-ai": "IconBookmarkAi",
  "bookmark-edit": "IconBookmarkEdit",
  "bookmark-filled": "IconBookmarkFilled",
  "bookmark-minus": "IconBookmarkMinus",
  "bookmark-off": "IconBookmarkOff",
  "bookmark-plus": "IconBookmarkPlus",
  "bookmark-question": "IconBookmarkQuestion",
  bookmarks: "IconBookmarks",
  "bookmarks-filled": "IconBookmarksFilled",
  "bookmarks-off": "IconBookmarksOff",
  books: "IconBooks",
  "books-off": "IconBooksOff",
  boom: "IconBoom",
  "boom-filled": "IconBoomFilled",
  "border-all": "IconBorderAll",
  "border-bottom": "IconBorderBottom",
  "border-bottom-plus": "IconBorderBottomPlus",
  "border-corner-ios": "IconBorderCornerIos",
  "border-corner-pill": "IconBorderCornerPill",
  "border-corner-rounded": "IconBorderCornerRounded",
  "border-corner-square": "IconBorderCornerSquare",
  "border-corners": "IconBorderCorners",
  "border-horizontal": "IconBorderHorizontal",
  "border-inner": "IconBorderInner",
  "border-left": "IconBorderLeft",
  "border-left-plus": "IconBorderLeftPlus",
  "border-none": "IconBorderNone",
  "border-outer": "IconBorderOuter",
  "border-radius": "IconBorderRadius",
  "border-right": "IconBorderRight",
  "border-right-plus": "IconBorderRightPlus",
  "border-sides": "IconBorderSides",
  "border-style": "IconBorderStyle",
  "border-style-2": "IconBorderStyle2",
  "border-top": "IconBorderTop",
  "border-top-plus": "IconBorderTopPlus",
  "border-vertical": "IconBorderVertical",
  bottle: "IconBottle",
  "bottle-filled": "IconBottleFilled",
  "bottle-off": "IconBottleOff",
  "bounce-left": "IconBounceLeft",
  "bounce-left-filled": "IconBounceLeftFilled",
  "bounce-right": "IconBounceRight",
  "bounce-right-filled": "IconBounceRightFilled",
  bow: "IconBow",
  "bow-filled": "IconBowFilled",
  bowl: "IconBowl",
  "bowl-chopsticks": "IconBowlChopsticks",
  "bowl-chopsticks-filled": "IconBowlChopsticksFilled",
  "bowl-filled": "IconBowlFilled",
  "bowl-spoon": "IconBowlSpoon",
  "bowl-spoon-filled": "IconBowlSpoonFilled",
  box: "IconBox",
  "box-align-bottom": "IconBoxAlignBottom",
  "box-align-bottom-filled": "IconBoxAlignBottomFilled",
  "box-align-bottom-left": "IconBoxAlignBottomLeft",
  "box-align-bottom-left-filled": "IconBoxAlignBottomLeftFilled",
  "box-align-bottom-right": "IconBoxAlignBottomRight",
  "box-align-bottom-right-filled": "IconBoxAlignBottomRightFilled",
  "box-align-left": "IconBoxAlignLeft",
  "box-align-left-filled": "IconBoxAlignLeftFilled",
  "box-align-right": "IconBoxAlignRight",
  "box-align-right-filled": "IconBoxAlignRightFilled",
  "box-align-top": "IconBoxAlignTop",
  "box-align-top-filled": "IconBoxAlignTopFilled",
  "box-align-top-left": "IconBoxAlignTopLeft",
  "box-align-top-left-filled": "IconBoxAlignTopLeftFilled",
  "box-align-top-right": "IconBoxAlignTopRight",
  "box-align-top-right-filled": "IconBoxAlignTopRightFilled",
  "box-margin": "IconBoxMargin",
  "box-model": "IconBoxModel",
  "box-model-2": "IconBoxModel2",
  "box-model-2-off": "IconBoxModel2Off",
  "box-model-off": "IconBoxModelOff",
  "box-multiple": "IconBoxMultiple",
  "box-multiple-0": "IconBoxMultiple0",
  "box-multiple-1": "IconBoxMultiple1",
  "box-multiple-2": "IconBoxMultiple2",
  "box-multiple-3": "IconBoxMultiple3",
  "box-multiple-4": "IconBoxMultiple4",
  "box-multiple-5": "IconBoxMultiple5",
  "box-multiple-6": "IconBoxMultiple6",
  "box-multiple-7": "IconBoxMultiple7",
  "box-multiple-8": "IconBoxMultiple8",
  "box-multiple-9": "IconBoxMultiple9",
  "box-off": "IconBoxOff",
  "box-padding": "IconBoxPadding",
  braces: "IconBraces",
  "braces-off": "IconBracesOff",
  brackets: "IconBrackets",
  "brackets-angle": "IconBracketsAngle",
  "brackets-angle-off": "IconBracketsAngleOff",
  "brackets-contain": "IconBracketsContain",
  "brackets-contain-end": "IconBracketsContainEnd",
  "brackets-contain-start": "IconBracketsContainStart",
  "brackets-off": "IconBracketsOff",
  braille: "IconBraille",
  brain: "IconBrain",
  "brand-4-chan": "IconBrand4chan",
  "brand-abstract": "IconBrandAbstract",
  "brand-adobe": "IconBrandAdobe",
  "brand-adobe-after-effect": "IconBrandAdobeAfterEffect",
  "brand-adobe-illustrator": "IconBrandAdobeIllustrator",
  "brand-adobe-indesign": "IconBrandAdobeIndesign",
  "brand-adobe-photoshop": "IconBrandAdobePhotoshop",
  "brand-adobe-premier": "IconBrandAdobePremier",
  "brand-adobe-xd": "IconBrandAdobeXd",
  "brand-adonis-js": "IconBrandAdonisJs",
  "brand-airbnb": "IconBrandAirbnb",
  "brand-airtable": "IconBrandAirtable",
  "brand-algolia": "IconBrandAlgolia",
  "brand-alipay": "IconBrandAlipay",
  "brand-alpine-js": "IconBrandAlpineJs",
  "brand-amazon": "IconBrandAmazon",
  "brand-amd": "IconBrandAmd",
  "brand-amigo": "IconBrandAmigo",
  "brand-among-us": "IconBrandAmongUs",
  "brand-android": "IconBrandAndroid",
  "brand-angular": "IconBrandAngular",
  "brand-ansible": "IconBrandAnsible",
  "brand-ao-3": "IconBrandAo3",
  "brand-appgallery": "IconBrandAppgallery",
  "brand-apple": "IconBrandApple",
  "brand-apple-arcade": "IconBrandAppleArcade",
  "brand-apple-filled": "IconBrandAppleFilled",
  "brand-apple-news": "IconBrandAppleNews",
  "brand-apple-podcast": "IconBrandApplePodcast",
  "brand-appstore": "IconBrandAppstore",
  "brand-arc": "IconBrandArc",
  "brand-asana": "IconBrandAsana",
  "brand-astro": "IconBrandAstro",
  "brand-auth-0": "IconBrandAuth0",
  "brand-aws": "IconBrandAws",
  "brand-azure": "IconBrandAzure",
  "brand-backbone": "IconBrandBackbone",
  "brand-badoo": "IconBrandBadoo",
  "brand-baidu": "IconBrandBaidu",
  "brand-bandcamp": "IconBrandBandcamp",
  "brand-bandlab": "IconBrandBandlab",
  "brand-beats": "IconBrandBeats",
  "brand-behance": "IconBrandBehance",
  "brand-bilibili": "IconBrandBilibili",
  "brand-binance": "IconBrandBinance",
  "brand-bing": "IconBrandBing",
  "brand-bitbucket": "IconBrandBitbucket",
  "brand-blackberry": "IconBrandBlackberry",
  "brand-blender": "IconBrandBlender",
  "brand-blogger": "IconBrandBlogger",
  "brand-bluesky": "IconBrandBluesky",
  "brand-booking": "IconBrandBooking",
  "brand-bootstrap": "IconBrandBootstrap",
  "brand-bulma": "IconBrandBulma",
  "brand-bumble": "IconBrandBumble",
  "brand-bunpo": "IconBrandBunpo",
  "brand-c-sharp": "IconBrandCSharp",
  "brand-cake": "IconBrandCake",
  "brand-cakephp": "IconBrandCakephp",
  "brand-campaignmonitor": "IconBrandCampaignmonitor",
  "brand-carbon": "IconBrandCarbon",
  "brand-cashapp": "IconBrandCashapp",
  "brand-chrome": "IconBrandChrome",
  "brand-cinema-4-d": "IconBrandCinema4d",
  "brand-citymapper": "IconBrandCitymapper",
  "brand-cloudflare": "IconBrandCloudflare",
  "brand-codecov": "IconBrandCodecov",
  "brand-codepen": "IconBrandCodepen",
  "brand-codesandbox": "IconBrandCodesandbox",
  "brand-cohost": "IconBrandCohost",
  "brand-coinbase": "IconBrandCoinbase",
  "brand-comedy-central": "IconBrandComedyCentral",
  "brand-coreos": "IconBrandCoreos",
  "brand-couchdb": "IconBrandCouchdb",
  "brand-couchsurfing": "IconBrandCouchsurfing",
  "brand-cpp": "IconBrandCpp",
  "brand-craft": "IconBrandCraft",
  "brand-crunchbase": "IconBrandCrunchbase",
  "brand-css-3": "IconBrandCss3",
  "brand-ctemplar": "IconBrandCtemplar",
  "brand-cucumber": "IconBrandCucumber",
  "brand-cupra": "IconBrandCupra",
  "brand-cypress": "IconBrandCypress",
  "brand-d-3": "IconBrandD3",
  "brand-databricks": "IconBrandDatabricks",
  "brand-days-counter": "IconBrandDaysCounter",
  "brand-dcos": "IconBrandDcos",
  "brand-debian": "IconBrandDebian",
  "brand-deezer": "IconBrandDeezer",
  "brand-deliveroo": "IconBrandDeliveroo",
  "brand-deno": "IconBrandDeno",
  "brand-denodo": "IconBrandDenodo",
  "brand-deviantart": "IconBrandDeviantart",
  "brand-digg": "IconBrandDigg",
  "brand-dingtalk": "IconBrandDingtalk",
  "brand-discord": "IconBrandDiscord",
  "brand-discord-filled": "IconBrandDiscordFilled",
  "brand-disney": "IconBrandDisney",
  "brand-disqus": "IconBrandDisqus",
  "brand-django": "IconBrandDjango",
  "brand-docker": "IconBrandDocker",
  "brand-doctrine": "IconBrandDoctrine",
  "brand-dolby-digital": "IconBrandDolbyDigital",
  "brand-douban": "IconBrandDouban",
  "brand-dribbble": "IconBrandDribbble",
  "brand-dribbble-filled": "IconBrandDribbbleFilled",
  "brand-drops": "IconBrandDrops",
  "brand-drupal": "IconBrandDrupal",
  "brand-edge": "IconBrandEdge",
  "brand-elastic": "IconBrandElastic",
  "brand-electronic-arts": "IconBrandElectronicArts",
  "brand-ember": "IconBrandEmber",
  "brand-envato": "IconBrandEnvato",
  "brand-etsy": "IconBrandEtsy",
  "brand-evernote": "IconBrandEvernote",
  "brand-facebook": "IconBrandFacebook",
  "brand-facebook-filled": "IconBrandFacebookFilled",
  "brand-feedly": "IconBrandFeedly",
  "brand-figma": "IconBrandFigma",
  "brand-filezilla": "IconBrandFilezilla",
  "brand-finder": "IconBrandFinder",
  "brand-firebase": "IconBrandFirebase",
  "brand-firefox": "IconBrandFirefox",
  "brand-fiverr": "IconBrandFiverr",
  "brand-flickr": "IconBrandFlickr",
  "brand-flightradar-24": "IconBrandFlightradar24",
  "brand-flipboard": "IconBrandFlipboard",
  "brand-flutter": "IconBrandFlutter",
  "brand-fortnite": "IconBrandFortnite",
  "brand-foursquare": "IconBrandFoursquare",
  "brand-framer": "IconBrandFramer",
  "brand-framer-motion": "IconBrandFramerMotion",
  "brand-funimation": "IconBrandFunimation",
  "brand-gatsby": "IconBrandGatsby",
  "brand-git": "IconBrandGit",
  "brand-github": "IconBrandGithub",
  "brand-github-copilot": "IconBrandGithubCopilot",
  "brand-github-filled": "IconBrandGithubFilled",
  "brand-gitlab": "IconBrandGitlab",
  "brand-gmail": "IconBrandGmail",
  "brand-golang": "IconBrandGolang",
  "brand-google": "IconBrandGoogle",
  "brand-google-analytics": "IconBrandGoogleAnalytics",
  "brand-google-big-query": "IconBrandGoogleBigQuery",
  "brand-google-drive": "IconBrandGoogleDrive",
  "brand-google-filled": "IconBrandGoogleFilled",
  "brand-google-fit": "IconBrandGoogleFit",
  "brand-google-home": "IconBrandGoogleHome",
  "brand-google-maps": "IconBrandGoogleMaps",
  "brand-google-one": "IconBrandGoogleOne",
  "brand-google-photos": "IconBrandGooglePhotos",
  "brand-google-play": "IconBrandGooglePlay",
  "brand-google-podcasts": "IconBrandGooglePodcasts",
  "brand-grammarly": "IconBrandGrammarly",
  "brand-graphql": "IconBrandGraphql",
  "brand-gravatar": "IconBrandGravatar",
  "brand-grindr": "IconBrandGrindr",
  "brand-guardian": "IconBrandGuardian",
  "brand-gumroad": "IconBrandGumroad",
  "brand-hackerrank": "IconBrandHackerrank",
  "brand-hbo": "IconBrandHbo",
  "brand-headlessui": "IconBrandHeadlessui",
  "brand-hexo": "IconBrandHexo",
  "brand-hipchat": "IconBrandHipchat",
  "brand-html-5": "IconBrandHtml5",
  "brand-inertia": "IconBrandInertia",
  "brand-instagram": "IconBrandInstagram",
  "brand-intercom": "IconBrandIntercom",
  "brand-itch": "IconBrandItch",
  "brand-javascript": "IconBrandJavascript",
  "brand-juejin": "IconBrandJuejin",
  "brand-kako-talk": "IconBrandKakoTalk",
  "brand-kbin": "IconBrandKbin",
  "brand-kick": "IconBrandKick",
  "brand-kickstarter": "IconBrandKickstarter",
  "brand-kotlin": "IconBrandKotlin",
  "brand-laravel": "IconBrandLaravel",
  "brand-lastfm": "IconBrandLastfm",
  "brand-leetcode": "IconBrandLeetcode",
  "brand-letterboxd": "IconBrandLetterboxd",
  "brand-line": "IconBrandLine",
  "brand-linkedin": "IconBrandLinkedin",
  "brand-linktree": "IconBrandLinktree",
  "brand-linqpad": "IconBrandLinqpad",
  "brand-livewire": "IconBrandLivewire",
  "brand-loom": "IconBrandLoom",
  "brand-mailgun": "IconBrandMailgun",
  "brand-mantine": "IconBrandMantine",
  "brand-mastercard": "IconBrandMastercard",
  "brand-mastodon": "IconBrandMastodon",
  "brand-matrix": "IconBrandMatrix",
  "brand-mcdonalds": "IconBrandMcdonalds",
  "brand-medium": "IconBrandMedium",
  "brand-meetup": "IconBrandMeetup",
  "brand-mercedes": "IconBrandMercedes",
  "brand-messenger": "IconBrandMessenger",
  "brand-meta": "IconBrandMeta",
  "brand-metabrainz": "IconBrandMetabrainz",
  "brand-minecraft": "IconBrandMinecraft",
  "brand-miniprogram": "IconBrandMiniprogram",
  "brand-mixpanel": "IconBrandMixpanel",
  "brand-monday": "IconBrandMonday",
  "brand-mongodb": "IconBrandMongodb",
  "brand-my-oppo": "IconBrandMyOppo",
  "brand-mysql": "IconBrandMysql",
  "brand-national-geographic": "IconBrandNationalGeographic",
  "brand-nem": "IconBrandNem",
  "brand-netbeans": "IconBrandNetbeans",
  "brand-netease-music": "IconBrandNeteaseMusic",
  "brand-netflix": "IconBrandNetflix",
  "brand-nexo": "IconBrandNexo",
  "brand-nextcloud": "IconBrandNextcloud",
  "brand-nextjs": "IconBrandNextjs",
  "brand-nodejs": "IconBrandNodejs",
  "brand-nord-vpn": "IconBrandNordVpn",
  "brand-notion": "IconBrandNotion",
  "brand-npm": "IconBrandNpm",
  "brand-nuxt": "IconBrandNuxt",
  "brand-nytimes": "IconBrandNytimes",
  "brand-oauth": "IconBrandOauth",
  "brand-office": "IconBrandOffice",
  "brand-ok-ru": "IconBrandOkRu",
  "brand-onedrive": "IconBrandOnedrive",
  "brand-onlyfans": "IconBrandOnlyfans",
  "brand-open-source": "IconBrandOpenSource",
  "brand-openai": "IconBrandOpenai",
  "brand-openvpn": "IconBrandOpenvpn",
  "brand-opera": "IconBrandOpera",
  "brand-pagekit": "IconBrandPagekit",
  "brand-parsinta": "IconBrandParsinta",
  "brand-patreon": "IconBrandPatreon",
  "brand-patreon-filled": "IconBrandPatreonFilled",
  "brand-paypal": "IconBrandPaypal",
  "brand-paypal-filled": "IconBrandPaypalFilled",
  "brand-paypay": "IconBrandPaypay",
  "brand-peanut": "IconBrandPeanut",
  "brand-pepsi": "IconBrandPepsi",
  "brand-php": "IconBrandPhp",
  "brand-picsart": "IconBrandPicsart",
  "brand-pinterest": "IconBrandPinterest",
  "brand-planetscale": "IconBrandPlanetscale",
  "brand-pnpm": "IconBrandPnpm",
  "brand-pocket": "IconBrandPocket",
  "brand-polymer": "IconBrandPolymer",
  "brand-powershell": "IconBrandPowershell",
  "brand-printables": "IconBrandPrintables",
  "brand-prisma": "IconBrandPrisma",
  "brand-producthunt": "IconBrandProducthunt",
  "brand-pushbullet": "IconBrandPushbullet",
  "brand-pushover": "IconBrandPushover",
  "brand-python": "IconBrandPython",
  "brand-qq": "IconBrandQq",
  "brand-radix-ui": "IconBrandRadixUi",
  "brand-react": "IconBrandReact",
  "brand-react-native": "IconBrandReactNative",
  "brand-reason": "IconBrandReason",
  "brand-reddit": "IconBrandReddit",
  "brand-redhat": "IconBrandRedhat",
  "brand-redux": "IconBrandRedux",
  "brand-revolut": "IconBrandRevolut",
  "brand-rumble": "IconBrandRumble",
  "brand-rust": "IconBrandRust",
  "brand-safari": "IconBrandSafari",
  "brand-samsungpass": "IconBrandSamsungpass",
  "brand-sass": "IconBrandSass",
  "brand-sentry": "IconBrandSentry",
  "brand-sharik": "IconBrandSharik",
  "brand-shazam": "IconBrandShazam",
  "brand-shopee": "IconBrandShopee",
  "brand-sketch": "IconBrandSketch",
  "brand-skype": "IconBrandSkype",
  "brand-slack": "IconBrandSlack",
  "brand-snapchat": "IconBrandSnapchat",
  "brand-snapseed": "IconBrandSnapseed",
  "brand-snowflake": "IconBrandSnowflake",
  "brand-socket-io": "IconBrandSocketIo",
  "brand-solidjs": "IconBrandSolidjs",
  "brand-soundcloud": "IconBrandSoundcloud",
  "brand-spacehey": "IconBrandSpacehey",
  "brand-speedtest": "IconBrandSpeedtest",
  "brand-spotify": "IconBrandSpotify",
  "brand-spotify-filled": "IconBrandSpotifyFilled",
  "brand-stackoverflow": "IconBrandStackoverflow",
  "brand-stackshare": "IconBrandStackshare",
  "brand-steam": "IconBrandSteam",
  "brand-stocktwits": "IconBrandStocktwits",
  "brand-storj": "IconBrandStorj",
  "brand-storybook": "IconBrandStorybook",
  "brand-storytel": "IconBrandStorytel",
  "brand-strava": "IconBrandStrava",
  "brand-stripe": "IconBrandStripe",
  "brand-sublime-text": "IconBrandSublimeText",
  "brand-sugarizer": "IconBrandSugarizer",
  "brand-supabase": "IconBrandSupabase",
  "brand-superhuman": "IconBrandSuperhuman",
  "brand-supernova": "IconBrandSupernova",
  "brand-surfshark": "IconBrandSurfshark",
  "brand-svelte": "IconBrandSvelte",
  "brand-swift": "IconBrandSwift",
  "brand-symfony": "IconBrandSymfony",
  "brand-tabler": "IconBrandTabler",
  "brand-tailwind": "IconBrandTailwind",
  "brand-taobao": "IconBrandTaobao",
  "brand-teams": "IconBrandTeams",
  "brand-ted": "IconBrandTed",
  "brand-telegram": "IconBrandTelegram",
  "brand-terraform": "IconBrandTerraform",
  "brand-tether": "IconBrandTether",
  "brand-thingiverse": "IconBrandThingiverse",
  "brand-threads": "IconBrandThreads",
  "brand-threejs": "IconBrandThreejs",
  "brand-tidal": "IconBrandTidal",
  "brand-tiktok": "IconBrandTiktok",
  "brand-tiktok-filled": "IconBrandTiktokFilled",
  "brand-tinder": "IconBrandTinder",
  "brand-topbuzz": "IconBrandTopbuzz",
  "brand-torchain": "IconBrandTorchain",
  "brand-toyota": "IconBrandToyota",
  "brand-trello": "IconBrandTrello",
  "brand-tripadvisor": "IconBrandTripadvisor",
  "brand-tumblr": "IconBrandTumblr",
  "brand-twilio": "IconBrandTwilio",
  "brand-twitch": "IconBrandTwitch",
  "brand-twitter": "IconBrandTwitter",
  "brand-twitter-filled": "IconBrandTwitterFilled",
  "brand-typescript": "IconBrandTypescript",
  "brand-uber": "IconBrandUber",
  "brand-ubuntu": "IconBrandUbuntu",
  "brand-unity": "IconBrandUnity",
  "brand-unsplash": "IconBrandUnsplash",
  "brand-upwork": "IconBrandUpwork",
  "brand-valorant": "IconBrandValorant",
  "brand-vercel": "IconBrandVercel",
  "brand-vimeo": "IconBrandVimeo",
  "brand-vinted": "IconBrandVinted",
  "brand-visa": "IconBrandVisa",
  "brand-visual-studio": "IconBrandVisualStudio",
  "brand-vite": "IconBrandVite",
  "brand-vivaldi": "IconBrandVivaldi",
  "brand-vk": "IconBrandVk",
  "brand-vlc": "IconBrandVlc",
  "brand-volkswagen": "IconBrandVolkswagen",
  "brand-vsco": "IconBrandVsco",
  "brand-vscode": "IconBrandVscode",
  "brand-vue": "IconBrandVue",
  "brand-walmart": "IconBrandWalmart",
  "brand-waze": "IconBrandWaze",
  "brand-webflow": "IconBrandWebflow",
  "brand-wechat": "IconBrandWechat",
  "brand-weibo": "IconBrandWeibo",
  "brand-whatsapp": "IconBrandWhatsapp",
  "brand-wikipedia": "IconBrandWikipedia",
  "brand-windows": "IconBrandWindows",
  "brand-windy": "IconBrandWindy",
  "brand-wish": "IconBrandWish",
  "brand-wix": "IconBrandWix",
  "brand-wordpress": "IconBrandWordpress",
  "brand-x": "IconBrandX",
  "brand-x-filled": "IconBrandXFilled",
  "brand-xamarin": "IconBrandXamarin",
  "brand-xbox": "IconBrandXbox",
  "brand-xdeep": "IconBrandXdeep",
  "brand-xing": "IconBrandXing",
  "brand-yahoo": "IconBrandYahoo",
  "brand-yandex": "IconBrandYandex",
  "brand-yarn": "IconBrandYarn",
  "brand-yatse": "IconBrandYatse",
  "brand-ycombinator": "IconBrandYcombinator",
  "brand-youtube": "IconBrandYoutube",
  "brand-youtube-filled": "IconBrandYoutubeFilled",
  "brand-youtube-kids": "IconBrandYoutubeKids",
  "brand-zalando": "IconBrandZalando",
  "brand-zapier": "IconBrandZapier",
  "brand-zeit": "IconBrandZeit",
  "brand-zhihu": "IconBrandZhihu",
  "brand-zoom": "IconBrandZoom",
  "brand-zulip": "IconBrandZulip",
  "brand-zwift": "IconBrandZwift",
  bread: "IconBread",
  "bread-filled": "IconBreadFilled",
  "bread-off": "IconBreadOff",
  briefcase: "IconBriefcase",
  "briefcase-2": "IconBriefcase2",
  "briefcase-2-filled": "IconBriefcase2Filled",
  "briefcase-filled": "IconBriefcaseFilled",
  "briefcase-off": "IconBriefcaseOff",
  brightness: "IconBrightness",
  "brightness-2": "IconBrightness2",
  "brightness-auto": "IconBrightnessAuto",
  "brightness-auto-filled": "IconBrightnessAutoFilled",
  "brightness-down": "IconBrightnessDown",
  "brightness-down-filled": "IconBrightnessDownFilled",
  "brightness-filled": "IconBrightnessFilled",
  "brightness-half": "IconBrightnessHalf",
  "brightness-off": "IconBrightnessOff",
  "brightness-up": "IconBrightnessUp",
  "brightness-up-filled": "IconBrightnessUpFilled",
  broadcast: "IconBroadcast",
  "broadcast-off": "IconBroadcastOff",
  browser: "IconBrowser",
  "browser-check": "IconBrowserCheck",
  "browser-off": "IconBrowserOff",
  "browser-plus": "IconBrowserPlus",
  "browser-x": "IconBrowserX",
  brush: "IconBrush",
  "brush-off": "IconBrushOff",
  bubble: "IconBubble",
  "bubble-filled": "IconBubbleFilled",
  "bubble-minus": "IconBubbleMinus",
  "bubble-plus": "IconBubblePlus",
  "bubble-tea": "IconBubbleTea",
  "bubble-tea-2": "IconBubbleTea2",
  "bubble-text": "IconBubbleText",
  "bubble-x": "IconBubbleX",
  bucket: "IconBucket",
  "bucket-droplet": "IconBucketDroplet",
  "bucket-off": "IconBucketOff",
  bug: "IconBug",
  "bug-filled": "IconBugFilled",
  "bug-off": "IconBugOff",
  building: "IconBuilding",
  "building-arch": "IconBuildingArch",
  "building-bank": "IconBuildingBank",
  "building-bridge": "IconBuildingBridge",
  "building-bridge-2": "IconBuildingBridge2",
  "building-broadcast-tower": "IconBuildingBroadcastTower",
  "building-broadcast-tower-filled": "IconBuildingBroadcastTowerFilled",
  "building-burj-al-arab": "IconBuildingBurjAlArab",
  "building-carousel": "IconBuildingCarousel",
  "building-castle": "IconBuildingCastle",
  "building-church": "IconBuildingChurch",
  "building-circus": "IconBuildingCircus",
  "building-community": "IconBuildingCommunity",
  "building-cottage": "IconBuildingCottage",
  "building-estate": "IconBuildingEstate",
  "building-factory": "IconBuildingFactory",
  "building-factory-2": "IconBuildingFactory2",
  "building-fortress": "IconBuildingFortress",
  "building-hospital": "IconBuildingHospital",
  "building-lighthouse": "IconBuildingLighthouse",
  "building-monument": "IconBuildingMonument",
  "building-mosque": "IconBuildingMosque",
  "building-off": "IconBuildingOff",
  "building-pavilion": "IconBuildingPavilion",
  "building-skyscraper": "IconBuildingSkyscraper",
  "building-stadium": "IconBuildingStadium",
  "building-store": "IconBuildingStore",
  "building-tunnel": "IconBuildingTunnel",
  "building-warehouse": "IconBuildingWarehouse",
  "building-wind-turbine": "IconBuildingWindTurbine",
  buildings: "IconBuildings",
  bulb: "IconBulb",
  "bulb-filled": "IconBulbFilled",
  "bulb-off": "IconBulbOff",
  bulldozer: "IconBulldozer",
  burger: "IconBurger",
  bus: "IconBus",
  "bus-off": "IconBusOff",
  "bus-stop": "IconBusStop",
  businessplan: "IconBusinessplan",
  butterfly: "IconButterfly",
  cactus: "IconCactus",
  "cactus-filled": "IconCactusFilled",
  "cactus-off": "IconCactusOff",
  cake: "IconCake",
  "cake-off": "IconCakeOff",
  calculator: "IconCalculator",
  "calculator-filled": "IconCalculatorFilled",
  "calculator-off": "IconCalculatorOff",
  calendar: "IconCalendar",
  "calendar-bolt": "IconCalendarBolt",
  "calendar-cancel": "IconCalendarCancel",
  "calendar-check": "IconCalendarCheck",
  "calendar-clock": "IconCalendarClock",
  "calendar-code": "IconCalendarCode",
  "calendar-cog": "IconCalendarCog",
  "calendar-dollar": "IconCalendarDollar",
  "calendar-dot": "IconCalendarDot",
  "calendar-down": "IconCalendarDown",
  "calendar-due": "IconCalendarDue",
  "calendar-event": "IconCalendarEvent",
  "calendar-exclamation": "IconCalendarExclamation",
  "calendar-filled": "IconCalendarFilled",
  "calendar-heart": "IconCalendarHeart",
  "calendar-minus": "IconCalendarMinus",
  "calendar-month": "IconCalendarMonth",
  "calendar-off": "IconCalendarOff",
  "calendar-pause": "IconCalendarPause",
  "calendar-pin": "IconCalendarPin",
  "calendar-plus": "IconCalendarPlus",
  "calendar-question": "IconCalendarQuestion",
  "calendar-repeat": "IconCalendarRepeat",
  "calendar-sad": "IconCalendarSad",
  "calendar-search": "IconCalendarSearch",
  "calendar-share": "IconCalendarShare",
  "calendar-smile": "IconCalendarSmile",
  "calendar-star": "IconCalendarStar",
  "calendar-stats": "IconCalendarStats",
  "calendar-time": "IconCalendarTime",
  "calendar-up": "IconCalendarUp",
  "calendar-user": "IconCalendarUser",
  "calendar-week": "IconCalendarWeek",
  "calendar-x": "IconCalendarX",
  camera: "IconCamera",
  "camera-bolt": "IconCameraBolt",
  "camera-cancel": "IconCameraCancel",
  "camera-check": "IconCameraCheck",
  "camera-code": "IconCameraCode",
  "camera-cog": "IconCameraCog",
  "camera-dollar": "IconCameraDollar",
  "camera-down": "IconCameraDown",
  "camera-exclamation": "IconCameraExclamation",
  "camera-filled": "IconCameraFilled",
  "camera-heart": "IconCameraHeart",
  "camera-minus": "IconCameraMinus",
  "camera-off": "IconCameraOff",
  "camera-pause": "IconCameraPause",
  "camera-pin": "IconCameraPin",
  "camera-plus": "IconCameraPlus",
  "camera-question": "IconCameraQuestion",
  "camera-rotate": "IconCameraRotate",
  "camera-search": "IconCameraSearch",
  "camera-selfie": "IconCameraSelfie",
  "camera-share": "IconCameraShare",
  "camera-star": "IconCameraStar",
  "camera-up": "IconCameraUp",
  "camera-x": "IconCameraX",
  camper: "IconCamper",
  campfire: "IconCampfire",
  "campfire-filled": "IconCampfireFilled",
  cancel: "IconCancel",
  candle: "IconCandle",
  "candle-filled": "IconCandleFilled",
  candy: "IconCandy",
  "candy-off": "IconCandyOff",
  cane: "IconCane",
  cannabis: "IconCannabis",
  "cap-projecting": "IconCapProjecting",
  "cap-rounded": "IconCapRounded",
  "cap-straight": "IconCapStraight",
  capsule: "IconCapsule",
  "capsule-filled": "IconCapsuleFilled",
  "capsule-horizontal": "IconCapsuleHorizontal",
  "capsule-horizontal-filled": "IconCapsuleHorizontalFilled",
  capture: "IconCapture",
  "capture-filled": "IconCaptureFilled",
  "capture-off": "IconCaptureOff",
  car: "IconCar",
  "car-4-wd": "IconCar4wd",
  "car-crane": "IconCarCrane",
  "car-crash": "IconCarCrash",
  "car-fan": "IconCarFan",
  "car-fan-1": "IconCarFan1",
  "car-fan-2": "IconCarFan2",
  "car-fan-3": "IconCarFan3",
  "car-fan-auto": "IconCarFanAuto",
  "car-garage": "IconCarGarage",
  "car-off": "IconCarOff",
  "car-suv": "IconCarSuv",
  "car-turbine": "IconCarTurbine",
  carambola: "IconCarambola",
  caravan: "IconCaravan",
  cardboards: "IconCardboards",
  "cardboards-off": "IconCardboardsOff",
  cards: "IconCards",
  "cards-filled": "IconCardsFilled",
  "caret-down": "IconCaretDown",
  "caret-down-filled": "IconCaretDownFilled",
  "caret-left": "IconCaretLeft",
  "caret-left-filled": "IconCaretLeftFilled",
  "caret-left-right": "IconCaretLeftRight",
  "caret-left-right-filled": "IconCaretLeftRightFilled",
  "caret-right": "IconCaretRight",
  "caret-right-filled": "IconCaretRightFilled",
  "caret-up": "IconCaretUp",
  "caret-up-down": "IconCaretUpDown",
  "caret-up-down-filled": "IconCaretUpDownFilled",
  "caret-up-filled": "IconCaretUpFilled",
  "carousel-horizontal": "IconCarouselHorizontal",
  "carousel-horizontal-filled": "IconCarouselHorizontalFilled",
  "carousel-vertical": "IconCarouselVertical",
  "carousel-vertical-filled": "IconCarouselVerticalFilled",
  carrot: "IconCarrot",
  "carrot-off": "IconCarrotOff",
  cash: "IconCash",
  "cash-banknote": "IconCashBanknote",
  "cash-banknote-filled": "IconCashBanknoteFilled",
  "cash-banknote-off": "IconCashBanknoteOff",
  "cash-off": "IconCashOff",
  "cash-register": "IconCashRegister",
  cast: "IconCast",
  "cast-off": "IconCastOff",
  cat: "IconCat",
  category: "IconCategory",
  "category-2": "IconCategory2",
  "category-filled": "IconCategoryFilled",
  "category-minus": "IconCategoryMinus",
  "category-plus": "IconCategoryPlus",
  ce: "IconCe",
  "ce-off": "IconCeOff",
  cell: "IconCell",
  "cell-signal-1": "IconCellSignal1",
  "cell-signal-2": "IconCellSignal2",
  "cell-signal-3": "IconCellSignal3",
  "cell-signal-4": "IconCellSignal4",
  "cell-signal-5": "IconCellSignal5",
  "cell-signal-off": "IconCellSignalOff",
  certificate: "IconCertificate",
  "certificate-2": "IconCertificate2",
  "certificate-2-off": "IconCertificate2Off",
  "certificate-off": "IconCertificateOff",
  "chair-director": "IconChairDirector",
  chalkboard: "IconChalkboard",
  "chalkboard-off": "IconChalkboardOff",
  "charging-pile": "IconChargingPile",
  "chart-arcs": "IconChartArcs",
  "chart-arcs-3": "IconChartArcs3",
  "chart-area": "IconChartArea",
  "chart-area-filled": "IconChartAreaFilled",
  "chart-area-line": "IconChartAreaLine",
  "chart-area-line-filled": "IconChartAreaLineFilled",
  "chart-arrows": "IconChartArrows",
  "chart-arrows-vertical": "IconChartArrowsVertical",
  "chart-bar": "IconChartBar",
  "chart-bar-off": "IconChartBarOff",
  "chart-bar-popular": "IconChartBarPopular",
  "chart-bubble": "IconChartBubble",
  "chart-bubble-filled": "IconChartBubbleFilled",
  "chart-candle": "IconChartCandle",
  "chart-candle-filled": "IconChartCandleFilled",
  "chart-circles": "IconChartCircles",
  "chart-cohort": "IconChartCohort",
  "chart-donut": "IconChartDonut",
  "chart-donut-2": "IconChartDonut2",
  "chart-donut-3": "IconChartDonut3",
  "chart-donut-4": "IconChartDonut4",
  "chart-donut-filled": "IconChartDonutFilled",
  "chart-dots": "IconChartDots",
  "chart-dots-2": "IconChartDots2",
  "chart-dots-3": "IconChartDots3",
  "chart-dots-filled": "IconChartDotsFilled",
  "chart-funnel": "IconChartFunnel",
  "chart-grid-dots": "IconChartGridDots",
  "chart-grid-dots-filled": "IconChartGridDotsFilled",
  "chart-histogram": "IconChartHistogram",
  "chart-infographic": "IconChartInfographic",
  "chart-line": "IconChartLine",
  "chart-pie": "IconChartPie",
  "chart-pie-2": "IconChartPie2",
  "chart-pie-3": "IconChartPie3",
  "chart-pie-4": "IconChartPie4",
  "chart-pie-filled": "IconChartPieFilled",
  "chart-pie-off": "IconChartPieOff",
  "chart-ppf": "IconChartPpf",
  "chart-radar": "IconChartRadar",
  "chart-sankey": "IconChartSankey",
  "chart-scatter": "IconChartScatter",
  "chart-scatter-3-d": "IconChartScatter3d",
  "chart-treemap": "IconChartTreemap",
  check: "IconCheck",
  checkbox: "IconCheckbox",
  checklist: "IconChecklist",
  checks: "IconChecks",
  "checkup-list": "IconCheckupList",
  cheese: "IconCheese",
  "chef-hat": "IconChefHat",
  "chef-hat-off": "IconChefHatOff",
  cherry: "IconCherry",
  "cherry-filled": "IconCherryFilled",
  chess: "IconChess",
  "chess-bishop": "IconChessBishop",
  "chess-bishop-filled": "IconChessBishopFilled",
  "chess-filled": "IconChessFilled",
  "chess-king": "IconChessKing",
  "chess-king-filled": "IconChessKingFilled",
  "chess-knight": "IconChessKnight",
  "chess-knight-filled": "IconChessKnightFilled",
  "chess-queen": "IconChessQueen",
  "chess-queen-filled": "IconChessQueenFilled",
  "chess-rook": "IconChessRook",
  "chess-rook-filled": "IconChessRookFilled",
  "chevron-compact-down": "IconChevronCompactDown",
  "chevron-compact-left": "IconChevronCompactLeft",
  "chevron-compact-right": "IconChevronCompactRight",
  "chevron-compact-up": "IconChevronCompactUp",
  "chevron-down": "IconChevronDown",
  "chevron-down-left": "IconChevronDownLeft",
  "chevron-down-right": "IconChevronDownRight",
  "chevron-left": "IconChevronLeft",
  "chevron-left-pipe": "IconChevronLeftPipe",
  "chevron-right": "IconChevronRight",
  "chevron-right-pipe": "IconChevronRightPipe",
  "chevron-up": "IconChevronUp",
  "chevron-up-left": "IconChevronUpLeft",
  "chevron-up-right": "IconChevronUpRight",
  "chevrons-down": "IconChevronsDown",
  "chevrons-down-left": "IconChevronsDownLeft",
  "chevrons-down-right": "IconChevronsDownRight",
  "chevrons-left": "IconChevronsLeft",
  "chevrons-right": "IconChevronsRight",
  "chevrons-up": "IconChevronsUp",
  "chevrons-up-left": "IconChevronsUpLeft",
  "chevrons-up-right": "IconChevronsUpRight",
  chisel: "IconChisel",
  "christmas-ball": "IconChristmasBall",
  "christmas-tree": "IconChristmasTree",
  "christmas-tree-off": "IconChristmasTreeOff",
  circle: "IconCircle",
  "circle-arrow-down": "IconCircleArrowDown",
  "circle-arrow-down-filled": "IconCircleArrowDownFilled",
  "circle-arrow-down-left": "IconCircleArrowDownLeft",
  "circle-arrow-down-left-filled": "IconCircleArrowDownLeftFilled",
  "circle-arrow-down-right": "IconCircleArrowDownRight",
  "circle-arrow-down-right-filled": "IconCircleArrowDownRightFilled",
  "circle-arrow-left": "IconCircleArrowLeft",
  "circle-arrow-left-filled": "IconCircleArrowLeftFilled",
  "circle-arrow-right": "IconCircleArrowRight",
  "circle-arrow-right-filled": "IconCircleArrowRightFilled",
  "circle-arrow-up": "IconCircleArrowUp",
  "circle-arrow-up-filled": "IconCircleArrowUpFilled",
  "circle-arrow-up-left": "IconCircleArrowUpLeft",
  "circle-arrow-up-left-filled": "IconCircleArrowUpLeftFilled",
  "circle-arrow-up-right": "IconCircleArrowUpRight",
  "circle-arrow-up-right-filled": "IconCircleArrowUpRightFilled",
  "circle-caret-down": "IconCircleCaretDown",
  "circle-caret-left": "IconCircleCaretLeft",
  "circle-caret-right": "IconCircleCaretRight",
  "circle-caret-up": "IconCircleCaretUp",
  "circle-check": "IconCircleCheck",
  "circle-check-filled": "IconCircleCheckFilled",
  "circle-chevron-down": "IconCircleChevronDown",
  "circle-chevron-left": "IconCircleChevronLeft",
  "circle-chevron-right": "IconCircleChevronRight",
  "circle-chevron-up": "IconCircleChevronUp",
  "circle-chevrons-down": "IconCircleChevronsDown",
  "circle-chevrons-left": "IconCircleChevronsLeft",
  "circle-chevrons-right": "IconCircleChevronsRight",
  "circle-chevrons-up": "IconCircleChevronsUp",
  "circle-dashed": "IconCircleDashed",
  "circle-dashed-check": "IconCircleDashedCheck",
  "circle-dashed-minus": "IconCircleDashedMinus",
  "circle-dashed-number-0": "IconCircleDashedNumber0",
  "circle-dashed-number-1": "IconCircleDashedNumber1",
  "circle-dashed-number-2": "IconCircleDashedNumber2",
  "circle-dashed-number-3": "IconCircleDashedNumber3",
  "circle-dashed-number-4": "IconCircleDashedNumber4",
  "circle-dashed-number-5": "IconCircleDashedNumber5",
  "circle-dashed-number-6": "IconCircleDashedNumber6",
  "circle-dashed-number-7": "IconCircleDashedNumber7",
  "circle-dashed-number-8": "IconCircleDashedNumber8",
  "circle-dashed-number-9": "IconCircleDashedNumber9",
  "circle-dashed-percentage": "IconCircleDashedPercentage",
  "circle-dashed-plus": "IconCircleDashedPlus",
  "circle-dashed-x": "IconCircleDashedX",
  "circle-dot": "IconCircleDot",
  "circle-dot-filled": "IconCircleDotFilled",
  "circle-dotted": "IconCircleDotted",
  "circle-filled": "IconCircleFilled",
  "circle-half": "IconCircleHalf",
  "circle-half-2": "IconCircleHalf2",
  "circle-half-vertical": "IconCircleHalfVertical",
  "circle-key": "IconCircleKey",
  "circle-key-filled": "IconCircleKeyFilled",
  "circle-letter-a": "IconCircleLetterA",
  "circle-letter-a-filled": "IconCircleLetterAFilled",
  "circle-letter-b": "IconCircleLetterB",
  "circle-letter-b-filled": "IconCircleLetterBFilled",
  "circle-letter-c": "IconCircleLetterC",
  "circle-letter-c-filled": "IconCircleLetterCFilled",
  "circle-letter-d": "IconCircleLetterD",
  "circle-letter-d-filled": "IconCircleLetterDFilled",
  "circle-letter-e": "IconCircleLetterE",
  "circle-letter-e-filled": "IconCircleLetterEFilled",
  "circle-letter-f": "IconCircleLetterF",
  "circle-letter-f-filled": "IconCircleLetterFFilled",
  "circle-letter-g": "IconCircleLetterG",
  "circle-letter-g-filled": "IconCircleLetterGFilled",
  "circle-letter-h": "IconCircleLetterH",
  "circle-letter-h-filled": "IconCircleLetterHFilled",
  "circle-letter-i": "IconCircleLetterI",
  "circle-letter-i-filled": "IconCircleLetterIFilled",
  "circle-letter-j": "IconCircleLetterJ",
  "circle-letter-j-filled": "IconCircleLetterJFilled",
  "circle-letter-k": "IconCircleLetterK",
  "circle-letter-k-filled": "IconCircleLetterKFilled",
  "circle-letter-l": "IconCircleLetterL",
  "circle-letter-l-filled": "IconCircleLetterLFilled",
  "circle-letter-m": "IconCircleLetterM",
  "circle-letter-m-filled": "IconCircleLetterMFilled",
  "circle-letter-n": "IconCircleLetterN",
  "circle-letter-n-filled": "IconCircleLetterNFilled",
  "circle-letter-o": "IconCircleLetterO",
  "circle-letter-o-filled": "IconCircleLetterOFilled",
  "circle-letter-p": "IconCircleLetterP",
  "circle-letter-p-filled": "IconCircleLetterPFilled",
  "circle-letter-q": "IconCircleLetterQ",
  "circle-letter-q-filled": "IconCircleLetterQFilled",
  "circle-letter-r": "IconCircleLetterR",
  "circle-letter-r-filled": "IconCircleLetterRFilled",
  "circle-letter-s": "IconCircleLetterS",
  "circle-letter-s-filled": "IconCircleLetterSFilled",
  "circle-letter-t": "IconCircleLetterT",
  "circle-letter-t-filled": "IconCircleLetterTFilled",
  "circle-letter-u": "IconCircleLetterU",
  "circle-letter-u-filled": "IconCircleLetterUFilled",
  "circle-letter-v": "IconCircleLetterV",
  "circle-letter-v-filled": "IconCircleLetterVFilled",
  "circle-letter-w": "IconCircleLetterW",
  "circle-letter-w-filled": "IconCircleLetterWFilled",
  "circle-letter-x": "IconCircleLetterX",
  "circle-letter-x-filled": "IconCircleLetterXFilled",
  "circle-letter-y": "IconCircleLetterY",
  "circle-letter-y-filled": "IconCircleLetterYFilled",
  "circle-letter-z": "IconCircleLetterZ",
  "circle-letter-z-filled": "IconCircleLetterZFilled",
  "circle-minus": "IconCircleMinus",
  "circle-minus-2": "IconCircleMinus2",
  "circle-number-0": "IconCircleNumber0",
  "circle-number-0-filled": "IconCircleNumber0Filled",
  "circle-number-1": "IconCircleNumber1",
  "circle-number-1-filled": "IconCircleNumber1Filled",
  "circle-number-2": "IconCircleNumber2",
  "circle-number-2-filled": "IconCircleNumber2Filled",
  "circle-number-3": "IconCircleNumber3",
  "circle-number-3-filled": "IconCircleNumber3Filled",
  "circle-number-4": "IconCircleNumber4",
  "circle-number-4-filled": "IconCircleNumber4Filled",
  "circle-number-5": "IconCircleNumber5",
  "circle-number-5-filled": "IconCircleNumber5Filled",
  "circle-number-6": "IconCircleNumber6",
  "circle-number-6-filled": "IconCircleNumber6Filled",
  "circle-number-7": "IconCircleNumber7",
  "circle-number-7-filled": "IconCircleNumber7Filled",
  "circle-number-8": "IconCircleNumber8",
  "circle-number-8-filled": "IconCircleNumber8Filled",
  "circle-number-9": "IconCircleNumber9",
  "circle-number-9-filled": "IconCircleNumber9Filled",
  "circle-off": "IconCircleOff",
  "circle-percentage": "IconCirclePercentage",
  "circle-percentage-filled": "IconCirclePercentageFilled",
  "circle-plus": "IconCirclePlus",
  "circle-plus-2": "IconCirclePlus2",
  "circle-plus-filled": "IconCirclePlusFilled",
  "circle-rectangle": "IconCircleRectangle",
  "circle-rectangle-filled": "IconCircleRectangleFilled",
  "circle-rectangle-off": "IconCircleRectangleOff",
  "circle-square": "IconCircleSquare",
  "circle-triangle": "IconCircleTriangle",
  "circle-x": "IconCircleX",
  "circle-x-filled": "IconCircleXFilled",
  circles: "IconCircles",
  "circles-filled": "IconCirclesFilled",
  "circles-relation": "IconCirclesRelation",
  "circuit-ammeter": "IconCircuitAmmeter",
  "circuit-battery": "IconCircuitBattery",
  "circuit-bulb": "IconCircuitBulb",
  "circuit-capacitor": "IconCircuitCapacitor",
  "circuit-capacitor-polarized": "IconCircuitCapacitorPolarized",
  "circuit-cell": "IconCircuitCell",
  "circuit-cell-plus": "IconCircuitCellPlus",
  "circuit-changeover": "IconCircuitChangeover",
  "circuit-diode": "IconCircuitDiode",
  "circuit-diode-zener": "IconCircuitDiodeZener",
  "circuit-ground": "IconCircuitGround",
  "circuit-ground-digital": "IconCircuitGroundDigital",
  "circuit-inductor": "IconCircuitInductor",
  "circuit-motor": "IconCircuitMotor",
  "circuit-pushbutton": "IconCircuitPushbutton",
  "circuit-resistor": "IconCircuitResistor",
  "circuit-switch-closed": "IconCircuitSwitchClosed",
  "circuit-switch-open": "IconCircuitSwitchOpen",
  "circuit-voltmeter": "IconCircuitVoltmeter",
  "clear-all": "IconClearAll",
  "clear-formatting": "IconClearFormatting",
  click: "IconClick",
  "cliff-jumping": "IconCliffJumping",
  clipboard: "IconClipboard",
  "clipboard-check": "IconClipboardCheck",
  "clipboard-copy": "IconClipboardCopy",
  "clipboard-data": "IconClipboardData",
  "clipboard-heart": "IconClipboardHeart",
  "clipboard-list": "IconClipboardList",
  "clipboard-off": "IconClipboardOff",
  "clipboard-plus": "IconClipboardPlus",
  "clipboard-smile": "IconClipboardSmile",
  "clipboard-text": "IconClipboardText",
  "clipboard-typography": "IconClipboardTypography",
  "clipboard-x": "IconClipboardX",
  clock: "IconClock",
  "clock-12": "IconClock12",
  "clock-2": "IconClock2",
  "clock-24": "IconClock24",
  "clock-bitcoin": "IconClockBitcoin",
  "clock-bolt": "IconClockBolt",
  "clock-cancel": "IconClockCancel",
  "clock-check": "IconClockCheck",
  "clock-code": "IconClockCode",
  "clock-cog": "IconClockCog",
  "clock-dollar": "IconClockDollar",
  "clock-down": "IconClockDown",
  "clock-edit": "IconClockEdit",
  "clock-exclamation": "IconClockExclamation",
  "clock-filled": "IconClockFilled",
  "clock-heart": "IconClockHeart",
  "clock-hour-1": "IconClockHour1",
  "clock-hour-10": "IconClockHour10",
  "clock-hour-10-filled": "IconClockHour10Filled",
  "clock-hour-11": "IconClockHour11",
  "clock-hour-11-filled": "IconClockHour11Filled",
  "clock-hour-12": "IconClockHour12",
  "clock-hour-12-filled": "IconClockHour12Filled",
  "clock-hour-1-filled": "IconClockHour1Filled",
  "clock-hour-2": "IconClockHour2",
  "clock-hour-2-filled": "IconClockHour2Filled",
  "clock-hour-3": "IconClockHour3",
  "clock-hour-3-filled": "IconClockHour3Filled",
  "clock-hour-4": "IconClockHour4",
  "clock-hour-4-filled": "IconClockHour4Filled",
  "clock-hour-5": "IconClockHour5",
  "clock-hour-5-filled": "IconClockHour5Filled",
  "clock-hour-6": "IconClockHour6",
  "clock-hour-6-filled": "IconClockHour6Filled",
  "clock-hour-7": "IconClockHour7",
  "clock-hour-7-filled": "IconClockHour7Filled",
  "clock-hour-8": "IconClockHour8",
  "clock-hour-8-filled": "IconClockHour8Filled",
  "clock-hour-9": "IconClockHour9",
  "clock-hour-9-filled": "IconClockHour9Filled",
  "clock-minus": "IconClockMinus",
  "clock-off": "IconClockOff",
  "clock-pause": "IconClockPause",
  "clock-pin": "IconClockPin",
  "clock-play": "IconClockPlay",
  "clock-plus": "IconClockPlus",
  "clock-question": "IconClockQuestion",
  "clock-record": "IconClockRecord",
  "clock-search": "IconClockSearch",
  "clock-share": "IconClockShare",
  "clock-shield": "IconClockShield",
  "clock-star": "IconClockStar",
  "clock-stop": "IconClockStop",
  "clock-up": "IconClockUp",
  "clock-x": "IconClockX",
  "clothes-rack": "IconClothesRack",
  "clothes-rack-off": "IconClothesRackOff",
  cloud: "IconCloud",
  "cloud-bitcoin": "IconCloudBitcoin",
  "cloud-bolt": "IconCloudBolt",
  "cloud-cancel": "IconCloudCancel",
  "cloud-check": "IconCloudCheck",
  "cloud-code": "IconCloudCode",
  "cloud-cog": "IconCloudCog",
  "cloud-computing": "IconCloudComputing",
  "cloud-data-connection": "IconCloudDataConnection",
  "cloud-dollar": "IconCloudDollar",
  "cloud-down": "IconCloudDown",
  "cloud-download": "IconCloudDownload",
  "cloud-exclamation": "IconCloudExclamation",
  "cloud-filled": "IconCloudFilled",
  "cloud-fog": "IconCloudFog",
  "cloud-heart": "IconCloudHeart",
  "cloud-lock": "IconCloudLock",
  "cloud-lock-open": "IconCloudLockOpen",
  "cloud-minus": "IconCloudMinus",
  "cloud-network": "IconCloudNetwork",
  "cloud-off": "IconCloudOff",
  "cloud-pause": "IconCloudPause",
  "cloud-pin": "IconCloudPin",
  "cloud-plus": "IconCloudPlus",
  "cloud-question": "IconCloudQuestion",
  "cloud-rain": "IconCloudRain",
  "cloud-search": "IconCloudSearch",
  "cloud-share": "IconCloudShare",
  "cloud-snow": "IconCloudSnow",
  "cloud-star": "IconCloudStar",
  "cloud-storm": "IconCloudStorm",
  "cloud-up": "IconCloudUp",
  "cloud-upload": "IconCloudUpload",
  "cloud-x": "IconCloudX",
  clover: "IconClover",
  "clover-2": "IconClover2",
  clubs: "IconClubs",
  "clubs-filled": "IconClubsFilled",
  code: "IconCode",
  "code-asterisk": "IconCodeAsterisk",
  "code-circle": "IconCodeCircle",
  "code-circle-2": "IconCodeCircle2",
  "code-circle-2-filled": "IconCodeCircle2Filled",
  "code-circle-filled": "IconCodeCircleFilled",
  "code-dots": "IconCodeDots",
  "code-minus": "IconCodeMinus",
  "code-off": "IconCodeOff",
  "code-plus": "IconCodePlus",
  coffee: "IconCoffee",
  "coffee-off": "IconCoffeeOff",
  coffin: "IconCoffin",
  coin: "IconCoin",
  "coin-bitcoin": "IconCoinBitcoin",
  "coin-bitcoin-filled": "IconCoinBitcoinFilled",
  "coin-euro": "IconCoinEuro",
  "coin-euro-filled": "IconCoinEuroFilled",
  "coin-filled": "IconCoinFilled",
  "coin-monero": "IconCoinMonero",
  "coin-monero-filled": "IconCoinMoneroFilled",
  "coin-off": "IconCoinOff",
  "coin-pound": "IconCoinPound",
  "coin-pound-filled": "IconCoinPoundFilled",
  "coin-rupee": "IconCoinRupee",
  "coin-rupee-filled": "IconCoinRupeeFilled",
  "coin-taka": "IconCoinTaka",
  "coin-taka-filled": "IconCoinTakaFilled",
  "coin-yen": "IconCoinYen",
  "coin-yen-filled": "IconCoinYenFilled",
  "coin-yuan": "IconCoinYuan",
  "coin-yuan-filled": "IconCoinYuanFilled",
  coins: "IconCoins",
  "color-filter": "IconColorFilter",
  "color-picker": "IconColorPicker",
  "color-picker-off": "IconColorPickerOff",
  "color-swatch": "IconColorSwatch",
  "color-swatch-off": "IconColorSwatchOff",
  "column-insert-left": "IconColumnInsertLeft",
  "column-insert-right": "IconColumnInsertRight",
  "column-remove": "IconColumnRemove",
  columns: "IconColumns",
  "columns-1": "IconColumns1",
  "columns-2": "IconColumns2",
  "columns-3": "IconColumns3",
  "columns-off": "IconColumnsOff",
  comet: "IconComet",
  command: "IconCommand",
  "command-off": "IconCommandOff",
  compass: "IconCompass",
  "compass-filled": "IconCompassFilled",
  "compass-off": "IconCompassOff",
  components: "IconComponents",
  "components-off": "IconComponentsOff",
  cone: "IconCone",
  "cone-2": "IconCone2",
  "cone-2-filled": "IconCone2Filled",
  "cone-filled": "IconConeFilled",
  "cone-off": "IconConeOff",
  "cone-plus": "IconConePlus",
  confetti: "IconConfetti",
  "confetti-off": "IconConfettiOff",
  confucius: "IconConfucius",
  container: "IconContainer",
  "container-off": "IconContainerOff",
  contract: "IconContract",
  contrast: "IconContrast",
  "contrast-2": "IconContrast2",
  "contrast-2-filled": "IconContrast2Filled",
  "contrast-2-off": "IconContrast2Off",
  "contrast-filled": "IconContrastFilled",
  "contrast-off": "IconContrastOff",
  cooker: "IconCooker",
  cookie: "IconCookie",
  "cookie-filled": "IconCookieFilled",
  "cookie-man": "IconCookieMan",
  "cookie-man-filled": "IconCookieManFilled",
  "cookie-off": "IconCookieOff",
  copy: "IconCopy",
  "copy-check": "IconCopyCheck",
  "copy-check-filled": "IconCopyCheckFilled",
  "copy-minus": "IconCopyMinus",
  "copy-minus-filled": "IconCopyMinusFilled",
  "copy-off": "IconCopyOff",
  "copy-plus": "IconCopyPlus",
  "copy-plus-filled": "IconCopyPlusFilled",
  "copy-x": "IconCopyX",
  "copy-x-filled": "IconCopyXFilled",
  copyleft: "IconCopyleft",
  "copyleft-filled": "IconCopyleftFilled",
  "copyleft-off": "IconCopyleftOff",
  copyright: "IconCopyright",
  "copyright-filled": "IconCopyrightFilled",
  "copyright-off": "IconCopyrightOff",
  "corner-down-left": "IconCornerDownLeft",
  "corner-down-left-double": "IconCornerDownLeftDouble",
  "corner-down-right": "IconCornerDownRight",
  "corner-down-right-double": "IconCornerDownRightDouble",
  "corner-left-down": "IconCornerLeftDown",
  "corner-left-down-double": "IconCornerLeftDownDouble",
  "corner-left-up": "IconCornerLeftUp",
  "corner-left-up-double": "IconCornerLeftUpDouble",
  "corner-right-down": "IconCornerRightDown",
  "corner-right-down-double": "IconCornerRightDownDouble",
  "corner-right-up": "IconCornerRightUp",
  "corner-right-up-double": "IconCornerRightUpDouble",
  "corner-up-left": "IconCornerUpLeft",
  "corner-up-left-double": "IconCornerUpLeftDouble",
  "corner-up-right": "IconCornerUpRight",
  "corner-up-right-double": "IconCornerUpRightDouble",
  cpu: "IconCpu",
  "cpu-2": "IconCpu2",
  "cpu-off": "IconCpuOff",
  crane: "IconCrane",
  "crane-off": "IconCraneOff",
  "creative-commons": "IconCreativeCommons",
  "creative-commons-by": "IconCreativeCommonsBy",
  "creative-commons-nc": "IconCreativeCommonsNc",
  "creative-commons-nd": "IconCreativeCommonsNd",
  "creative-commons-off": "IconCreativeCommonsOff",
  "creative-commons-sa": "IconCreativeCommonsSa",
  "creative-commons-zero": "IconCreativeCommonsZero",
  "credit-card": "IconCreditCard",
  "credit-card-filled": "IconCreditCardFilled",
  "credit-card-off": "IconCreditCardOff",
  "credit-card-pay": "IconCreditCardPay",
  "credit-card-refund": "IconCreditCardRefund",
  cricket: "IconCricket",
  crop: "IconCrop",
  "crop-11": "IconCrop11",
  "crop-11-filled": "IconCrop11Filled",
  "crop-169": "IconCrop169",
  "crop-169-filled": "IconCrop169Filled",
  "crop-32": "IconCrop32",
  "crop-32-filled": "IconCrop32Filled",
  "crop-54": "IconCrop54",
  "crop-54-filled": "IconCrop54Filled",
  "crop-75": "IconCrop75",
  "crop-75-filled": "IconCrop75Filled",
  "crop-landscape": "IconCropLandscape",
  "crop-landscape-filled": "IconCropLandscapeFilled",
  "crop-portrait": "IconCropPortrait",
  "crop-portrait-filled": "IconCropPortraitFilled",
  cross: "IconCross",
  "cross-filled": "IconCrossFilled",
  "cross-off": "IconCrossOff",
  crosshair: "IconCrosshair",
  crown: "IconCrown",
  "crown-off": "IconCrownOff",
  crutches: "IconCrutches",
  "crutches-off": "IconCrutchesOff",
  "crystal-ball": "IconCrystalBall",
  csv: "IconCsv",
  cube: "IconCube",
  "cube-3-d-sphere": "IconCube3dSphere",
  "cube-3-d-sphere-off": "IconCube3dSphereOff",
  "cube-off": "IconCubeOff",
  "cube-plus": "IconCubePlus",
  "cube-send": "IconCubeSend",
  "cube-unfolded": "IconCubeUnfolded",
  cup: "IconCup",
  "cup-off": "IconCupOff",
  curling: "IconCurling",
  "curly-loop": "IconCurlyLoop",
  currency: "IconCurrency",
  "currency-afghani": "IconCurrencyAfghani",
  "currency-bahraini": "IconCurrencyBahraini",
  "currency-baht": "IconCurrencyBaht",
  "currency-bitcoin": "IconCurrencyBitcoin",
  "currency-cent": "IconCurrencyCent",
  "currency-dinar": "IconCurrencyDinar",
  "currency-dirham": "IconCurrencyDirham",
  "currency-dogecoin": "IconCurrencyDogecoin",
  "currency-dollar": "IconCurrencyDollar",
  "currency-dollar-australian": "IconCurrencyDollarAustralian",
  "currency-dollar-brunei": "IconCurrencyDollarBrunei",
  "currency-dollar-canadian": "IconCurrencyDollarCanadian",
  "currency-dollar-guyanese": "IconCurrencyDollarGuyanese",
  "currency-dollar-off": "IconCurrencyDollarOff",
  "currency-dollar-singapore": "IconCurrencyDollarSingapore",
  "currency-dollar-zimbabwean": "IconCurrencyDollarZimbabwean",
  "currency-dong": "IconCurrencyDong",
  "currency-dram": "IconCurrencyDram",
  "currency-ethereum": "IconCurrencyEthereum",
  "currency-euro": "IconCurrencyEuro",
  "currency-euro-off": "IconCurrencyEuroOff",
  "currency-florin": "IconCurrencyFlorin",
  "currency-forint": "IconCurrencyForint",
  "currency-frank": "IconCurrencyFrank",
  "currency-guarani": "IconCurrencyGuarani",
  "currency-hryvnia": "IconCurrencyHryvnia",
  "currency-iranian-rial": "IconCurrencyIranianRial",
  "currency-kip": "IconCurrencyKip",
  "currency-krone-czech": "IconCurrencyKroneCzech",
  "currency-krone-danish": "IconCurrencyKroneDanish",
  "currency-krone-swedish": "IconCurrencyKroneSwedish",
  "currency-lari": "IconCurrencyLari",
  "currency-leu": "IconCurrencyLeu",
  "currency-lira": "IconCurrencyLira",
  "currency-litecoin": "IconCurrencyLitecoin",
  "currency-lyd": "IconCurrencyLyd",
  "currency-manat": "IconCurrencyManat",
  "currency-monero": "IconCurrencyMonero",
  "currency-naira": "IconCurrencyNaira",
  "currency-nano": "IconCurrencyNano",
  "currency-off": "IconCurrencyOff",
  "currency-paanga": "IconCurrencyPaanga",
  "currency-peso": "IconCurrencyPeso",
  "currency-pound": "IconCurrencyPound",
  "currency-pound-off": "IconCurrencyPoundOff",
  "currency-quetzal": "IconCurrencyQuetzal",
  "currency-real": "IconCurrencyReal",
  "currency-renminbi": "IconCurrencyRenminbi",
  "currency-ripple": "IconCurrencyRipple",
  "currency-riyal": "IconCurrencyRiyal",
  "currency-rubel": "IconCurrencyRubel",
  "currency-rufiyaa": "IconCurrencyRufiyaa",
  "currency-rupee": "IconCurrencyRupee",
  "currency-rupee-nepalese": "IconCurrencyRupeeNepalese",
  "currency-shekel": "IconCurrencyShekel",
  "currency-solana": "IconCurrencySolana",
  "currency-som": "IconCurrencySom",
  "currency-taka": "IconCurrencyTaka",
  "currency-tenge": "IconCurrencyTenge",
  "currency-tugrik": "IconCurrencyTugrik",
  "currency-won": "IconCurrencyWon",
  "currency-xrp": "IconCurrencyXrp",
  "currency-yen": "IconCurrencyYen",
  "currency-yen-off": "IconCurrencyYenOff",
  "currency-yuan": "IconCurrencyYuan",
  "currency-zloty": "IconCurrencyZloty",
  "current-location": "IconCurrentLocation",
  "current-location-off": "IconCurrentLocationOff",
  "cursor-off": "IconCursorOff",
  "cursor-text": "IconCursorText",
  cut: "IconCut",
  cylinder: "IconCylinder",
  "cylinder-off": "IconCylinderOff",
  "cylinder-plus": "IconCylinderPlus",
  dashboard: "IconDashboard",
  "dashboard-off": "IconDashboardOff",
  database: "IconDatabase",
  "database-cog": "IconDatabaseCog",
  "database-dollar": "IconDatabaseDollar",
  "database-edit": "IconDatabaseEdit",
  "database-exclamation": "IconDatabaseExclamation",
  "database-export": "IconDatabaseExport",
  "database-heart": "IconDatabaseHeart",
  "database-import": "IconDatabaseImport",
  "database-leak": "IconDatabaseLeak",
  "database-minus": "IconDatabaseMinus",
  "database-off": "IconDatabaseOff",
  "database-plus": "IconDatabasePlus",
  "database-search": "IconDatabaseSearch",
  "database-share": "IconDatabaseShare",
  "database-smile": "IconDatabaseSmile",
  "database-star": "IconDatabaseStar",
  "database-x": "IconDatabaseX",
  decimal: "IconDecimal",
  deer: "IconDeer",
  delta: "IconDelta",
  dental: "IconDental",
  "dental-broken": "IconDentalBroken",
  "dental-off": "IconDentalOff",
  deselect: "IconDeselect",
  desk: "IconDesk",
  details: "IconDetails",
  "details-off": "IconDetailsOff",
  "device-airpods": "IconDeviceAirpods",
  "device-airpods-case": "IconDeviceAirpodsCase",
  "device-airtag": "IconDeviceAirtag",
  "device-analytics": "IconDeviceAnalytics",
  "device-audio-tape": "IconDeviceAudioTape",
  "device-camera-phone": "IconDeviceCameraPhone",
  "device-cctv": "IconDeviceCctv",
  "device-cctv-off": "IconDeviceCctvOff",
  "device-computer-camera": "IconDeviceComputerCamera",
  "device-computer-camera-off": "IconDeviceComputerCameraOff",
  "device-desktop": "IconDeviceDesktop",
  "device-desktop-analytics": "IconDeviceDesktopAnalytics",
  "device-desktop-bolt": "IconDeviceDesktopBolt",
  "device-desktop-cancel": "IconDeviceDesktopCancel",
  "device-desktop-check": "IconDeviceDesktopCheck",
  "device-desktop-code": "IconDeviceDesktopCode",
  "device-desktop-cog": "IconDeviceDesktopCog",
  "device-desktop-dollar": "IconDeviceDesktopDollar",
  "device-desktop-down": "IconDeviceDesktopDown",
  "device-desktop-exclamation": "IconDeviceDesktopExclamation",
  "device-desktop-heart": "IconDeviceDesktopHeart",
  "device-desktop-minus": "IconDeviceDesktopMinus",
  "device-desktop-off": "IconDeviceDesktopOff",
  "device-desktop-pause": "IconDeviceDesktopPause",
  "device-desktop-pin": "IconDeviceDesktopPin",
  "device-desktop-plus": "IconDeviceDesktopPlus",
  "device-desktop-question": "IconDeviceDesktopQuestion",
  "device-desktop-search": "IconDeviceDesktopSearch",
  "device-desktop-share": "IconDeviceDesktopShare",
  "device-desktop-star": "IconDeviceDesktopStar",
  "device-desktop-up": "IconDeviceDesktopUp",
  "device-desktop-x": "IconDeviceDesktopX",
  "device-floppy": "IconDeviceFloppy",
  "device-gamepad": "IconDeviceGamepad",
  "device-gamepad-2": "IconDeviceGamepad2",
  "device-gamepad-3": "IconDeviceGamepad3",
  "device-heart-monitor": "IconDeviceHeartMonitor",
  "device-heart-monitor-filled": "IconDeviceHeartMonitorFilled",
  "device-imac": "IconDeviceImac",
  "device-imac-bolt": "IconDeviceImacBolt",
  "device-imac-cancel": "IconDeviceImacCancel",
  "device-imac-check": "IconDeviceImacCheck",
  "device-imac-code": "IconDeviceImacCode",
  "device-imac-cog": "IconDeviceImacCog",
  "device-imac-dollar": "IconDeviceImacDollar",
  "device-imac-down": "IconDeviceImacDown",
  "device-imac-exclamation": "IconDeviceImacExclamation",
  "device-imac-heart": "IconDeviceImacHeart",
  "device-imac-minus": "IconDeviceImacMinus",
  "device-imac-off": "IconDeviceImacOff",
  "device-imac-pause": "IconDeviceImacPause",
  "device-imac-pin": "IconDeviceImacPin",
  "device-imac-plus": "IconDeviceImacPlus",
  "device-imac-question": "IconDeviceImacQuestion",
  "device-imac-search": "IconDeviceImacSearch",
  "device-imac-share": "IconDeviceImacShare",
  "device-imac-star": "IconDeviceImacStar",
  "device-imac-up": "IconDeviceImacUp",
  "device-imac-x": "IconDeviceImacX",
  "device-ipad": "IconDeviceIpad",
  "device-ipad-bolt": "IconDeviceIpadBolt",
  "device-ipad-cancel": "IconDeviceIpadCancel",
  "device-ipad-check": "IconDeviceIpadCheck",
  "device-ipad-code": "IconDeviceIpadCode",
  "device-ipad-cog": "IconDeviceIpadCog",
  "device-ipad-dollar": "IconDeviceIpadDollar",
  "device-ipad-down": "IconDeviceIpadDown",
  "device-ipad-exclamation": "IconDeviceIpadExclamation",
  "device-ipad-heart": "IconDeviceIpadHeart",
  "device-ipad-horizontal": "IconDeviceIpadHorizontal",
  "device-ipad-horizontal-bolt": "IconDeviceIpadHorizontalBolt",
  "device-ipad-horizontal-cancel": "IconDeviceIpadHorizontalCancel",
  "device-ipad-horizontal-check": "IconDeviceIpadHorizontalCheck",
  "device-ipad-horizontal-code": "IconDeviceIpadHorizontalCode",
  "device-ipad-horizontal-cog": "IconDeviceIpadHorizontalCog",
  "device-ipad-horizontal-dollar": "IconDeviceIpadHorizontalDollar",
  "device-ipad-horizontal-down": "IconDeviceIpadHorizontalDown",
  "device-ipad-horizontal-exclamation": "IconDeviceIpadHorizontalExclamation",
  "device-ipad-horizontal-heart": "IconDeviceIpadHorizontalHeart",
  "device-ipad-horizontal-minus": "IconDeviceIpadHorizontalMinus",
  "device-ipad-horizontal-off": "IconDeviceIpadHorizontalOff",
  "device-ipad-horizontal-pause": "IconDeviceIpadHorizontalPause",
  "device-ipad-horizontal-pin": "IconDeviceIpadHorizontalPin",
  "device-ipad-horizontal-plus": "IconDeviceIpadHorizontalPlus",
  "device-ipad-horizontal-question": "IconDeviceIpadHorizontalQuestion",
  "device-ipad-horizontal-search": "IconDeviceIpadHorizontalSearch",
  "device-ipad-horizontal-share": "IconDeviceIpadHorizontalShare",
  "device-ipad-horizontal-star": "IconDeviceIpadHorizontalStar",
  "device-ipad-horizontal-up": "IconDeviceIpadHorizontalUp",
  "device-ipad-horizontal-x": "IconDeviceIpadHorizontalX",
  "device-ipad-minus": "IconDeviceIpadMinus",
  "device-ipad-off": "IconDeviceIpadOff",
  "device-ipad-pause": "IconDeviceIpadPause",
  "device-ipad-pin": "IconDeviceIpadPin",
  "device-ipad-plus": "IconDeviceIpadPlus",
  "device-ipad-question": "IconDeviceIpadQuestion",
  "device-ipad-search": "IconDeviceIpadSearch",
  "device-ipad-share": "IconDeviceIpadShare",
  "device-ipad-star": "IconDeviceIpadStar",
  "device-ipad-up": "IconDeviceIpadUp",
  "device-ipad-x": "IconDeviceIpadX",
  "device-landline-phone": "IconDeviceLandlinePhone",
  "device-laptop": "IconDeviceLaptop",
  "device-laptop-off": "IconDeviceLaptopOff",
  "device-mobile": "IconDeviceMobile",
  "device-mobile-bolt": "IconDeviceMobileBolt",
  "device-mobile-cancel": "IconDeviceMobileCancel",
  "device-mobile-charging": "IconDeviceMobileCharging",
  "device-mobile-check": "IconDeviceMobileCheck",
  "device-mobile-code": "IconDeviceMobileCode",
  "device-mobile-cog": "IconDeviceMobileCog",
  "device-mobile-dollar": "IconDeviceMobileDollar",
  "device-mobile-down": "IconDeviceMobileDown",
  "device-mobile-exclamation": "IconDeviceMobileExclamation",
  "device-mobile-filled": "IconDeviceMobileFilled",
  "device-mobile-heart": "IconDeviceMobileHeart",
  "device-mobile-message": "IconDeviceMobileMessage",
  "device-mobile-minus": "IconDeviceMobileMinus",
  "device-mobile-off": "IconDeviceMobileOff",
  "device-mobile-pause": "IconDeviceMobilePause",
  "device-mobile-pin": "IconDeviceMobilePin",
  "device-mobile-plus": "IconDeviceMobilePlus",
  "device-mobile-question": "IconDeviceMobileQuestion",
  "device-mobile-rotated": "IconDeviceMobileRotated",
  "device-mobile-search": "IconDeviceMobileSearch",
  "device-mobile-share": "IconDeviceMobileShare",
  "device-mobile-star": "IconDeviceMobileStar",
  "device-mobile-up": "IconDeviceMobileUp",
  "device-mobile-vibration": "IconDeviceMobileVibration",
  "device-mobile-x": "IconDeviceMobileX",
  "device-nintendo": "IconDeviceNintendo",
  "device-nintendo-off": "IconDeviceNintendoOff",
  "device-projector": "IconDeviceProjector",
  "device-remote": "IconDeviceRemote",
  "device-sd-card": "IconDeviceSdCard",
  "device-sim": "IconDeviceSim",
  "device-sim-1": "IconDeviceSim1",
  "device-sim-2": "IconDeviceSim2",
  "device-sim-3": "IconDeviceSim3",
  "device-speaker": "IconDeviceSpeaker",
  "device-speaker-off": "IconDeviceSpeakerOff",
  "device-tablet": "IconDeviceTablet",
  "device-tablet-bolt": "IconDeviceTabletBolt",
  "device-tablet-cancel": "IconDeviceTabletCancel",
  "device-tablet-check": "IconDeviceTabletCheck",
  "device-tablet-code": "IconDeviceTabletCode",
  "device-tablet-cog": "IconDeviceTabletCog",
  "device-tablet-dollar": "IconDeviceTabletDollar",
  "device-tablet-down": "IconDeviceTabletDown",
  "device-tablet-exclamation": "IconDeviceTabletExclamation",
  "device-tablet-filled": "IconDeviceTabletFilled",
  "device-tablet-heart": "IconDeviceTabletHeart",
  "device-tablet-minus": "IconDeviceTabletMinus",
  "device-tablet-off": "IconDeviceTabletOff",
  "device-tablet-pause": "IconDeviceTabletPause",
  "device-tablet-pin": "IconDeviceTabletPin",
  "device-tablet-plus": "IconDeviceTabletPlus",
  "device-tablet-question": "IconDeviceTabletQuestion",
  "device-tablet-search": "IconDeviceTabletSearch",
  "device-tablet-share": "IconDeviceTabletShare",
  "device-tablet-star": "IconDeviceTabletStar",
  "device-tablet-up": "IconDeviceTabletUp",
  "device-tablet-x": "IconDeviceTabletX",
  "device-tv": "IconDeviceTv",
  "device-tv-off": "IconDeviceTvOff",
  "device-tv-old": "IconDeviceTvOld",
  "device-unknown": "IconDeviceUnknown",
  "device-usb": "IconDeviceUsb",
  "device-vision-pro": "IconDeviceVisionPro",
  "device-watch": "IconDeviceWatch",
  "device-watch-bolt": "IconDeviceWatchBolt",
  "device-watch-cancel": "IconDeviceWatchCancel",
  "device-watch-check": "IconDeviceWatchCheck",
  "device-watch-code": "IconDeviceWatchCode",
  "device-watch-cog": "IconDeviceWatchCog",
  "device-watch-dollar": "IconDeviceWatchDollar",
  "device-watch-down": "IconDeviceWatchDown",
  "device-watch-exclamation": "IconDeviceWatchExclamation",
  "device-watch-heart": "IconDeviceWatchHeart",
  "device-watch-minus": "IconDeviceWatchMinus",
  "device-watch-off": "IconDeviceWatchOff",
  "device-watch-pause": "IconDeviceWatchPause",
  "device-watch-pin": "IconDeviceWatchPin",
  "device-watch-plus": "IconDeviceWatchPlus",
  "device-watch-question": "IconDeviceWatchQuestion",
  "device-watch-search": "IconDeviceWatchSearch",
  "device-watch-share": "IconDeviceWatchShare",
  "device-watch-star": "IconDeviceWatchStar",
  "device-watch-stats": "IconDeviceWatchStats",
  "device-watch-stats-2": "IconDeviceWatchStats2",
  "device-watch-up": "IconDeviceWatchUp",
  "device-watch-x": "IconDeviceWatchX",
  devices: "IconDevices",
  "devices-2": "IconDevices2",
  "devices-bolt": "IconDevicesBolt",
  "devices-cancel": "IconDevicesCancel",
  "devices-check": "IconDevicesCheck",
  "devices-code": "IconDevicesCode",
  "devices-cog": "IconDevicesCog",
  "devices-dollar": "IconDevicesDollar",
  "devices-down": "IconDevicesDown",
  "devices-exclamation": "IconDevicesExclamation",
  "devices-heart": "IconDevicesHeart",
  "devices-minus": "IconDevicesMinus",
  "devices-off": "IconDevicesOff",
  "devices-pause": "IconDevicesPause",
  "devices-pc": "IconDevicesPc",
  "devices-pc-off": "IconDevicesPcOff",
  "devices-pin": "IconDevicesPin",
  "devices-plus": "IconDevicesPlus",
  "devices-question": "IconDevicesQuestion",
  "devices-search": "IconDevicesSearch",
  "devices-share": "IconDevicesShare",
  "devices-star": "IconDevicesStar",
  "devices-up": "IconDevicesUp",
  "devices-x": "IconDevicesX",
  diabolo: "IconDiabolo",
  "diabolo-off": "IconDiaboloOff",
  "diabolo-plus": "IconDiaboloPlus",
  dialpad: "IconDialpad",
  "dialpad-filled": "IconDialpadFilled",
  "dialpad-off": "IconDialpadOff",
  diamond: "IconDiamond",
  "diamond-filled": "IconDiamondFilled",
  "diamond-off": "IconDiamondOff",
  diamonds: "IconDiamonds",
  "diamonds-filled": "IconDiamondsFilled",
  dice: "IconDice",
  "dice-1": "IconDice1",
  "dice-1-filled": "IconDice1Filled",
  "dice-2": "IconDice2",
  "dice-2-filled": "IconDice2Filled",
  "dice-3": "IconDice3",
  "dice-3-filled": "IconDice3Filled",
  "dice-4": "IconDice4",
  "dice-4-filled": "IconDice4Filled",
  "dice-5": "IconDice5",
  "dice-5-filled": "IconDice5Filled",
  "dice-6": "IconDice6",
  "dice-6-filled": "IconDice6Filled",
  "dice-filled": "IconDiceFilled",
  dimensions: "IconDimensions",
  direction: "IconDirection",
  "direction-arrows": "IconDirectionArrows",
  "direction-horizontal": "IconDirectionHorizontal",
  "direction-sign": "IconDirectionSign",
  "direction-sign-filled": "IconDirectionSignFilled",
  "direction-sign-off": "IconDirectionSignOff",
  directions: "IconDirections",
  "directions-off": "IconDirectionsOff",
  disabled: "IconDisabled",
  "disabled-2": "IconDisabled2",
  "disabled-off": "IconDisabledOff",
  disc: "IconDisc",
  "disc-golf": "IconDiscGolf",
  "disc-off": "IconDiscOff",
  discount: "IconDiscount",
  "discount-off": "IconDiscountOff",
  divide: "IconDivide",
  dna: "IconDna",
  "dna-2": "IconDna2",
  "dna-2-off": "IconDna2Off",
  "dna-off": "IconDnaOff",
  dog: "IconDog",
  "dog-bowl": "IconDogBowl",
  door: "IconDoor",
  "door-enter": "IconDoorEnter",
  "door-exit": "IconDoorExit",
  "door-off": "IconDoorOff",
  dots: "IconDots",
  "dots-circle-horizontal": "IconDotsCircleHorizontal",
  "dots-diagonal": "IconDotsDiagonal",
  "dots-diagonal-2": "IconDotsDiagonal2",
  "dots-vertical": "IconDotsVertical",
  download: "IconDownload",
  "download-off": "IconDownloadOff",
  "drag-drop": "IconDragDrop",
  "drag-drop-2": "IconDragDrop2",
  drone: "IconDrone",
  "drone-off": "IconDroneOff",
  "drop-circle": "IconDropCircle",
  droplet: "IconDroplet",
  "droplet-bolt": "IconDropletBolt",
  "droplet-cancel": "IconDropletCancel",
  "droplet-check": "IconDropletCheck",
  "droplet-code": "IconDropletCode",
  "droplet-cog": "IconDropletCog",
  "droplet-dollar": "IconDropletDollar",
  "droplet-down": "IconDropletDown",
  "droplet-exclamation": "IconDropletExclamation",
  "droplet-filled": "IconDropletFilled",
  "droplet-half": "IconDropletHalf",
  "droplet-half-2": "IconDropletHalf2",
  "droplet-half-2-filled": "IconDropletHalf2Filled",
  "droplet-half-filled": "IconDropletHalfFilled",
  "droplet-heart": "IconDropletHeart",
  "droplet-minus": "IconDropletMinus",
  "droplet-off": "IconDropletOff",
  "droplet-pause": "IconDropletPause",
  "droplet-pin": "IconDropletPin",
  "droplet-plus": "IconDropletPlus",
  "droplet-question": "IconDropletQuestion",
  "droplet-search": "IconDropletSearch",
  "droplet-share": "IconDropletShare",
  "droplet-star": "IconDropletStar",
  "droplet-up": "IconDropletUp",
  "droplet-x": "IconDropletX",
  droplets: "IconDroplets",
  "dual-screen": "IconDualScreen",
  dumpling: "IconDumpling",
  "e-passport": "IconEPassport",
  ear: "IconEar",
  "ear-off": "IconEarOff",
  "ear-scan": "IconEarScan",
  "ease-in": "IconEaseIn",
  "ease-in-control-point": "IconEaseInControlPoint",
  "ease-in-out": "IconEaseInOut",
  "ease-in-out-control-points": "IconEaseInOutControlPoints",
  "ease-out": "IconEaseOut",
  "ease-out-control-point": "IconEaseOutControlPoint",
  edit: "IconEdit",
  "edit-circle": "IconEditCircle",
  "edit-circle-off": "IconEditCircleOff",
  "edit-off": "IconEditOff",
  egg: "IconEgg",
  "egg-cracked": "IconEggCracked",
  "egg-filled": "IconEggFilled",
  "egg-fried": "IconEggFried",
  "egg-off": "IconEggOff",
  eggs: "IconEggs",
  elevator: "IconElevator",
  "elevator-off": "IconElevatorOff",
  "emergency-bed": "IconEmergencyBed",
  empathize: "IconEmpathize",
  "empathize-off": "IconEmpathizeOff",
  emphasis: "IconEmphasis",
  engine: "IconEngine",
  "engine-off": "IconEngineOff",
  equal: "IconEqual",
  "equal-double": "IconEqualDouble",
  "equal-not": "IconEqualNot",
  eraser: "IconEraser",
  "eraser-off": "IconEraserOff",
  "error-404": "IconError404",
  "error-404-off": "IconError404Off",
  escalator: "IconEscalator",
  "escalator-down": "IconEscalatorDown",
  "escalator-up": "IconEscalatorUp",
  exchange: "IconExchange",
  "exchange-off": "IconExchangeOff",
  "exclamation-circle": "IconExclamationCircle",
  "exclamation-circle-filled": "IconExclamationCircleFilled",
  "exclamation-mark": "IconExclamationMark",
  "exclamation-mark-off": "IconExclamationMarkOff",
  explicit: "IconExplicit",
  "explicit-off": "IconExplicitOff",
  exposure: "IconExposure",
  "exposure-0": "IconExposure0",
  "exposure-minus-1": "IconExposureMinus1",
  "exposure-minus-2": "IconExposureMinus2",
  "exposure-off": "IconExposureOff",
  "exposure-plus-1": "IconExposurePlus1",
  "exposure-plus-2": "IconExposurePlus2",
  "external-link": "IconExternalLink",
  "external-link-off": "IconExternalLinkOff",
  eye: "IconEye",
  "eye-bitcoin": "IconEyeBitcoin",
  "eye-bolt": "IconEyeBolt",
  "eye-cancel": "IconEyeCancel",
  "eye-check": "IconEyeCheck",
  "eye-closed": "IconEyeClosed",
  "eye-code": "IconEyeCode",
  "eye-cog": "IconEyeCog",
  "eye-discount": "IconEyeDiscount",
  "eye-dollar": "IconEyeDollar",
  "eye-dotted": "IconEyeDotted",
  "eye-down": "IconEyeDown",
  "eye-edit": "IconEyeEdit",
  "eye-exclamation": "IconEyeExclamation",
  "eye-filled": "IconEyeFilled",
  "eye-heart": "IconEyeHeart",
  "eye-minus": "IconEyeMinus",
  "eye-off": "IconEyeOff",
  "eye-pause": "IconEyePause",
  "eye-pin": "IconEyePin",
  "eye-plus": "IconEyePlus",
  "eye-question": "IconEyeQuestion",
  "eye-search": "IconEyeSearch",
  "eye-share": "IconEyeShare",
  "eye-star": "IconEyeStar",
  "eye-table": "IconEyeTable",
  "eye-up": "IconEyeUp",
  "eye-x": "IconEyeX",
  eyeglass: "IconEyeglass",
  "eyeglass-2": "IconEyeglass2",
  "eyeglass-off": "IconEyeglassOff",
  "face-id": "IconFaceId",
  "face-id-error": "IconFaceIdError",
  "face-mask": "IconFaceMask",
  "face-mask-off": "IconFaceMaskOff",
  fall: "IconFall",
  favicon: "IconFavicon",
  feather: "IconFeather",
  "feather-off": "IconFeatherOff",
  fence: "IconFence",
  "fence-off": "IconFenceOff",
  "fidget-spinner": "IconFidgetSpinner",
  file: "IconFile",
  "file-3-d": "IconFile3d",
  "file-alert": "IconFileAlert",
  "file-analytics": "IconFileAnalytics",
  "file-arrow-left": "IconFileArrowLeft",
  "file-arrow-right": "IconFileArrowRight",
  "file-barcode": "IconFileBarcode",
  "file-broken": "IconFileBroken",
  "file-certificate": "IconFileCertificate",
  "file-chart": "IconFileChart",
  "file-check": "IconFileCheck",
  "file-code": "IconFileCode",
  "file-code-2": "IconFileCode2",
  "file-cv": "IconFileCv",
  "file-database": "IconFileDatabase",
  "file-delta": "IconFileDelta",
  "file-description": "IconFileDescription",
  "file-diff": "IconFileDiff",
  "file-digit": "IconFileDigit",
  "file-dislike": "IconFileDislike",
  "file-dollar": "IconFileDollar",
  "file-dots": "IconFileDots",
  "file-download": "IconFileDownload",
  "file-euro": "IconFileEuro",
  "file-excel": "IconFileExcel",
  "file-export": "IconFileExport",
  "file-filled": "IconFileFilled",
  "file-function": "IconFileFunction",
  "file-horizontal": "IconFileHorizontal",
  "file-import": "IconFileImport",
  "file-infinity": "IconFileInfinity",
  "file-info": "IconFileInfo",
  "file-invoice": "IconFileInvoice",
  "file-isr": "IconFileIsr",
  "file-lambda": "IconFileLambda",
  "file-like": "IconFileLike",
  "file-minus": "IconFileMinus",
  "file-music": "IconFileMusic",
  "file-neutral": "IconFileNeutral",
  "file-off": "IconFileOff",
  "file-orientation": "IconFileOrientation",
  "file-pencil": "IconFilePencil",
  "file-percent": "IconFilePercent",
  "file-phone": "IconFilePhone",
  "file-plus": "IconFilePlus",
  "file-power": "IconFilePower",
  "file-report": "IconFileReport",
  "file-rss": "IconFileRss",
  "file-sad": "IconFileSad",
  "file-scissors": "IconFileScissors",
  "file-search": "IconFileSearch",
  "file-settings": "IconFileSettings",
  "file-shredder": "IconFileShredder",
  "file-signal": "IconFileSignal",
  "file-smile": "IconFileSmile",
  "file-spreadsheet": "IconFileSpreadsheet",
  "file-stack": "IconFileStack",
  "file-star": "IconFileStar",
  "file-symlink": "IconFileSymlink",
  "file-text": "IconFileText",
  "file-text-ai": "IconFileTextAi",
  "file-time": "IconFileTime",
  "file-type-bmp": "IconFileTypeBmp",
  "file-type-css": "IconFileTypeCss",
  "file-type-csv": "IconFileTypeCsv",
  "file-type-doc": "IconFileTypeDoc",
  "file-type-docx": "IconFileTypeDocx",
  "file-type-html": "IconFileTypeHtml",
  "file-type-jpg": "IconFileTypeJpg",
  "file-type-js": "IconFileTypeJs",
  "file-type-jsx": "IconFileTypeJsx",
  "file-type-pdf": "IconFileTypePdf",
  "file-type-php": "IconFileTypePhp",
  "file-type-png": "IconFileTypePng",
  "file-type-ppt": "IconFileTypePpt",
  "file-type-rs": "IconFileTypeRs",
  "file-type-sql": "IconFileTypeSql",
  "file-type-svg": "IconFileTypeSvg",
  "file-type-ts": "IconFileTypeTs",
  "file-type-tsx": "IconFileTypeTsx",
  "file-type-txt": "IconFileTypeTxt",
  "file-type-vue": "IconFileTypeVue",
  "file-type-xls": "IconFileTypeXls",
  "file-type-xml": "IconFileTypeXml",
  "file-type-zip": "IconFileTypeZip",
  "file-typography": "IconFileTypography",
  "file-unknown": "IconFileUnknown",
  "file-upload": "IconFileUpload",
  "file-vector": "IconFileVector",
  "file-word": "IconFileWord",
  "file-x": "IconFileX",
  "file-x-filled": "IconFileXFilled",
  "file-zip": "IconFileZip",
  files: "IconFiles",
  "files-off": "IconFilesOff",
  filter: "IconFilter",
  "filter-bolt": "IconFilterBolt",
  "filter-cancel": "IconFilterCancel",
  "filter-check": "IconFilterCheck",
  "filter-code": "IconFilterCode",
  "filter-cog": "IconFilterCog",
  "filter-discount": "IconFilterDiscount",
  "filter-dollar": "IconFilterDollar",
  "filter-down": "IconFilterDown",
  "filter-edit": "IconFilterEdit",
  "filter-exclamation": "IconFilterExclamation",
  "filter-filled": "IconFilterFilled",
  "filter-heart": "IconFilterHeart",
  "filter-minus": "IconFilterMinus",
  "filter-off": "IconFilterOff",
  "filter-pause": "IconFilterPause",
  "filter-pin": "IconFilterPin",
  "filter-plus": "IconFilterPlus",
  "filter-question": "IconFilterQuestion",
  "filter-search": "IconFilterSearch",
  "filter-share": "IconFilterShare",
  "filter-star": "IconFilterStar",
  "filter-up": "IconFilterUp",
  "filter-x": "IconFilterX",
  filters: "IconFilters",
  fingerprint: "IconFingerprint",
  "fingerprint-off": "IconFingerprintOff",
  "fingerprint-scan": "IconFingerprintScan",
  "fire-extinguisher": "IconFireExtinguisher",
  "fire-hydrant": "IconFireHydrant",
  "fire-hydrant-off": "IconFireHydrantOff",
  firetruck: "IconFiretruck",
  "first-aid-kit": "IconFirstAidKit",
  "first-aid-kit-off": "IconFirstAidKitOff",
  fish: "IconFish",
  "fish-bone": "IconFishBone",
  "fish-christianity": "IconFishChristianity",
  "fish-hook": "IconFishHook",
  "fish-hook-off": "IconFishHookOff",
  "fish-off": "IconFishOff",
  flag: "IconFlag",
  "flag-2": "IconFlag2",
  "flag-2-filled": "IconFlag2Filled",
  "flag-2-off": "IconFlag2Off",
  "flag-3": "IconFlag3",
  "flag-3-filled": "IconFlag3Filled",
  "flag-bitcoin": "IconFlagBitcoin",
  "flag-bolt": "IconFlagBolt",
  "flag-cancel": "IconFlagCancel",
  "flag-check": "IconFlagCheck",
  "flag-code": "IconFlagCode",
  "flag-cog": "IconFlagCog",
  "flag-discount": "IconFlagDiscount",
  "flag-dollar": "IconFlagDollar",
  "flag-down": "IconFlagDown",
  "flag-exclamation": "IconFlagExclamation",
  "flag-filled": "IconFlagFilled",
  "flag-heart": "IconFlagHeart",
  "flag-minus": "IconFlagMinus",
  "flag-off": "IconFlagOff",
  "flag-pause": "IconFlagPause",
  "flag-pin": "IconFlagPin",
  "flag-plus": "IconFlagPlus",
  "flag-question": "IconFlagQuestion",
  "flag-search": "IconFlagSearch",
  "flag-share": "IconFlagShare",
  "flag-star": "IconFlagStar",
  "flag-up": "IconFlagUp",
  "flag-x": "IconFlagX",
  flame: "IconFlame",
  "flame-off": "IconFlameOff",
  flare: "IconFlare",
  flask: "IconFlask",
  "flask-2": "IconFlask2",
  "flask-2-filled": "IconFlask2Filled",
  "flask-2-off": "IconFlask2Off",
  "flask-filled": "IconFlaskFilled",
  "flask-off": "IconFlaskOff",
  "flip-flops": "IconFlipFlops",
  "flip-horizontal": "IconFlipHorizontal",
  "flip-vertical": "IconFlipVertical",
  "float-center": "IconFloatCenter",
  "float-left": "IconFloatLeft",
  "float-none": "IconFloatNone",
  "float-right": "IconFloatRight",
  flower: "IconFlower",
  "flower-off": "IconFlowerOff",
  focus: "IconFocus",
  "focus-2": "IconFocus2",
  "focus-auto": "IconFocusAuto",
  "focus-centered": "IconFocusCentered",
  fold: "IconFold",
  "fold-down": "IconFoldDown",
  "fold-up": "IconFoldUp",
  folder: "IconFolder",
  "folder-bolt": "IconFolderBolt",
  "folder-cancel": "IconFolderCancel",
  "folder-check": "IconFolderCheck",
  "folder-code": "IconFolderCode",
  "folder-cog": "IconFolderCog",
  "folder-dollar": "IconFolderDollar",
  "folder-down": "IconFolderDown",
  "folder-exclamation": "IconFolderExclamation",
  "folder-filled": "IconFolderFilled",
  "folder-heart": "IconFolderHeart",
  "folder-minus": "IconFolderMinus",
  "folder-off": "IconFolderOff",
  "folder-open": "IconFolderOpen",
  "folder-pause": "IconFolderPause",
  "folder-pin": "IconFolderPin",
  "folder-plus": "IconFolderPlus",
  "folder-question": "IconFolderQuestion",
  "folder-root": "IconFolderRoot",
  "folder-search": "IconFolderSearch",
  "folder-share": "IconFolderShare",
  "folder-star": "IconFolderStar",
  "folder-symlink": "IconFolderSymlink",
  "folder-up": "IconFolderUp",
  "folder-x": "IconFolderX",
  folders: "IconFolders",
  "folders-off": "IconFoldersOff",
  forbid: "IconForbid",
  "forbid-2": "IconForbid2",
  "forbid-2-filled": "IconForbid2Filled",
  "forbid-filled": "IconForbidFilled",
  forklift: "IconForklift",
  forms: "IconForms",
  fountain: "IconFountain",
  "fountain-filled": "IconFountainFilled",
  "fountain-off": "IconFountainOff",
  frame: "IconFrame",
  "frame-off": "IconFrameOff",
  "free-rights": "IconFreeRights",
  "freeze-column": "IconFreezeColumn",
  "freeze-row": "IconFreezeRow",
  "freeze-row-column": "IconFreezeRowColumn",
  fridge: "IconFridge",
  "fridge-off": "IconFridgeOff",
  friends: "IconFriends",
  "friends-off": "IconFriendsOff",
  frustum: "IconFrustum",
  "frustum-off": "IconFrustumOff",
  "frustum-plus": "IconFrustumPlus",
  function: "IconFunction",
  "function-filled": "IconFunctionFilled",
  "function-off": "IconFunctionOff",
  galaxy: "IconGalaxy",
  "garden-cart": "IconGardenCart",
  "garden-cart-off": "IconGardenCartOff",
  "gas-station": "IconGasStation",
  "gas-station-off": "IconGasStationOff",
  gauge: "IconGauge",
  "gauge-filled": "IconGaugeFilled",
  "gauge-off": "IconGaugeOff",
  gavel: "IconGavel",
  "gender-agender": "IconGenderAgender",
  "gender-androgyne": "IconGenderAndrogyne",
  "gender-bigender": "IconGenderBigender",
  "gender-demiboy": "IconGenderDemiboy",
  "gender-demigirl": "IconGenderDemigirl",
  "gender-epicene": "IconGenderEpicene",
  "gender-female": "IconGenderFemale",
  "gender-femme": "IconGenderFemme",
  "gender-genderfluid": "IconGenderGenderfluid",
  "gender-genderless": "IconGenderGenderless",
  "gender-genderqueer": "IconGenderGenderqueer",
  "gender-hermaphrodite": "IconGenderHermaphrodite",
  "gender-intergender": "IconGenderIntergender",
  "gender-male": "IconGenderMale",
  "gender-neutrois": "IconGenderNeutrois",
  "gender-third": "IconGenderThird",
  "gender-transgender": "IconGenderTransgender",
  "gender-trasvesti": "IconGenderTrasvesti",
  geometry: "IconGeometry",
  ghost: "IconGhost",
  "ghost-2": "IconGhost2",
  "ghost-2-filled": "IconGhost2Filled",
  "ghost-3": "IconGhost3",
  "ghost-filled": "IconGhostFilled",
  "ghost-off": "IconGhostOff",
  gif: "IconGif",
  gift: "IconGift",
  "gift-card": "IconGiftCard",
  "gift-card-filled": "IconGiftCardFilled",
  "gift-filled": "IconGiftFilled",
  "gift-off": "IconGiftOff",
  "git-branch": "IconGitBranch",
  "git-branch-deleted": "IconGitBranchDeleted",
  "git-cherry-pick": "IconGitCherryPick",
  "git-commit": "IconGitCommit",
  "git-compare": "IconGitCompare",
  "git-fork": "IconGitFork",
  "git-merge": "IconGitMerge",
  "git-pull-request": "IconGitPullRequest",
  "git-pull-request-closed": "IconGitPullRequestClosed",
  "git-pull-request-draft": "IconGitPullRequestDraft",
  gizmo: "IconGizmo",
  glass: "IconGlass",
  "glass-champagne": "IconGlassChampagne",
  "glass-cocktail": "IconGlassCocktail",
  "glass-full": "IconGlassFull",
  "glass-full-filled": "IconGlassFullFilled",
  "glass-gin": "IconGlassGin",
  "glass-off": "IconGlassOff",
  globe: "IconGlobe",
  "globe-filled": "IconGlobeFilled",
  "globe-off": "IconGlobeOff",
  "go-game": "IconGoGame",
  golf: "IconGolf",
  "golf-off": "IconGolfOff",
  gps: "IconGps",
  "gps-filled": "IconGpsFilled",
  gradienter: "IconGradienter",
  grain: "IconGrain",
  graph: "IconGraph",
  "graph-filled": "IconGraphFilled",
  "graph-off": "IconGraphOff",
  grave: "IconGrave",
  "grave-2": "IconGrave2",
  "grid-3-x-3": "IconGrid3x3",
  "grid-4-x-4": "IconGrid4x4",
  "grid-dots": "IconGridDots",
  "grid-goldenratio": "IconGridGoldenratio",
  "grid-pattern": "IconGridPattern",
  "grid-scan": "IconGridScan",
  grill: "IconGrill",
  "grill-fork": "IconGrillFork",
  "grill-off": "IconGrillOff",
  "grill-spatula": "IconGrillSpatula",
  "grip-horizontal": "IconGripHorizontal",
  "grip-vertical": "IconGripVertical",
  growth: "IconGrowth",
  "guitar-pick": "IconGuitarPick",
  "guitar-pick-filled": "IconGuitarPickFilled",
  gymnastics: "IconGymnastics",
  "h-1": "IconH1",
  "h-2": "IconH2",
  "h-3": "IconH3",
  "h-4": "IconH4",
  "h-5": "IconH5",
  "h-6": "IconH6",
  hammer: "IconHammer",
  "hammer-off": "IconHammerOff",
  "hand-click": "IconHandClick",
  "hand-finger": "IconHandFinger",
  "hand-finger-down": "IconHandFingerDown",
  "hand-finger-left": "IconHandFingerLeft",
  "hand-finger-off": "IconHandFingerOff",
  "hand-finger-right": "IconHandFingerRight",
  "hand-grab": "IconHandGrab",
  "hand-little-finger": "IconHandLittleFinger",
  "hand-love-you": "IconHandLoveYou",
  "hand-middle-finger": "IconHandMiddleFinger",
  "hand-move": "IconHandMove",
  "hand-off": "IconHandOff",
  "hand-ring-finger": "IconHandRingFinger",
  "hand-sanitizer": "IconHandSanitizer",
  "hand-stop": "IconHandStop",
  "hand-three-fingers": "IconHandThreeFingers",
  "hand-two-fingers": "IconHandTwoFingers",
  hanger: "IconHanger",
  "hanger-2": "IconHanger2",
  "hanger-2-filled": "IconHanger2Filled",
  "hanger-off": "IconHangerOff",
  hash: "IconHash",
  haze: "IconHaze",
  "haze-moon": "IconHazeMoon",
  hdr: "IconHdr",
  heading: "IconHeading",
  "heading-off": "IconHeadingOff",
  headphones: "IconHeadphones",
  "headphones-filled": "IconHeadphonesFilled",
  "headphones-off": "IconHeadphonesOff",
  headset: "IconHeadset",
  "headset-off": "IconHeadsetOff",
  "health-recognition": "IconHealthRecognition",
  heart: "IconHeart",
  "heart-bitcoin": "IconHeartBitcoin",
  "heart-bolt": "IconHeartBolt",
  "heart-broken": "IconHeartBroken",
  "heart-cancel": "IconHeartCancel",
  "heart-check": "IconHeartCheck",
  "heart-code": "IconHeartCode",
  "heart-cog": "IconHeartCog",
  "heart-discount": "IconHeartDiscount",
  "heart-dollar": "IconHeartDollar",
  "heart-down": "IconHeartDown",
  "heart-exclamation": "IconHeartExclamation",
  "heart-filled": "IconHeartFilled",
  "heart-handshake": "IconHeartHandshake",
  "heart-minus": "IconHeartMinus",
  "heart-off": "IconHeartOff",
  "heart-pause": "IconHeartPause",
  "heart-pin": "IconHeartPin",
  "heart-plus": "IconHeartPlus",
  "heart-question": "IconHeartQuestion",
  "heart-rate-monitor": "IconHeartRateMonitor",
  "heart-search": "IconHeartSearch",
  "heart-share": "IconHeartShare",
  "heart-star": "IconHeartStar",
  "heart-up": "IconHeartUp",
  "heart-x": "IconHeartX",
  heartbeat: "IconHeartbeat",
  hearts: "IconHearts",
  "hearts-off": "IconHeartsOff",
  helicopter: "IconHelicopter",
  "helicopter-landing": "IconHelicopterLanding",
  helmet: "IconHelmet",
  "helmet-off": "IconHelmetOff",
  help: "IconHelp",
  "help-circle": "IconHelpCircle",
  "help-circle-filled": "IconHelpCircleFilled",
  "help-hexagon": "IconHelpHexagon",
  "help-hexagon-filled": "IconHelpHexagonFilled",
  "help-octagon": "IconHelpOctagon",
  "help-octagon-filled": "IconHelpOctagonFilled",
  "help-off": "IconHelpOff",
  "help-small": "IconHelpSmall",
  "help-square": "IconHelpSquare",
  "help-square-filled": "IconHelpSquareFilled",
  "help-square-rounded": "IconHelpSquareRounded",
  "help-square-rounded-filled": "IconHelpSquareRoundedFilled",
  "help-triangle": "IconHelpTriangle",
  "help-triangle-filled": "IconHelpTriangleFilled",
  hemisphere: "IconHemisphere",
  "hemisphere-off": "IconHemisphereOff",
  "hemisphere-plus": "IconHemispherePlus",
  hexagon: "IconHexagon",
  "hexagon-3-d": "IconHexagon3d",
  "hexagon-filled": "IconHexagonFilled",
  "hexagon-letter-a": "IconHexagonLetterA",
  "hexagon-letter-a-filled": "IconHexagonLetterAFilled",
  "hexagon-letter-b": "IconHexagonLetterB",
  "hexagon-letter-b-filled": "IconHexagonLetterBFilled",
  "hexagon-letter-c": "IconHexagonLetterC",
  "hexagon-letter-c-filled": "IconHexagonLetterCFilled",
  "hexagon-letter-d": "IconHexagonLetterD",
  "hexagon-letter-d-filled": "IconHexagonLetterDFilled",
  "hexagon-letter-e": "IconHexagonLetterE",
  "hexagon-letter-e-filled": "IconHexagonLetterEFilled",
  "hexagon-letter-f": "IconHexagonLetterF",
  "hexagon-letter-f-filled": "IconHexagonLetterFFilled",
  "hexagon-letter-g": "IconHexagonLetterG",
  "hexagon-letter-g-filled": "IconHexagonLetterGFilled",
  "hexagon-letter-h": "IconHexagonLetterH",
  "hexagon-letter-h-filled": "IconHexagonLetterHFilled",
  "hexagon-letter-i": "IconHexagonLetterI",
  "hexagon-letter-i-filled": "IconHexagonLetterIFilled",
  "hexagon-letter-j": "IconHexagonLetterJ",
  "hexagon-letter-j-filled": "IconHexagonLetterJFilled",
  "hexagon-letter-k": "IconHexagonLetterK",
  "hexagon-letter-k-filled": "IconHexagonLetterKFilled",
  "hexagon-letter-l": "IconHexagonLetterL",
  "hexagon-letter-l-filled": "IconHexagonLetterLFilled",
  "hexagon-letter-m": "IconHexagonLetterM",
  "hexagon-letter-m-filled": "IconHexagonLetterMFilled",
  "hexagon-letter-n": "IconHexagonLetterN",
  "hexagon-letter-n-filled": "IconHexagonLetterNFilled",
  "hexagon-letter-o": "IconHexagonLetterO",
  "hexagon-letter-o-filled": "IconHexagonLetterOFilled",
  "hexagon-letter-p": "IconHexagonLetterP",
  "hexagon-letter-p-filled": "IconHexagonLetterPFilled",
  "hexagon-letter-q": "IconHexagonLetterQ",
  "hexagon-letter-q-filled": "IconHexagonLetterQFilled",
  "hexagon-letter-r": "IconHexagonLetterR",
  "hexagon-letter-r-filled": "IconHexagonLetterRFilled",
  "hexagon-letter-s": "IconHexagonLetterS",
  "hexagon-letter-s-filled": "IconHexagonLetterSFilled",
  "hexagon-letter-t": "IconHexagonLetterT",
  "hexagon-letter-t-filled": "IconHexagonLetterTFilled",
  "hexagon-letter-u": "IconHexagonLetterU",
  "hexagon-letter-u-filled": "IconHexagonLetterUFilled",
  "hexagon-letter-v": "IconHexagonLetterV",
  "hexagon-letter-v-filled": "IconHexagonLetterVFilled",
  "hexagon-letter-w": "IconHexagonLetterW",
  "hexagon-letter-w-filled": "IconHexagonLetterWFilled",
  "hexagon-letter-x": "IconHexagonLetterX",
  "hexagon-letter-x-filled": "IconHexagonLetterXFilled",
  "hexagon-letter-y": "IconHexagonLetterY",
  "hexagon-letter-y-filled": "IconHexagonLetterYFilled",
  "hexagon-letter-z": "IconHexagonLetterZ",
  "hexagon-letter-z-filled": "IconHexagonLetterZFilled",
  "hexagon-minus": "IconHexagonMinus",
  "hexagon-minus-2": "IconHexagonMinus2",
  "hexagon-minus-filled": "IconHexagonMinusFilled",
  "hexagon-number-0": "IconHexagonNumber0",
  "hexagon-number-0-filled": "IconHexagonNumber0Filled",
  "hexagon-number-1": "IconHexagonNumber1",
  "hexagon-number-1-filled": "IconHexagonNumber1Filled",
  "hexagon-number-2": "IconHexagonNumber2",
  "hexagon-number-2-filled": "IconHexagonNumber2Filled",
  "hexagon-number-3": "IconHexagonNumber3",
  "hexagon-number-3-filled": "IconHexagonNumber3Filled",
  "hexagon-number-4": "IconHexagonNumber4",
  "hexagon-number-4-filled": "IconHexagonNumber4Filled",
  "hexagon-number-5": "IconHexagonNumber5",
  "hexagon-number-5-filled": "IconHexagonNumber5Filled",
  "hexagon-number-6": "IconHexagonNumber6",
  "hexagon-number-6-filled": "IconHexagonNumber6Filled",
  "hexagon-number-7": "IconHexagonNumber7",
  "hexagon-number-7-filled": "IconHexagonNumber7Filled",
  "hexagon-number-8": "IconHexagonNumber8",
  "hexagon-number-8-filled": "IconHexagonNumber8Filled",
  "hexagon-number-9": "IconHexagonNumber9",
  "hexagon-number-9-filled": "IconHexagonNumber9Filled",
  "hexagon-off": "IconHexagonOff",
  "hexagon-plus": "IconHexagonPlus",
  "hexagon-plus-2": "IconHexagonPlus2",
  "hexagon-plus-filled": "IconHexagonPlusFilled",
  "hexagonal-prism": "IconHexagonalPrism",
  "hexagonal-prism-off": "IconHexagonalPrismOff",
  "hexagonal-prism-plus": "IconHexagonalPrismPlus",
  "hexagonal-pyramid": "IconHexagonalPyramid",
  "hexagonal-pyramid-off": "IconHexagonalPyramidOff",
  "hexagonal-pyramid-plus": "IconHexagonalPyramidPlus",
  hexagons: "IconHexagons",
  "hexagons-off": "IconHexagonsOff",
  hierarchy: "IconHierarchy",
  "hierarchy-2": "IconHierarchy2",
  "hierarchy-3": "IconHierarchy3",
  "hierarchy-off": "IconHierarchyOff",
  highlight: "IconHighlight",
  "highlight-off": "IconHighlightOff",
  history: "IconHistory",
  "history-off": "IconHistoryOff",
  "history-toggle": "IconHistoryToggle",
  home: "IconHome",
  "home-2": "IconHome2",
  "home-bitcoin": "IconHomeBitcoin",
  "home-bolt": "IconHomeBolt",
  "home-cancel": "IconHomeCancel",
  "home-check": "IconHomeCheck",
  "home-cog": "IconHomeCog",
  "home-dollar": "IconHomeDollar",
  "home-dot": "IconHomeDot",
  "home-down": "IconHomeDown",
  "home-eco": "IconHomeEco",
  "home-edit": "IconHomeEdit",
  "home-exclamation": "IconHomeExclamation",
  "home-filled": "IconHomeFilled",
  "home-hand": "IconHomeHand",
  "home-heart": "IconHomeHeart",
  "home-infinity": "IconHomeInfinity",
  "home-link": "IconHomeLink",
  "home-minus": "IconHomeMinus",
  "home-move": "IconHomeMove",
  "home-off": "IconHomeOff",
  "home-plus": "IconHomePlus",
  "home-question": "IconHomeQuestion",
  "home-ribbon": "IconHomeRibbon",
  "home-search": "IconHomeSearch",
  "home-share": "IconHomeShare",
  "home-shield": "IconHomeShield",
  "home-signal": "IconHomeSignal",
  "home-star": "IconHomeStar",
  "home-stats": "IconHomeStats",
  "home-up": "IconHomeUp",
  "home-x": "IconHomeX",
  horse: "IconHorse",
  "horse-toy": "IconHorseToy",
  horseshoe: "IconHorseshoe",
  hospital: "IconHospital",
  "hospital-circle": "IconHospitalCircle",
  "hospital-circle-filled": "IconHospitalCircleFilled",
  "hotel-service": "IconHotelService",
  hourglass: "IconHourglass",
  "hourglass-empty": "IconHourglassEmpty",
  "hourglass-filled": "IconHourglassFilled",
  "hourglass-high": "IconHourglassHigh",
  "hourglass-low": "IconHourglassLow",
  "hourglass-off": "IconHourglassOff",
  "hours-12": "IconHours12",
  "hours-24": "IconHours24",
  html: "IconHtml",
  "http-connect": "IconHttpConnect",
  "http-delete": "IconHttpDelete",
  "http-get": "IconHttpGet",
  "http-head": "IconHttpHead",
  "http-options": "IconHttpOptions",
  "http-patch": "IconHttpPatch",
  "http-post": "IconHttpPost",
  "http-put": "IconHttpPut",
  "http-que": "IconHttpQue",
  "http-trace": "IconHttpTrace",
  "ice-cream": "IconIceCream",
  "ice-cream-2": "IconIceCream2",
  "ice-cream-off": "IconIceCreamOff",
  "ice-skating": "IconIceSkating",
  icons: "IconIcons",
  "icons-off": "IconIconsOff",
  id: "IconId",
  "id-badge": "IconIdBadge",
  "id-badge-2": "IconIdBadge2",
  "id-badge-off": "IconIdBadgeOff",
  "id-off": "IconIdOff",
  ikosaedr: "IconIkosaedr",
  "image-in-picture": "IconImageInPicture",
  inbox: "IconInbox",
  "inbox-off": "IconInboxOff",
  "indent-decrease": "IconIndentDecrease",
  "indent-increase": "IconIndentIncrease",
  infinity: "IconInfinity",
  "infinity-off": "IconInfinityOff",
  "info-circle": "IconInfoCircle",
  "info-circle-filled": "IconInfoCircleFilled",
  "info-hexagon": "IconInfoHexagon",
  "info-hexagon-filled": "IconInfoHexagonFilled",
  "info-octagon": "IconInfoOctagon",
  "info-octagon-filled": "IconInfoOctagonFilled",
  "info-small": "IconInfoSmall",
  "info-square": "IconInfoSquare",
  "info-square-filled": "IconInfoSquareFilled",
  "info-square-rounded": "IconInfoSquareRounded",
  "info-square-rounded-filled": "IconInfoSquareRoundedFilled",
  "info-triangle": "IconInfoTriangle",
  "info-triangle-filled": "IconInfoTriangleFilled",
  "inner-shadow-bottom": "IconInnerShadowBottom",
  "inner-shadow-bottom-filled": "IconInnerShadowBottomFilled",
  "inner-shadow-bottom-left": "IconInnerShadowBottomLeft",
  "inner-shadow-bottom-left-filled": "IconInnerShadowBottomLeftFilled",
  "inner-shadow-bottom-right": "IconInnerShadowBottomRight",
  "inner-shadow-bottom-right-filled": "IconInnerShadowBottomRightFilled",
  "inner-shadow-left": "IconInnerShadowLeft",
  "inner-shadow-left-filled": "IconInnerShadowLeftFilled",
  "inner-shadow-right": "IconInnerShadowRight",
  "inner-shadow-right-filled": "IconInnerShadowRightFilled",
  "inner-shadow-top": "IconInnerShadowTop",
  "inner-shadow-top-filled": "IconInnerShadowTopFilled",
  "inner-shadow-top-left": "IconInnerShadowTopLeft",
  "inner-shadow-top-left-filled": "IconInnerShadowTopLeftFilled",
  "inner-shadow-top-right": "IconInnerShadowTopRight",
  "inner-shadow-top-right-filled": "IconInnerShadowTopRightFilled",
  "input-ai": "IconInputAi",
  "input-check": "IconInputCheck",
  "input-search": "IconInputSearch",
  "input-x": "IconInputX",
  invoice: "IconInvoice",
  ironing: "IconIroning",
  "ironing-1": "IconIroning1",
  "ironing-2": "IconIroning2",
  "ironing-3": "IconIroning3",
  "ironing-filled": "IconIroningFilled",
  "ironing-off": "IconIroningOff",
  "ironing-steam": "IconIroningSteam",
  "ironing-steam-off": "IconIroningSteamOff",
  "irregular-polyhedron": "IconIrregularPolyhedron",
  "irregular-polyhedron-off": "IconIrregularPolyhedronOff",
  "irregular-polyhedron-plus": "IconIrregularPolyhedronPlus",
  italic: "IconItalic",
  jacket: "IconJacket",
  jetpack: "IconJetpack",
  "jetpack-filled": "IconJetpackFilled",
  "jewish-star": "IconJewishStar",
  "jewish-star-filled": "IconJewishStarFilled",
  "join-bevel": "IconJoinBevel",
  "join-round": "IconJoinRound",
  "join-straight": "IconJoinStraight",
  jpg: "IconJpg",
  json: "IconJson",
  "jump-rope": "IconJumpRope",
  karate: "IconKarate",
  kayak: "IconKayak",
  kerning: "IconKerning",
  key: "IconKey",
  "key-filled": "IconKeyFilled",
  "key-off": "IconKeyOff",
  keyboard: "IconKeyboard",
  "keyboard-hide": "IconKeyboardHide",
  "keyboard-off": "IconKeyboardOff",
  "keyboard-show": "IconKeyboardShow",
  keyframe: "IconKeyframe",
  "keyframe-align-center": "IconKeyframeAlignCenter",
  "keyframe-align-center-filled": "IconKeyframeAlignCenterFilled",
  "keyframe-align-horizontal": "IconKeyframeAlignHorizontal",
  "keyframe-align-horizontal-filled": "IconKeyframeAlignHorizontalFilled",
  "keyframe-align-vertical": "IconKeyframeAlignVertical",
  "keyframe-align-vertical-filled": "IconKeyframeAlignVerticalFilled",
  "keyframe-filled": "IconKeyframeFilled",
  keyframes: "IconKeyframes",
  "keyframes-filled": "IconKeyframesFilled",
  label: "IconLabel",
  "label-filled": "IconLabelFilled",
  "label-important": "IconLabelImportant",
  "label-important-filled": "IconLabelImportantFilled",
  "label-off": "IconLabelOff",
  ladder: "IconLadder",
  "ladder-off": "IconLadderOff",
  ladle: "IconLadle",
  lambda: "IconLambda",
  lamp: "IconLamp",
  "lamp-2": "IconLamp2",
  "lamp-off": "IconLampOff",
  lane: "IconLane",
  language: "IconLanguage",
  "language-hiragana": "IconLanguageHiragana",
  "language-katakana": "IconLanguageKatakana",
  "language-off": "IconLanguageOff",
  lasso: "IconLasso",
  "lasso-off": "IconLassoOff",
  "lasso-polygon": "IconLassoPolygon",
  "lasso-polygon-filled": "IconLassoPolygonFilled",
  "laurel-wreath": "IconLaurelWreath",
  "laurel-wreath-1": "IconLaurelWreath1",
  "laurel-wreath-2": "IconLaurelWreath2",
  "laurel-wreath-3": "IconLaurelWreath3",
  "layers-difference": "IconLayersDifference",
  "layers-intersect": "IconLayersIntersect",
  "layers-intersect-2": "IconLayersIntersect2",
  "layers-linked": "IconLayersLinked",
  "layers-off": "IconLayersOff",
  "layers-selected": "IconLayersSelected",
  "layers-selected-bottom": "IconLayersSelectedBottom",
  "layers-subtract": "IconLayersSubtract",
  "layers-union": "IconLayersUnion",
  layout: "IconLayout",
  "layout-2": "IconLayout2",
  "layout-2-filled": "IconLayout2Filled",
  "layout-align-bottom": "IconLayoutAlignBottom",
  "layout-align-bottom-filled": "IconLayoutAlignBottomFilled",
  "layout-align-center": "IconLayoutAlignCenter",
  "layout-align-center-filled": "IconLayoutAlignCenterFilled",
  "layout-align-left": "IconLayoutAlignLeft",
  "layout-align-left-filled": "IconLayoutAlignLeftFilled",
  "layout-align-middle": "IconLayoutAlignMiddle",
  "layout-align-middle-filled": "IconLayoutAlignMiddleFilled",
  "layout-align-right": "IconLayoutAlignRight",
  "layout-align-right-filled": "IconLayoutAlignRightFilled",
  "layout-align-top": "IconLayoutAlignTop",
  "layout-align-top-filled": "IconLayoutAlignTopFilled",
  "layout-board": "IconLayoutBoard",
  "layout-board-split": "IconLayoutBoardSplit",
  "layout-bottombar": "IconLayoutBottombar",
  "layout-bottombar-collapse": "IconLayoutBottombarCollapse",
  "layout-bottombar-collapse-filled": "IconLayoutBottombarCollapseFilled",
  "layout-bottombar-expand": "IconLayoutBottombarExpand",
  "layout-bottombar-expand-filled": "IconLayoutBottombarExpandFilled",
  "layout-bottombar-filled": "IconLayoutBottombarFilled",
  "layout-bottombar-inactive": "IconLayoutBottombarInactive",
  "layout-cards": "IconLayoutCards",
  "layout-cards-filled": "IconLayoutCardsFilled",
  "layout-collage": "IconLayoutCollage",
  "layout-columns": "IconLayoutColumns",
  "layout-dashboard": "IconLayoutDashboard",
  "layout-dashboard-filled": "IconLayoutDashboardFilled",
  "layout-distribute-horizontal": "IconLayoutDistributeHorizontal",
  "layout-distribute-horizontal-filled": "IconLayoutDistributeHorizontalFilled",
  "layout-distribute-vertical": "IconLayoutDistributeVertical",
  "layout-distribute-vertical-filled": "IconLayoutDistributeVerticalFilled",
  "layout-filled": "IconLayoutFilled",
  "layout-grid": "IconLayoutGrid",
  "layout-grid-add": "IconLayoutGridAdd",
  "layout-grid-filled": "IconLayoutGridFilled",
  "layout-grid-remove": "IconLayoutGridRemove",
  "layout-kanban": "IconLayoutKanban",
  "layout-kanban-filled": "IconLayoutKanbanFilled",
  "layout-list": "IconLayoutList",
  "layout-list-filled": "IconLayoutListFilled",
  "layout-navbar": "IconLayoutNavbar",
  "layout-navbar-collapse": "IconLayoutNavbarCollapse",
  "layout-navbar-collapse-filled": "IconLayoutNavbarCollapseFilled",
  "layout-navbar-expand": "IconLayoutNavbarExpand",
  "layout-navbar-expand-filled": "IconLayoutNavbarExpandFilled",
  "layout-navbar-filled": "IconLayoutNavbarFilled",
  "layout-navbar-inactive": "IconLayoutNavbarInactive",
  "layout-off": "IconLayoutOff",
  "layout-rows": "IconLayoutRows",
  "layout-sidebar": "IconLayoutSidebar",
  "layout-sidebar-filled": "IconLayoutSidebarFilled",
  "layout-sidebar-inactive": "IconLayoutSidebarInactive",
  "layout-sidebar-left-collapse": "IconLayoutSidebarLeftCollapse",
  "layout-sidebar-left-collapse-filled": "IconLayoutSidebarLeftCollapseFilled",
  "layout-sidebar-left-expand": "IconLayoutSidebarLeftExpand",
  "layout-sidebar-left-expand-filled": "IconLayoutSidebarLeftExpandFilled",
  "layout-sidebar-right": "IconLayoutSidebarRight",
  "layout-sidebar-right-collapse": "IconLayoutSidebarRightCollapse",
  "layout-sidebar-right-collapse-filled":
    "IconLayoutSidebarRightCollapseFilled",
  "layout-sidebar-right-expand": "IconLayoutSidebarRightExpand",
  "layout-sidebar-right-expand-filled": "IconLayoutSidebarRightExpandFilled",
  "layout-sidebar-right-filled": "IconLayoutSidebarRightFilled",
  "layout-sidebar-right-inactive": "IconLayoutSidebarRightInactive",
  leaf: "IconLeaf",
  "leaf-2": "IconLeaf2",
  "leaf-off": "IconLeafOff",
  lego: "IconLego",
  "lego-filled": "IconLegoFilled",
  "lego-off": "IconLegoOff",
  lemon: "IconLemon",
  "lemon-2": "IconLemon2",
  "letter-a": "IconLetterA",
  "letter-a-small": "IconLetterASmall",
  "letter-b": "IconLetterB",
  "letter-b-small": "IconLetterBSmall",
  "letter-c": "IconLetterC",
  "letter-c-small": "IconLetterCSmall",
  "letter-case": "IconLetterCase",
  "letter-case-lower": "IconLetterCaseLower",
  "letter-case-toggle": "IconLetterCaseToggle",
  "letter-case-upper": "IconLetterCaseUpper",
  "letter-d": "IconLetterD",
  "letter-d-small": "IconLetterDSmall",
  "letter-e": "IconLetterE",
  "letter-e-small": "IconLetterESmall",
  "letter-f": "IconLetterF",
  "letter-f-small": "IconLetterFSmall",
  "letter-g": "IconLetterG",
  "letter-g-small": "IconLetterGSmall",
  "letter-h": "IconLetterH",
  "letter-h-small": "IconLetterHSmall",
  "letter-i": "IconLetterI",
  "letter-i-small": "IconLetterISmall",
  "letter-j": "IconLetterJ",
  "letter-j-small": "IconLetterJSmall",
  "letter-k": "IconLetterK",
  "letter-k-small": "IconLetterKSmall",
  "letter-l": "IconLetterL",
  "letter-l-small": "IconLetterLSmall",
  "letter-m": "IconLetterM",
  "letter-m-small": "IconLetterMSmall",
  "letter-n": "IconLetterN",
  "letter-n-small": "IconLetterNSmall",
  "letter-o": "IconLetterO",
  "letter-o-small": "IconLetterOSmall",
  "letter-p": "IconLetterP",
  "letter-p-small": "IconLetterPSmall",
  "letter-q": "IconLetterQ",
  "letter-q-small": "IconLetterQSmall",
  "letter-r": "IconLetterR",
  "letter-r-small": "IconLetterRSmall",
  "letter-s": "IconLetterS",
  "letter-s-small": "IconLetterSSmall",
  "letter-spacing": "IconLetterSpacing",
  "letter-t": "IconLetterT",
  "letter-t-small": "IconLetterTSmall",
  "letter-u": "IconLetterU",
  "letter-u-small": "IconLetterUSmall",
  "letter-v": "IconLetterV",
  "letter-v-small": "IconLetterVSmall",
  "letter-w": "IconLetterW",
  "letter-w-small": "IconLetterWSmall",
  "letter-x": "IconLetterX",
  "letter-x-small": "IconLetterXSmall",
  "letter-y": "IconLetterY",
  "letter-y-small": "IconLetterYSmall",
  "letter-z": "IconLetterZ",
  "letter-z-small": "IconLetterZSmall",
  library: "IconLibrary",
  "library-minus": "IconLibraryMinus",
  "library-photo": "IconLibraryPhoto",
  "library-plus": "IconLibraryPlus",
  license: "IconLicense",
  "license-off": "IconLicenseOff",
  lifebuoy: "IconLifebuoy",
  "lifebuoy-off": "IconLifebuoyOff",
  lighter: "IconLighter",
  line: "IconLine",
  "line-dashed": "IconLineDashed",
  "line-dotted": "IconLineDotted",
  "line-height": "IconLineHeight",
  "line-scan": "IconLineScan",
  link: "IconLink",
  "link-minus": "IconLinkMinus",
  "link-off": "IconLinkOff",
  "link-plus": "IconLinkPlus",
  list: "IconList",
  "list-check": "IconListCheck",
  "list-details": "IconListDetails",
  "list-letters": "IconListLetters",
  "list-numbers": "IconListNumbers",
  "list-search": "IconListSearch",
  "list-tree": "IconListTree",
  "live-photo": "IconLivePhoto",
  "live-photo-filled": "IconLivePhotoFilled",
  "live-photo-off": "IconLivePhotoOff",
  "live-view": "IconLiveView",
  "load-balancer": "IconLoadBalancer",
  loader: "IconLoader",
  "loader-2": "IconLoader2",
  "loader-3": "IconLoader3",
  "loader-quarter": "IconLoaderQuarter",
  location: "IconLocation",
  "location-bolt": "IconLocationBolt",
  "location-broken": "IconLocationBroken",
  "location-cancel": "IconLocationCancel",
  "location-check": "IconLocationCheck",
  "location-code": "IconLocationCode",
  "location-cog": "IconLocationCog",
  "location-discount": "IconLocationDiscount",
  "location-dollar": "IconLocationDollar",
  "location-down": "IconLocationDown",
  "location-exclamation": "IconLocationExclamation",
  "location-filled": "IconLocationFilled",
  "location-heart": "IconLocationHeart",
  "location-minus": "IconLocationMinus",
  "location-off": "IconLocationOff",
  "location-pause": "IconLocationPause",
  "location-pin": "IconLocationPin",
  "location-plus": "IconLocationPlus",
  "location-question": "IconLocationQuestion",
  "location-search": "IconLocationSearch",
  "location-share": "IconLocationShare",
  "location-star": "IconLocationStar",
  "location-up": "IconLocationUp",
  "location-x": "IconLocationX",
  lock: "IconLock",
  "lock-access": "IconLockAccess",
  "lock-access-off": "IconLockAccessOff",
  "lock-bitcoin": "IconLockBitcoin",
  "lock-bolt": "IconLockBolt",
  "lock-cancel": "IconLockCancel",
  "lock-check": "IconLockCheck",
  "lock-code": "IconLockCode",
  "lock-cog": "IconLockCog",
  "lock-dollar": "IconLockDollar",
  "lock-down": "IconLockDown",
  "lock-exclamation": "IconLockExclamation",
  "lock-filled": "IconLockFilled",
  "lock-heart": "IconLockHeart",
  "lock-minus": "IconLockMinus",
  "lock-off": "IconLockOff",
  "lock-open": "IconLockOpen",
  "lock-open-2": "IconLockOpen2",
  "lock-open-off": "IconLockOpenOff",
  "lock-pause": "IconLockPause",
  "lock-pin": "IconLockPin",
  "lock-plus": "IconLockPlus",
  "lock-question": "IconLockQuestion",
  "lock-search": "IconLockSearch",
  "lock-share": "IconLockShare",
  "lock-square": "IconLockSquare",
  "lock-square-rounded": "IconLockSquareRounded",
  "lock-square-rounded-filled": "IconLockSquareRoundedFilled",
  "lock-star": "IconLockStar",
  "lock-up": "IconLockUp",
  "lock-x": "IconLockX",
  "logic-and": "IconLogicAnd",
  "logic-buffer": "IconLogicBuffer",
  "logic-nand": "IconLogicNand",
  "logic-nor": "IconLogicNor",
  "logic-not": "IconLogicNot",
  "logic-or": "IconLogicOr",
  "logic-xnor": "IconLogicXnor",
  "logic-xor": "IconLogicXor",
  login: "IconLogin",
  "login-2": "IconLogin2",
  logout: "IconLogout",
  "logout-2": "IconLogout2",
  logs: "IconLogs",
  lollipop: "IconLollipop",
  "lollipop-off": "IconLollipopOff",
  luggage: "IconLuggage",
  "luggage-off": "IconLuggageOff",
  lungs: "IconLungs",
  "lungs-filled": "IconLungsFilled",
  "lungs-off": "IconLungsOff",
  macro: "IconMacro",
  "macro-filled": "IconMacroFilled",
  "macro-off": "IconMacroOff",
  magnet: "IconMagnet",
  "magnet-filled": "IconMagnetFilled",
  "magnet-off": "IconMagnetOff",
  magnetic: "IconMagnetic",
  mail: "IconMail",
  "mail-ai": "IconMailAi",
  "mail-bitcoin": "IconMailBitcoin",
  "mail-bolt": "IconMailBolt",
  "mail-cancel": "IconMailCancel",
  "mail-check": "IconMailCheck",
  "mail-code": "IconMailCode",
  "mail-cog": "IconMailCog",
  "mail-dollar": "IconMailDollar",
  "mail-down": "IconMailDown",
  "mail-exclamation": "IconMailExclamation",
  "mail-fast": "IconMailFast",
  "mail-filled": "IconMailFilled",
  "mail-forward": "IconMailForward",
  "mail-heart": "IconMailHeart",
  "mail-minus": "IconMailMinus",
  "mail-off": "IconMailOff",
  "mail-opened": "IconMailOpened",
  "mail-opened-filled": "IconMailOpenedFilled",
  "mail-pause": "IconMailPause",
  "mail-pin": "IconMailPin",
  "mail-plus": "IconMailPlus",
  "mail-question": "IconMailQuestion",
  "mail-search": "IconMailSearch",
  "mail-share": "IconMailShare",
  "mail-star": "IconMailStar",
  "mail-up": "IconMailUp",
  "mail-x": "IconMailX",
  mailbox: "IconMailbox",
  "mailbox-off": "IconMailboxOff",
  man: "IconMan",
  "man-filled": "IconManFilled",
  "manual-gearbox": "IconManualGearbox",
  "manual-gearbox-filled": "IconManualGearboxFilled",
  map: "IconMap",
  "map-2": "IconMap2",
  "map-bolt": "IconMapBolt",
  "map-cancel": "IconMapCancel",
  "map-check": "IconMapCheck",
  "map-code": "IconMapCode",
  "map-cog": "IconMapCog",
  "map-discount": "IconMapDiscount",
  "map-dollar": "IconMapDollar",
  "map-down": "IconMapDown",
  "map-east": "IconMapEast",
  "map-exclamation": "IconMapExclamation",
  "map-heart": "IconMapHeart",
  "map-minus": "IconMapMinus",
  "map-north": "IconMapNorth",
  "map-off": "IconMapOff",
  "map-pause": "IconMapPause",
  "map-pin": "IconMapPin",
  "map-pin-2": "IconMapPin2",
  "map-pin-bolt": "IconMapPinBolt",
  "map-pin-cancel": "IconMapPinCancel",
  "map-pin-check": "IconMapPinCheck",
  "map-pin-code": "IconMapPinCode",
  "map-pin-cog": "IconMapPinCog",
  "map-pin-dollar": "IconMapPinDollar",
  "map-pin-down": "IconMapPinDown",
  "map-pin-exclamation": "IconMapPinExclamation",
  "map-pin-filled": "IconMapPinFilled",
  "map-pin-heart": "IconMapPinHeart",
  "map-pin-minus": "IconMapPinMinus",
  "map-pin-off": "IconMapPinOff",
  "map-pin-pause": "IconMapPinPause",
  "map-pin-pin": "IconMapPinPin",
  "map-pin-plus": "IconMapPinPlus",
  "map-pin-question": "IconMapPinQuestion",
  "map-pin-search": "IconMapPinSearch",
  "map-pin-share": "IconMapPinShare",
  "map-pin-star": "IconMapPinStar",
  "map-pin-up": "IconMapPinUp",
  "map-pin-x": "IconMapPinX",
  "map-pins": "IconMapPins",
  "map-plus": "IconMapPlus",
  "map-question": "IconMapQuestion",
  "map-route": "IconMapRoute",
  "map-search": "IconMapSearch",
  "map-share": "IconMapShare",
  "map-south": "IconMapSouth",
  "map-star": "IconMapStar",
  "map-up": "IconMapUp",
  "map-west": "IconMapWest",
  "map-x": "IconMapX",
  markdown: "IconMarkdown",
  "markdown-off": "IconMarkdownOff",
  marquee: "IconMarquee",
  "marquee-2": "IconMarquee2",
  "marquee-off": "IconMarqueeOff",
  mars: "IconMars",
  mask: "IconMask",
  "mask-off": "IconMaskOff",
  "masks-theater": "IconMasksTheater",
  "masks-theater-off": "IconMasksTheaterOff",
  massage: "IconMassage",
  matchstick: "IconMatchstick",
  math: "IconMath",
  "math-1-divide-2": "IconMath1Divide2",
  "math-1-divide-3": "IconMath1Divide3",
  "math-avg": "IconMathAvg",
  "math-cos": "IconMathCos",
  "math-ctg": "IconMathCtg",
  "math-equal-greater": "IconMathEqualGreater",
  "math-equal-lower": "IconMathEqualLower",
  "math-function": "IconMathFunction",
  "math-function-off": "IconMathFunctionOff",
  "math-function-y": "IconMathFunctionY",
  "math-greater": "IconMathGreater",
  "math-integral": "IconMathIntegral",
  "math-integral-x": "IconMathIntegralX",
  "math-integrals": "IconMathIntegrals",
  "math-lower": "IconMathLower",
  "math-max": "IconMathMax",
  "math-max-min": "IconMathMaxMin",
  "math-min": "IconMathMin",
  "math-not": "IconMathNot",
  "math-off": "IconMathOff",
  "math-pi": "IconMathPi",
  "math-pi-divide-2": "IconMathPiDivide2",
  "math-sec": "IconMathSec",
  "math-sin": "IconMathSin",
  "math-symbols": "IconMathSymbols",
  "math-tg": "IconMathTg",
  "math-x-divide-2": "IconMathXDivide2",
  "math-x-divide-y": "IconMathXDivideY",
  "math-x-divide-y-2": "IconMathXDivideY2",
  "math-x-minus-x": "IconMathXMinusX",
  "math-x-minus-y": "IconMathXMinusY",
  "math-x-plus-x": "IconMathXPlusX",
  "math-x-plus-y": "IconMathXPlusY",
  "math-xy": "IconMathXy",
  "math-y-minus-y": "IconMathYMinusY",
  "math-y-plus-y": "IconMathYPlusY",
  maximize: "IconMaximize",
  "maximize-off": "IconMaximizeOff",
  meat: "IconMeat",
  "meat-off": "IconMeatOff",
  medal: "IconMedal",
  "medal-2": "IconMedal2",
  "medical-cross": "IconMedicalCross",
  "medical-cross-circle": "IconMedicalCrossCircle",
  "medical-cross-filled": "IconMedicalCrossFilled",
  "medical-cross-off": "IconMedicalCrossOff",
  "medicine-syrup": "IconMedicineSyrup",
  meeple: "IconMeeple",
  melon: "IconMelon",
  menorah: "IconMenorah",
  menu: "IconMenu",
  "menu-2": "IconMenu2",
  "menu-3": "IconMenu3",
  "menu-4": "IconMenu4",
  "menu-deep": "IconMenuDeep",
  "menu-order": "IconMenuOrder",
  message: "IconMessage",
  "message-2": "IconMessage2",
  "message-2-bolt": "IconMessage2Bolt",
  "message-2-cancel": "IconMessage2Cancel",
  "message-2-check": "IconMessage2Check",
  "message-2-code": "IconMessage2Code",
  "message-2-cog": "IconMessage2Cog",
  "message-2-dollar": "IconMessage2Dollar",
  "message-2-down": "IconMessage2Down",
  "message-2-exclamation": "IconMessage2Exclamation",
  "message-2-heart": "IconMessage2Heart",
  "message-2-minus": "IconMessage2Minus",
  "message-2-off": "IconMessage2Off",
  "message-2-pause": "IconMessage2Pause",
  "message-2-pin": "IconMessage2Pin",
  "message-2-plus": "IconMessage2Plus",
  "message-2-question": "IconMessage2Question",
  "message-2-search": "IconMessage2Search",
  "message-2-share": "IconMessage2Share",
  "message-2-star": "IconMessage2Star",
  "message-2-up": "IconMessage2Up",
  "message-2-x": "IconMessage2X",
  "message-bolt": "IconMessageBolt",
  "message-cancel": "IconMessageCancel",
  "message-chatbot": "IconMessageChatbot",
  "message-chatbot-filled": "IconMessageChatbotFilled",
  "message-check": "IconMessageCheck",
  "message-circle": "IconMessageCircle",
  "message-circle-bolt": "IconMessageCircleBolt",
  "message-circle-cancel": "IconMessageCircleCancel",
  "message-circle-check": "IconMessageCircleCheck",
  "message-circle-code": "IconMessageCircleCode",
  "message-circle-cog": "IconMessageCircleCog",
  "message-circle-dollar": "IconMessageCircleDollar",
  "message-circle-down": "IconMessageCircleDown",
  "message-circle-exclamation": "IconMessageCircleExclamation",
  "message-circle-filled": "IconMessageCircleFilled",
  "message-circle-heart": "IconMessageCircleHeart",
  "message-circle-minus": "IconMessageCircleMinus",
  "message-circle-off": "IconMessageCircleOff",
  "message-circle-pause": "IconMessageCirclePause",
  "message-circle-pin": "IconMessageCirclePin",
  "message-circle-plus": "IconMessageCirclePlus",
  "message-circle-question": "IconMessageCircleQuestion",
  "message-circle-search": "IconMessageCircleSearch",
  "message-circle-share": "IconMessageCircleShare",
  "message-circle-star": "IconMessageCircleStar",
  "message-circle-up": "IconMessageCircleUp",
  "message-circle-user": "IconMessageCircleUser",
  "message-circle-x": "IconMessageCircleX",
  "message-code": "IconMessageCode",
  "message-cog": "IconMessageCog",
  "message-dollar": "IconMessageDollar",
  "message-dots": "IconMessageDots",
  "message-down": "IconMessageDown",
  "message-exclamation": "IconMessageExclamation",
  "message-filled": "IconMessageFilled",
  "message-forward": "IconMessageForward",
  "message-heart": "IconMessageHeart",
  "message-language": "IconMessageLanguage",
  "message-minus": "IconMessageMinus",
  "message-off": "IconMessageOff",
  "message-pause": "IconMessagePause",
  "message-pin": "IconMessagePin",
  "message-plus": "IconMessagePlus",
  "message-question": "IconMessageQuestion",
  "message-reply": "IconMessageReply",
  "message-report": "IconMessageReport",
  "message-report-filled": "IconMessageReportFilled",
  "message-search": "IconMessageSearch",
  "message-share": "IconMessageShare",
  "message-star": "IconMessageStar",
  "message-up": "IconMessageUp",
  "message-user": "IconMessageUser",
  "message-x": "IconMessageX",
  messages: "IconMessages",
  "messages-off": "IconMessagesOff",
  meteor: "IconMeteor",
  "meteor-off": "IconMeteorOff",
  "meter-cube": "IconMeterCube",
  "meter-square": "IconMeterSquare",
  metronome: "IconMetronome",
  "michelin-bib-gourmand": "IconMichelinBibGourmand",
  "michelin-star": "IconMichelinStar",
  "michelin-star-green": "IconMichelinStarGreen",
  mickey: "IconMickey",
  "mickey-filled": "IconMickeyFilled",
  microphone: "IconMicrophone",
  "microphone-2": "IconMicrophone2",
  "microphone-2-off": "IconMicrophone2Off",
  "microphone-filled": "IconMicrophoneFilled",
  "microphone-off": "IconMicrophoneOff",
  microscope: "IconMicroscope",
  "microscope-off": "IconMicroscopeOff",
  microwave: "IconMicrowave",
  "microwave-filled": "IconMicrowaveFilled",
  "microwave-off": "IconMicrowaveOff",
  "military-award": "IconMilitaryAward",
  "military-rank": "IconMilitaryRank",
  "military-rank-filled": "IconMilitaryRankFilled",
  milk: "IconMilk",
  "milk-off": "IconMilkOff",
  milkshake: "IconMilkshake",
  minimize: "IconMinimize",
  minus: "IconMinus",
  "minus-vertical": "IconMinusVertical",
  mist: "IconMist",
  "mist-off": "IconMistOff",
  mobiledata: "IconMobiledata",
  "mobiledata-off": "IconMobiledataOff",
  moneybag: "IconMoneybag",
  monkeybar: "IconMonkeybar",
  "mood-angry": "IconMoodAngry",
  "mood-angry-filled": "IconMoodAngryFilled",
  "mood-annoyed": "IconMoodAnnoyed",
  "mood-annoyed-2": "IconMoodAnnoyed2",
  "mood-bitcoin": "IconMoodBitcoin",
  "mood-boy": "IconMoodBoy",
  "mood-check": "IconMoodCheck",
  "mood-cog": "IconMoodCog",
  "mood-confuzed": "IconMoodConfuzed",
  "mood-confuzed-filled": "IconMoodConfuzedFilled",
  "mood-crazy-happy": "IconMoodCrazyHappy",
  "mood-crazy-happy-filled": "IconMoodCrazyHappyFilled",
  "mood-cry": "IconMoodCry",
  "mood-dollar": "IconMoodDollar",
  "mood-edit": "IconMoodEdit",
  "mood-empty": "IconMoodEmpty",
  "mood-empty-filled": "IconMoodEmptyFilled",
  "mood-happy": "IconMoodHappy",
  "mood-happy-filled": "IconMoodHappyFilled",
  "mood-heart": "IconMoodHeart",
  "mood-kid": "IconMoodKid",
  "mood-kid-filled": "IconMoodKidFilled",
  "mood-look-down": "IconMoodLookDown",
  "mood-look-left": "IconMoodLookLeft",
  "mood-look-right": "IconMoodLookRight",
  "mood-look-up": "IconMoodLookUp",
  "mood-minus": "IconMoodMinus",
  "mood-nerd": "IconMoodNerd",
  "mood-nervous": "IconMoodNervous",
  "mood-neutral": "IconMoodNeutral",
  "mood-neutral-filled": "IconMoodNeutralFilled",
  "mood-off": "IconMoodOff",
  "mood-pin": "IconMoodPin",
  "mood-plus": "IconMoodPlus",
  "mood-puzzled": "IconMoodPuzzled",
  "mood-sad": "IconMoodSad",
  "mood-sad-2": "IconMoodSad2",
  "mood-sad-dizzy": "IconMoodSadDizzy",
  "mood-sad-filled": "IconMoodSadFilled",
  "mood-sad-squint": "IconMoodSadSquint",
  "mood-search": "IconMoodSearch",
  "mood-share": "IconMoodShare",
  "mood-sick": "IconMoodSick",
  "mood-silence": "IconMoodSilence",
  "mood-sing": "IconMoodSing",
  "mood-smile": "IconMoodSmile",
  "mood-smile-beam": "IconMoodSmileBeam",
  "mood-smile-dizzy": "IconMoodSmileDizzy",
  "mood-smile-filled": "IconMoodSmileFilled",
  "mood-surprised": "IconMoodSurprised",
  "mood-tongue": "IconMoodTongue",
  "mood-tongue-wink": "IconMoodTongueWink",
  "mood-tongue-wink-2": "IconMoodTongueWink2",
  "mood-unamused": "IconMoodUnamused",
  "mood-up": "IconMoodUp",
  "mood-wink": "IconMoodWink",
  "mood-wink-2": "IconMoodWink2",
  "mood-wrrr": "IconMoodWrrr",
  "mood-wrrr-filled": "IconMoodWrrrFilled",
  "mood-x": "IconMoodX",
  "mood-xd": "IconMoodXd",
  moon: "IconMoon",
  "moon-2": "IconMoon2",
  "moon-filled": "IconMoonFilled",
  "moon-off": "IconMoonOff",
  "moon-stars": "IconMoonStars",
  moped: "IconMoped",
  motorbike: "IconMotorbike",
  mountain: "IconMountain",
  "mountain-off": "IconMountainOff",
  mouse: "IconMouse",
  "mouse-2": "IconMouse2",
  "mouse-filled": "IconMouseFilled",
  "mouse-off": "IconMouseOff",
  moustache: "IconMoustache",
  movie: "IconMovie",
  "movie-off": "IconMovieOff",
  mug: "IconMug",
  "mug-off": "IconMugOff",
  "multiplier-05-x": "IconMultiplier05x",
  "multiplier-15-x": "IconMultiplier15x",
  "multiplier-1-x": "IconMultiplier1x",
  "multiplier-2-x": "IconMultiplier2x",
  mushroom: "IconMushroom",
  "mushroom-filled": "IconMushroomFilled",
  "mushroom-off": "IconMushroomOff",
  music: "IconMusic",
  "music-bolt": "IconMusicBolt",
  "music-cancel": "IconMusicCancel",
  "music-check": "IconMusicCheck",
  "music-code": "IconMusicCode",
  "music-cog": "IconMusicCog",
  "music-discount": "IconMusicDiscount",
  "music-dollar": "IconMusicDollar",
  "music-down": "IconMusicDown",
  "music-exclamation": "IconMusicExclamation",
  "music-heart": "IconMusicHeart",
  "music-minus": "IconMusicMinus",
  "music-off": "IconMusicOff",
  "music-pause": "IconMusicPause",
  "music-pin": "IconMusicPin",
  "music-plus": "IconMusicPlus",
  "music-question": "IconMusicQuestion",
  "music-search": "IconMusicSearch",
  "music-share": "IconMusicShare",
  "music-star": "IconMusicStar",
  "music-up": "IconMusicUp",
  "music-x": "IconMusicX",
  navigation: "IconNavigation",
  "navigation-bolt": "IconNavigationBolt",
  "navigation-cancel": "IconNavigationCancel",
  "navigation-check": "IconNavigationCheck",
  "navigation-code": "IconNavigationCode",
  "navigation-cog": "IconNavigationCog",
  "navigation-discount": "IconNavigationDiscount",
  "navigation-dollar": "IconNavigationDollar",
  "navigation-down": "IconNavigationDown",
  "navigation-east": "IconNavigationEast",
  "navigation-exclamation": "IconNavigationExclamation",
  "navigation-filled": "IconNavigationFilled",
  "navigation-heart": "IconNavigationHeart",
  "navigation-minus": "IconNavigationMinus",
  "navigation-north": "IconNavigationNorth",
  "navigation-off": "IconNavigationOff",
  "navigation-pause": "IconNavigationPause",
  "navigation-pin": "IconNavigationPin",
  "navigation-plus": "IconNavigationPlus",
  "navigation-question": "IconNavigationQuestion",
  "navigation-search": "IconNavigationSearch",
  "navigation-share": "IconNavigationShare",
  "navigation-south": "IconNavigationSouth",
  "navigation-star": "IconNavigationStar",
  "navigation-top": "IconNavigationTop",
  "navigation-up": "IconNavigationUp",
  "navigation-west": "IconNavigationWest",
  "navigation-x": "IconNavigationX",
  needle: "IconNeedle",
  "needle-thread": "IconNeedleThread",
  network: "IconNetwork",
  "network-off": "IconNetworkOff",
  "new-section": "IconNewSection",
  news: "IconNews",
  "news-off": "IconNewsOff",
  nfc: "IconNfc",
  "nfc-off": "IconNfcOff",
  "no-copyright": "IconNoCopyright",
  "no-creative-commons": "IconNoCreativeCommons",
  "no-derivatives": "IconNoDerivatives",
  "north-star": "IconNorthStar",
  note: "IconNote",
  "note-off": "IconNoteOff",
  notebook: "IconNotebook",
  "notebook-off": "IconNotebookOff",
  notes: "IconNotes",
  "notes-off": "IconNotesOff",
  notification: "IconNotification",
  "notification-off": "IconNotificationOff",
  number: "IconNumber",
  "number-0": "IconNumber0",
  "number-0-small": "IconNumber0Small",
  "number-1": "IconNumber1",
  "number-10-small": "IconNumber10Small",
  "number-11-small": "IconNumber11Small",
  "number-123": "IconNumber123",
  "number-12-small": "IconNumber12Small",
  "number-13-small": "IconNumber13Small",
  "number-14-small": "IconNumber14Small",
  "number-15-small": "IconNumber15Small",
  "number-16-small": "IconNumber16Small",
  "number-17-small": "IconNumber17Small",
  "number-18-small": "IconNumber18Small",
  "number-19-small": "IconNumber19Small",
  "number-1-small": "IconNumber1Small",
  "number-2": "IconNumber2",
  "number-20-small": "IconNumber20Small",
  "number-21-small": "IconNumber21Small",
  "number-22-small": "IconNumber22Small",
  "number-23-small": "IconNumber23Small",
  "number-24-small": "IconNumber24Small",
  "number-25-small": "IconNumber25Small",
  "number-26-small": "IconNumber26Small",
  "number-27-small": "IconNumber27Small",
  "number-28-small": "IconNumber28Small",
  "number-29-small": "IconNumber29Small",
  "number-2-small": "IconNumber2Small",
  "number-3": "IconNumber3",
  "number-3-small": "IconNumber3Small",
  "number-4": "IconNumber4",
  "number-4-small": "IconNumber4Small",
  "number-5": "IconNumber5",
  "number-5-small": "IconNumber5Small",
  "number-6": "IconNumber6",
  "number-6-small": "IconNumber6Small",
  "number-7": "IconNumber7",
  "number-7-small": "IconNumber7Small",
  "number-8": "IconNumber8",
  "number-8-small": "IconNumber8Small",
  "number-9": "IconNumber9",
  "number-9-small": "IconNumber9Small",
  numbers: "IconNumbers",
  nurse: "IconNurse",
  nut: "IconNut",
  "object-scan": "IconObjectScan",
  octagon: "IconOctagon",
  "octagon-filled": "IconOctagonFilled",
  "octagon-minus": "IconOctagonMinus",
  "octagon-minus-2": "IconOctagonMinus2",
  "octagon-off": "IconOctagonOff",
  "octagon-plus": "IconOctagonPlus",
  "octagon-plus-2": "IconOctagonPlus2",
  octahedron: "IconOctahedron",
  "octahedron-off": "IconOctahedronOff",
  "octahedron-plus": "IconOctahedronPlus",
  old: "IconOld",
  olympics: "IconOlympics",
  "olympics-off": "IconOlympicsOff",
  om: "IconOm",
  omega: "IconOmega",
  outbound: "IconOutbound",
  outlet: "IconOutlet",
  oval: "IconOval",
  "oval-filled": "IconOvalFilled",
  "oval-vertical": "IconOvalVertical",
  "oval-vertical-filled": "IconOvalVerticalFilled",
  overline: "IconOverline",
  package: "IconPackage",
  "package-export": "IconPackageExport",
  "package-import": "IconPackageImport",
  "package-off": "IconPackageOff",
  packages: "IconPackages",
  pacman: "IconPacman",
  "page-break": "IconPageBreak",
  paint: "IconPaint",
  "paint-filled": "IconPaintFilled",
  "paint-off": "IconPaintOff",
  palette: "IconPalette",
  "palette-off": "IconPaletteOff",
  "panorama-horizontal": "IconPanoramaHorizontal",
  "panorama-horizontal-filled": "IconPanoramaHorizontalFilled",
  "panorama-horizontal-off": "IconPanoramaHorizontalOff",
  "panorama-vertical": "IconPanoramaVertical",
  "panorama-vertical-filled": "IconPanoramaVerticalFilled",
  "panorama-vertical-off": "IconPanoramaVerticalOff",
  "paper-bag": "IconPaperBag",
  "paper-bag-off": "IconPaperBagOff",
  paperclip: "IconPaperclip",
  parachute: "IconParachute",
  "parachute-off": "IconParachuteOff",
  parentheses: "IconParentheses",
  "parentheses-off": "IconParenthesesOff",
  parking: "IconParking",
  "parking-circle": "IconParkingCircle",
  "parking-circle-filled": "IconParkingCircleFilled",
  "parking-off": "IconParkingOff",
  password: "IconPassword",
  "password-fingerprint": "IconPasswordFingerprint",
  "password-mobile-phone": "IconPasswordMobilePhone",
  "password-user": "IconPasswordUser",
  paw: "IconPaw",
  "paw-filled": "IconPawFilled",
  "paw-off": "IconPawOff",
  paywall: "IconPaywall",
  pdf: "IconPdf",
  peace: "IconPeace",
  pencil: "IconPencil",
  "pencil-bolt": "IconPencilBolt",
  "pencil-cancel": "IconPencilCancel",
  "pencil-check": "IconPencilCheck",
  "pencil-code": "IconPencilCode",
  "pencil-cog": "IconPencilCog",
  "pencil-discount": "IconPencilDiscount",
  "pencil-dollar": "IconPencilDollar",
  "pencil-down": "IconPencilDown",
  "pencil-exclamation": "IconPencilExclamation",
  "pencil-heart": "IconPencilHeart",
  "pencil-minus": "IconPencilMinus",
  "pencil-off": "IconPencilOff",
  "pencil-pause": "IconPencilPause",
  "pencil-pin": "IconPencilPin",
  "pencil-plus": "IconPencilPlus",
  "pencil-question": "IconPencilQuestion",
  "pencil-search": "IconPencilSearch",
  "pencil-share": "IconPencilShare",
  "pencil-star": "IconPencilStar",
  "pencil-up": "IconPencilUp",
  "pencil-x": "IconPencilX",
  pennant: "IconPennant",
  "pennant-2": "IconPennant2",
  "pennant-2-filled": "IconPennant2Filled",
  "pennant-filled": "IconPennantFilled",
  "pennant-off": "IconPennantOff",
  pentagon: "IconPentagon",
  "pentagon-filled": "IconPentagonFilled",
  "pentagon-minus": "IconPentagonMinus",
  "pentagon-number-0": "IconPentagonNumber0",
  "pentagon-number-1": "IconPentagonNumber1",
  "pentagon-number-2": "IconPentagonNumber2",
  "pentagon-number-3": "IconPentagonNumber3",
  "pentagon-number-4": "IconPentagonNumber4",
  "pentagon-number-5": "IconPentagonNumber5",
  "pentagon-number-6": "IconPentagonNumber6",
  "pentagon-number-7": "IconPentagonNumber7",
  "pentagon-number-8": "IconPentagonNumber8",
  "pentagon-number-9": "IconPentagonNumber9",
  "pentagon-off": "IconPentagonOff",
  "pentagon-plus": "IconPentagonPlus",
  "pentagon-x": "IconPentagonX",
  pentagram: "IconPentagram",
  pepper: "IconPepper",
  "pepper-off": "IconPepperOff",
  percentage: "IconPercentage",
  "percentage-0": "IconPercentage0",
  "percentage-10": "IconPercentage10",
  "percentage-100": "IconPercentage100",
  "percentage-20": "IconPercentage20",
  "percentage-25": "IconPercentage25",
  "percentage-30": "IconPercentage30",
  "percentage-33": "IconPercentage33",
  "percentage-40": "IconPercentage40",
  "percentage-50": "IconPercentage50",
  "percentage-60": "IconPercentage60",
  "percentage-66": "IconPercentage66",
  "percentage-70": "IconPercentage70",
  "percentage-75": "IconPercentage75",
  "percentage-80": "IconPercentage80",
  "percentage-90": "IconPercentage90",
  perfume: "IconPerfume",
  perspective: "IconPerspective",
  "perspective-off": "IconPerspectiveOff",
  phone: "IconPhone",
  "phone-call": "IconPhoneCall",
  "phone-calling": "IconPhoneCalling",
  "phone-check": "IconPhoneCheck",
  "phone-filled": "IconPhoneFilled",
  "phone-incoming": "IconPhoneIncoming",
  "phone-off": "IconPhoneOff",
  "phone-outgoing": "IconPhoneOutgoing",
  "phone-pause": "IconPhonePause",
  "phone-plus": "IconPhonePlus",
  "phone-x": "IconPhoneX",
  photo: "IconPhoto",
  "photo-ai": "IconPhotoAi",
  "photo-bitcoin": "IconPhotoBitcoin",
  "photo-bolt": "IconPhotoBolt",
  "photo-cancel": "IconPhotoCancel",
  "photo-check": "IconPhotoCheck",
  "photo-circle": "IconPhotoCircle",
  "photo-circle-minus": "IconPhotoCircleMinus",
  "photo-circle-plus": "IconPhotoCirclePlus",
  "photo-code": "IconPhotoCode",
  "photo-cog": "IconPhotoCog",
  "photo-dollar": "IconPhotoDollar",
  "photo-down": "IconPhotoDown",
  "photo-edit": "IconPhotoEdit",
  "photo-exclamation": "IconPhotoExclamation",
  "photo-filled": "IconPhotoFilled",
  "photo-heart": "IconPhotoHeart",
  "photo-hexagon": "IconPhotoHexagon",
  "photo-minus": "IconPhotoMinus",
  "photo-off": "IconPhotoOff",
  "photo-pause": "IconPhotoPause",
  "photo-pentagon": "IconPhotoPentagon",
  "photo-pin": "IconPhotoPin",
  "photo-plus": "IconPhotoPlus",
  "photo-question": "IconPhotoQuestion",
  "photo-scan": "IconPhotoScan",
  "photo-search": "IconPhotoSearch",
  "photo-sensor": "IconPhotoSensor",
  "photo-sensor-2": "IconPhotoSensor2",
  "photo-sensor-3": "IconPhotoSensor3",
  "photo-share": "IconPhotoShare",
  "photo-shield": "IconPhotoShield",
  "photo-square-rounded": "IconPhotoSquareRounded",
  "photo-star": "IconPhotoStar",
  "photo-up": "IconPhotoUp",
  "photo-video": "IconPhotoVideo",
  "photo-x": "IconPhotoX",
  physotherapist: "IconPhysotherapist",
  piano: "IconPiano",
  pick: "IconPick",
  "picnic-table": "IconPicnicTable",
  "picture-in-picture": "IconPictureInPicture",
  "picture-in-picture-filled": "IconPictureInPictureFilled",
  "picture-in-picture-off": "IconPictureInPictureOff",
  "picture-in-picture-on": "IconPictureInPictureOn",
  "picture-in-picture-top": "IconPictureInPictureTop",
  "picture-in-picture-top-filled": "IconPictureInPictureTopFilled",
  pig: "IconPig",
  "pig-money": "IconPigMoney",
  "pig-off": "IconPigOff",
  pilcrow: "IconPilcrow",
  "pilcrow-left": "IconPilcrowLeft",
  "pilcrow-right": "IconPilcrowRight",
  pill: "IconPill",
  "pill-filled": "IconPillFilled",
  "pill-off": "IconPillOff",
  pills: "IconPills",
  pin: "IconPin",
  "pin-end": "IconPinEnd",
  "pin-filled": "IconPinFilled",
  "pin-invoke": "IconPinInvoke",
  "ping-pong": "IconPingPong",
  pinned: "IconPinned",
  "pinned-filled": "IconPinnedFilled",
  "pinned-off": "IconPinnedOff",
  pizza: "IconPizza",
  "pizza-off": "IconPizzaOff",
  placeholder: "IconPlaceholder",
  plane: "IconPlane",
  "plane-arrival": "IconPlaneArrival",
  "plane-departure": "IconPlaneDeparture",
  "plane-inflight": "IconPlaneInflight",
  "plane-off": "IconPlaneOff",
  "plane-tilt": "IconPlaneTilt",
  planet: "IconPlanet",
  "planet-off": "IconPlanetOff",
  plant: "IconPlant",
  "plant-2": "IconPlant2",
  "plant-2-off": "IconPlant2Off",
  "plant-off": "IconPlantOff",
  "play-basketball": "IconPlayBasketball",
  "play-card": "IconPlayCard",
  "play-card-off": "IconPlayCardOff",
  "play-football": "IconPlayFootball",
  "play-handball": "IconPlayHandball",
  "play-volleyball": "IconPlayVolleyball",
  "player-eject": "IconPlayerEject",
  "player-eject-filled": "IconPlayerEjectFilled",
  "player-pause": "IconPlayerPause",
  "player-pause-filled": "IconPlayerPauseFilled",
  "player-play": "IconPlayerPlay",
  "player-play-filled": "IconPlayerPlayFilled",
  "player-record": "IconPlayerRecord",
  "player-record-filled": "IconPlayerRecordFilled",
  "player-skip-back": "IconPlayerSkipBack",
  "player-skip-back-filled": "IconPlayerSkipBackFilled",
  "player-skip-forward": "IconPlayerSkipForward",
  "player-skip-forward-filled": "IconPlayerSkipForwardFilled",
  "player-stop": "IconPlayerStop",
  "player-stop-filled": "IconPlayerStopFilled",
  "player-track-next": "IconPlayerTrackNext",
  "player-track-next-filled": "IconPlayerTrackNextFilled",
  "player-track-prev": "IconPlayerTrackPrev",
  "player-track-prev-filled": "IconPlayerTrackPrevFilled",
  playlist: "IconPlaylist",
  "playlist-add": "IconPlaylistAdd",
  "playlist-off": "IconPlaylistOff",
  "playlist-x": "IconPlaylistX",
  "playstation-circle": "IconPlaystationCircle",
  "playstation-square": "IconPlaystationSquare",
  "playstation-triangle": "IconPlaystationTriangle",
  "playstation-x": "IconPlaystationX",
  plug: "IconPlug",
  "plug-connected": "IconPlugConnected",
  "plug-connected-x": "IconPlugConnectedX",
  "plug-off": "IconPlugOff",
  "plug-x": "IconPlugX",
  plus: "IconPlus",
  "plus-equal": "IconPlusEqual",
  "plus-minus": "IconPlusMinus",
  png: "IconPng",
  podium: "IconPodium",
  "podium-off": "IconPodiumOff",
  point: "IconPoint",
  "point-filled": "IconPointFilled",
  "point-off": "IconPointOff",
  pointer: "IconPointer",
  "pointer-bolt": "IconPointerBolt",
  "pointer-cancel": "IconPointerCancel",
  "pointer-check": "IconPointerCheck",
  "pointer-code": "IconPointerCode",
  "pointer-cog": "IconPointerCog",
  "pointer-dollar": "IconPointerDollar",
  "pointer-down": "IconPointerDown",
  "pointer-exclamation": "IconPointerExclamation",
  "pointer-filled": "IconPointerFilled",
  "pointer-heart": "IconPointerHeart",
  "pointer-minus": "IconPointerMinus",
  "pointer-off": "IconPointerOff",
  "pointer-pause": "IconPointerPause",
  "pointer-pin": "IconPointerPin",
  "pointer-plus": "IconPointerPlus",
  "pointer-question": "IconPointerQuestion",
  "pointer-search": "IconPointerSearch",
  "pointer-share": "IconPointerShare",
  "pointer-star": "IconPointerStar",
  "pointer-up": "IconPointerUp",
  "pointer-x": "IconPointerX",
  pokeball: "IconPokeball",
  "pokeball-off": "IconPokeballOff",
  "poker-chip": "IconPokerChip",
  polaroid: "IconPolaroid",
  "polaroid-filled": "IconPolaroidFilled",
  polygon: "IconPolygon",
  "polygon-off": "IconPolygonOff",
  poo: "IconPoo",
  "poo-filled": "IconPooFilled",
  pool: "IconPool",
  "pool-off": "IconPoolOff",
  power: "IconPower",
  pray: "IconPray",
  "premium-rights": "IconPremiumRights",
  prescription: "IconPrescription",
  presentation: "IconPresentation",
  "presentation-analytics": "IconPresentationAnalytics",
  "presentation-analytics-filled": "IconPresentationAnalyticsFilled",
  "presentation-filled": "IconPresentationFilled",
  "presentation-off": "IconPresentationOff",
  printer: "IconPrinter",
  "printer-off": "IconPrinterOff",
  prism: "IconPrism",
  "prism-light": "IconPrismLight",
  "prism-off": "IconPrismOff",
  "prism-plus": "IconPrismPlus",
  prison: "IconPrison",
  progress: "IconProgress",
  "progress-alert": "IconProgressAlert",
  "progress-bolt": "IconProgressBolt",
  "progress-check": "IconProgressCheck",
  "progress-down": "IconProgressDown",
  "progress-help": "IconProgressHelp",
  "progress-x": "IconProgressX",
  prompt: "IconPrompt",
  prong: "IconProng",
  propeller: "IconPropeller",
  "propeller-off": "IconPropellerOff",
  protocol: "IconProtocol",
  "pumpkin-scary": "IconPumpkinScary",
  puzzle: "IconPuzzle",
  "puzzle-2": "IconPuzzle2",
  "puzzle-filled": "IconPuzzleFilled",
  "puzzle-off": "IconPuzzleOff",
  pyramid: "IconPyramid",
  "pyramid-off": "IconPyramidOff",
  "pyramid-plus": "IconPyramidPlus",
  qrcode: "IconQrcode",
  "qrcode-off": "IconQrcodeOff",
  "question-mark": "IconQuestionMark",
  quote: "IconQuote",
  "quote-off": "IconQuoteOff",
  quotes: "IconQuotes",
  radar: "IconRadar",
  "radar-2": "IconRadar2",
  "radar-filled": "IconRadarFilled",
  "radar-off": "IconRadarOff",
  radio: "IconRadio",
  "radio-off": "IconRadioOff",
  radioactive: "IconRadioactive",
  "radioactive-filled": "IconRadioactiveFilled",
  "radioactive-off": "IconRadioactiveOff",
  "radius-bottom-left": "IconRadiusBottomLeft",
  "radius-bottom-right": "IconRadiusBottomRight",
  "radius-top-left": "IconRadiusTopLeft",
  "radius-top-right": "IconRadiusTopRight",
  rainbow: "IconRainbow",
  "rainbow-off": "IconRainbowOff",
  "rating-12-plus": "IconRating12Plus",
  "rating-14-plus": "IconRating14Plus",
  "rating-16-plus": "IconRating16Plus",
  "rating-18-plus": "IconRating18Plus",
  "rating-21-plus": "IconRating21Plus",
  razor: "IconRazor",
  "razor-electric": "IconRazorElectric",
  receipt: "IconReceipt",
  "receipt-2": "IconReceipt2",
  "receipt-bitcoin": "IconReceiptBitcoin",
  "receipt-dollar": "IconReceiptDollar",
  "receipt-euro": "IconReceiptEuro",
  "receipt-filled": "IconReceiptFilled",
  "receipt-off": "IconReceiptOff",
  "receipt-pound": "IconReceiptPound",
  "receipt-refund": "IconReceiptRefund",
  "receipt-rupee": "IconReceiptRupee",
  "receipt-tax": "IconReceiptTax",
  "receipt-yen": "IconReceiptYen",
  "receipt-yuan": "IconReceiptYuan",
  recharging: "IconRecharging",
  "record-mail": "IconRecordMail",
  "record-mail-off": "IconRecordMailOff",
  rectangle: "IconRectangle",
  "rectangle-filled": "IconRectangleFilled",
  "rectangle-rounded-bottom": "IconRectangleRoundedBottom",
  "rectangle-rounded-top": "IconRectangleRoundedTop",
  "rectangle-vertical": "IconRectangleVertical",
  "rectangle-vertical-filled": "IconRectangleVerticalFilled",
  "rectangular-prism": "IconRectangularPrism",
  "rectangular-prism-off": "IconRectangularPrismOff",
  "rectangular-prism-plus": "IconRectangularPrismPlus",
  recycle: "IconRecycle",
  "recycle-off": "IconRecycleOff",
  refresh: "IconRefresh",
  "refresh-alert": "IconRefreshAlert",
  "refresh-dot": "IconRefreshDot",
  "refresh-off": "IconRefreshOff",
  regex: "IconRegex",
  "regex-off": "IconRegexOff",
  registered: "IconRegistered",
  "relation-many-to-many": "IconRelationManyToMany",
  "relation-many-to-many-filled": "IconRelationManyToManyFilled",
  "relation-one-to-many": "IconRelationOneToMany",
  "relation-one-to-many-filled": "IconRelationOneToManyFilled",
  "relation-one-to-one": "IconRelationOneToOne",
  "relation-one-to-one-filled": "IconRelationOneToOneFilled",
  reload: "IconReload",
  reorder: "IconReorder",
  repeat: "IconRepeat",
  "repeat-off": "IconRepeatOff",
  "repeat-once": "IconRepeatOnce",
  replace: "IconReplace",
  "replace-filled": "IconReplaceFilled",
  "replace-off": "IconReplaceOff",
  report: "IconReport",
  "report-analytics": "IconReportAnalytics",
  "report-medical": "IconReportMedical",
  "report-money": "IconReportMoney",
  "report-off": "IconReportOff",
  "report-search": "IconReportSearch",
  "reserved-line": "IconReservedLine",
  resize: "IconResize",
  restore: "IconRestore",
  "rewind-backward-10": "IconRewindBackward10",
  "rewind-backward-15": "IconRewindBackward15",
  "rewind-backward-20": "IconRewindBackward20",
  "rewind-backward-30": "IconRewindBackward30",
  "rewind-backward-40": "IconRewindBackward40",
  "rewind-backward-5": "IconRewindBackward5",
  "rewind-backward-50": "IconRewindBackward50",
  "rewind-backward-60": "IconRewindBackward60",
  "rewind-forward-10": "IconRewindForward10",
  "rewind-forward-15": "IconRewindForward15",
  "rewind-forward-20": "IconRewindForward20",
  "rewind-forward-30": "IconRewindForward30",
  "rewind-forward-40": "IconRewindForward40",
  "rewind-forward-5": "IconRewindForward5",
  "rewind-forward-50": "IconRewindForward50",
  "rewind-forward-60": "IconRewindForward60",
  "ribbon-health": "IconRibbonHealth",
  rings: "IconRings",
  ripple: "IconRipple",
  "ripple-off": "IconRippleOff",
  road: "IconRoad",
  "road-off": "IconRoadOff",
  "road-sign": "IconRoadSign",
  robot: "IconRobot",
  "robot-face": "IconRobotFace",
  "robot-off": "IconRobotOff",
  rocket: "IconRocket",
  "rocket-off": "IconRocketOff",
  "roller-skating": "IconRollerSkating",
  rollercoaster: "IconRollercoaster",
  "rollercoaster-off": "IconRollercoasterOff",
  rosette: "IconRosette",
  "rosette-discount": "IconRosetteDiscount",
  "rosette-discount-check": "IconRosetteDiscountCheck",
  "rosette-discount-check-filled": "IconRosetteDiscountCheckFilled",
  "rosette-discount-check-off": "IconRosetteDiscountCheckOff",
  "rosette-discount-filled": "IconRosetteDiscountFilled",
  "rosette-discount-off": "IconRosetteDiscountOff",
  "rosette-filled": "IconRosetteFilled",
  "rosette-number-0": "IconRosetteNumber0",
  "rosette-number-1": "IconRosetteNumber1",
  "rosette-number-2": "IconRosetteNumber2",
  "rosette-number-3": "IconRosetteNumber3",
  "rosette-number-4": "IconRosetteNumber4",
  "rosette-number-5": "IconRosetteNumber5",
  "rosette-number-6": "IconRosetteNumber6",
  "rosette-number-7": "IconRosetteNumber7",
  "rosette-number-8": "IconRosetteNumber8",
  "rosette-number-9": "IconRosetteNumber9",
  rotate: "IconRotate",
  "rotate-2": "IconRotate2",
  "rotate-360": "IconRotate360",
  "rotate-3-d": "IconRotate3d",
  "rotate-clockwise": "IconRotateClockwise",
  "rotate-clockwise-2": "IconRotateClockwise2",
  "rotate-dot": "IconRotateDot",
  "rotate-rectangle": "IconRotateRectangle",
  route: "IconRoute",
  "route-2": "IconRoute2",
  "route-alt-left": "IconRouteAltLeft",
  "route-alt-right": "IconRouteAltRight",
  "route-off": "IconRouteOff",
  "route-scan": "IconRouteScan",
  "route-square": "IconRouteSquare",
  "route-square-2": "IconRouteSquare2",
  "route-x": "IconRouteX",
  "route-x-2": "IconRouteX2",
  router: "IconRouter",
  "router-off": "IconRouterOff",
  "row-insert-bottom": "IconRowInsertBottom",
  "row-insert-top": "IconRowInsertTop",
  "row-remove": "IconRowRemove",
  rss: "IconRss",
  "rubber-stamp": "IconRubberStamp",
  "rubber-stamp-off": "IconRubberStampOff",
  ruler: "IconRuler",
  "ruler-2": "IconRuler2",
  "ruler-2-off": "IconRuler2Off",
  "ruler-3": "IconRuler3",
  "ruler-measure": "IconRulerMeasure",
  "ruler-measure-2": "IconRulerMeasure2",
  "ruler-off": "IconRulerOff",
  run: "IconRun",
  "rv-truck": "IconRvTruck",
  "s-turn-down": "IconSTurnDown",
  "s-turn-left": "IconSTurnLeft",
  "s-turn-right": "IconSTurnRight",
  "s-turn-up": "IconSTurnUp",
  sailboat: "IconSailboat",
  "sailboat-2": "IconSailboat2",
  "sailboat-off": "IconSailboatOff",
  salad: "IconSalad",
  salt: "IconSalt",
  sandbox: "IconSandbox",
  satellite: "IconSatellite",
  "satellite-off": "IconSatelliteOff",
  sausage: "IconSausage",
  scale: "IconScale",
  "scale-off": "IconScaleOff",
  "scale-outline": "IconScaleOutline",
  "scale-outline-off": "IconScaleOutlineOff",
  scan: "IconScan",
  "scan-eye": "IconScanEye",
  "scan-position": "IconScanPosition",
  schema: "IconSchema",
  "schema-off": "IconSchemaOff",
  school: "IconSchool",
  "school-bell": "IconSchoolBell",
  "school-off": "IconSchoolOff",
  scissors: "IconScissors",
  "scissors-off": "IconScissorsOff",
  scooter: "IconScooter",
  "scooter-electric": "IconScooterElectric",
  scoreboard: "IconScoreboard",
  "screen-share": "IconScreenShare",
  "screen-share-off": "IconScreenShareOff",
  screenshot: "IconScreenshot",
  scribble: "IconScribble",
  "scribble-off": "IconScribbleOff",
  script: "IconScript",
  "script-minus": "IconScriptMinus",
  "script-plus": "IconScriptPlus",
  "script-x": "IconScriptX",
  "scuba-diving": "IconScubaDiving",
  "scuba-diving-tank": "IconScubaDivingTank",
  "scuba-diving-tank-filled": "IconScubaDivingTankFilled",
  "scuba-mask": "IconScubaMask",
  "scuba-mask-off": "IconScubaMaskOff",
  sdk: "IconSdk",
  search: "IconSearch",
  "search-off": "IconSearchOff",
  section: "IconSection",
  "section-filled": "IconSectionFilled",
  "section-sign": "IconSectionSign",
  seeding: "IconSeeding",
  "seeding-off": "IconSeedingOff",
  select: "IconSelect",
  "select-all": "IconSelectAll",
  selector: "IconSelector",
  send: "IconSend",
  "send-2": "IconSend2",
  "send-off": "IconSendOff",
  seo: "IconSeo",
  separator: "IconSeparator",
  "separator-horizontal": "IconSeparatorHorizontal",
  "separator-vertical": "IconSeparatorVertical",
  server: "IconServer",
  "server-2": "IconServer2",
  "server-bolt": "IconServerBolt",
  "server-cog": "IconServerCog",
  "server-off": "IconServerOff",
  servicemark: "IconServicemark",
  settings: "IconSettings",
  "settings-2": "IconSettings2",
  "settings-automation": "IconSettingsAutomation",
  "settings-bolt": "IconSettingsBolt",
  "settings-cancel": "IconSettingsCancel",
  "settings-check": "IconSettingsCheck",
  "settings-code": "IconSettingsCode",
  "settings-cog": "IconSettingsCog",
  "settings-dollar": "IconSettingsDollar",
  "settings-down": "IconSettingsDown",
  "settings-exclamation": "IconSettingsExclamation",
  "settings-filled": "IconSettingsFilled",
  "settings-heart": "IconSettingsHeart",
  "settings-minus": "IconSettingsMinus",
  "settings-off": "IconSettingsOff",
  "settings-pause": "IconSettingsPause",
  "settings-pin": "IconSettingsPin",
  "settings-plus": "IconSettingsPlus",
  "settings-question": "IconSettingsQuestion",
  "settings-search": "IconSettingsSearch",
  "settings-share": "IconSettingsShare",
  "settings-star": "IconSettingsStar",
  "settings-up": "IconSettingsUp",
  "settings-x": "IconSettingsX",
  shadow: "IconShadow",
  "shadow-off": "IconShadowOff",
  shape: "IconShape",
  "shape-2": "IconShape2",
  "shape-3": "IconShape3",
  "shape-off": "IconShapeOff",
  share: "IconShare",
  "share-2": "IconShare2",
  "share-3": "IconShare3",
  "share-off": "IconShareOff",
  shareplay: "IconShareplay",
  shield: "IconShield",
  "shield-bolt": "IconShieldBolt",
  "shield-cancel": "IconShieldCancel",
  "shield-check": "IconShieldCheck",
  "shield-check-filled": "IconShieldCheckFilled",
  "shield-checkered": "IconShieldCheckered",
  "shield-checkered-filled": "IconShieldCheckeredFilled",
  "shield-chevron": "IconShieldChevron",
  "shield-code": "IconShieldCode",
  "shield-cog": "IconShieldCog",
  "shield-dollar": "IconShieldDollar",
  "shield-down": "IconShieldDown",
  "shield-exclamation": "IconShieldExclamation",
  "shield-filled": "IconShieldFilled",
  "shield-half": "IconShieldHalf",
  "shield-half-filled": "IconShieldHalfFilled",
  "shield-heart": "IconShieldHeart",
  "shield-lock": "IconShieldLock",
  "shield-lock-filled": "IconShieldLockFilled",
  "shield-minus": "IconShieldMinus",
  "shield-off": "IconShieldOff",
  "shield-pause": "IconShieldPause",
  "shield-pin": "IconShieldPin",
  "shield-plus": "IconShieldPlus",
  "shield-question": "IconShieldQuestion",
  "shield-search": "IconShieldSearch",
  "shield-share": "IconShieldShare",
  "shield-star": "IconShieldStar",
  "shield-up": "IconShieldUp",
  "shield-x": "IconShieldX",
  ship: "IconShip",
  "ship-off": "IconShipOff",
  shirt: "IconShirt",
  "shirt-filled": "IconShirtFilled",
  "shirt-off": "IconShirtOff",
  "shirt-sport": "IconShirtSport",
  shoe: "IconShoe",
  "shoe-off": "IconShoeOff",
  "shopping-bag": "IconShoppingBag",
  "shopping-bag-check": "IconShoppingBagCheck",
  "shopping-bag-discount": "IconShoppingBagDiscount",
  "shopping-bag-edit": "IconShoppingBagEdit",
  "shopping-bag-exclamation": "IconShoppingBagExclamation",
  "shopping-bag-heart": "IconShoppingBagHeart",
  "shopping-bag-minus": "IconShoppingBagMinus",
  "shopping-bag-plus": "IconShoppingBagPlus",
  "shopping-bag-search": "IconShoppingBagSearch",
  "shopping-bag-x": "IconShoppingBagX",
  "shopping-cart": "IconShoppingCart",
  "shopping-cart-bolt": "IconShoppingCartBolt",
  "shopping-cart-cancel": "IconShoppingCartCancel",
  "shopping-cart-check": "IconShoppingCartCheck",
  "shopping-cart-code": "IconShoppingCartCode",
  "shopping-cart-cog": "IconShoppingCartCog",
  "shopping-cart-copy": "IconShoppingCartCopy",
  "shopping-cart-discount": "IconShoppingCartDiscount",
  "shopping-cart-dollar": "IconShoppingCartDollar",
  "shopping-cart-down": "IconShoppingCartDown",
  "shopping-cart-exclamation": "IconShoppingCartExclamation",
  "shopping-cart-filled": "IconShoppingCartFilled",
  "shopping-cart-heart": "IconShoppingCartHeart",
  "shopping-cart-minus": "IconShoppingCartMinus",
  "shopping-cart-off": "IconShoppingCartOff",
  "shopping-cart-pause": "IconShoppingCartPause",
  "shopping-cart-pin": "IconShoppingCartPin",
  "shopping-cart-plus": "IconShoppingCartPlus",
  "shopping-cart-question": "IconShoppingCartQuestion",
  "shopping-cart-search": "IconShoppingCartSearch",
  "shopping-cart-share": "IconShoppingCartShare",
  "shopping-cart-star": "IconShoppingCartStar",
  "shopping-cart-up": "IconShoppingCartUp",
  "shopping-cart-x": "IconShoppingCartX",
  shovel: "IconShovel",
  "shovel-pitchforks": "IconShovelPitchforks",
  shredder: "IconShredder",
  "sign-left": "IconSignLeft",
  "sign-left-filled": "IconSignLeftFilled",
  "sign-right": "IconSignRight",
  "sign-right-filled": "IconSignRightFilled",
  "signal-2-g": "IconSignal2g",
  "signal-3-g": "IconSignal3g",
  "signal-4-g": "IconSignal4g",
  "signal-4-g-plus": "IconSignal4gPlus",
  "signal-5-g": "IconSignal5g",
  "signal-6-g": "IconSignal6g",
  "signal-e": "IconSignalE",
  "signal-g": "IconSignalG",
  "signal-h": "IconSignalH",
  "signal-h-plus": "IconSignalHPlus",
  "signal-lte": "IconSignalLte",
  signature: "IconSignature",
  "signature-off": "IconSignatureOff",
  sitemap: "IconSitemap",
  "sitemap-off": "IconSitemapOff",
  skateboard: "IconSkateboard",
  "skateboard-off": "IconSkateboardOff",
  skateboarding: "IconSkateboarding",
  "skew-x": "IconSkewX",
  "skew-y": "IconSkewY",
  "ski-jumping": "IconSkiJumping",
  skull: "IconSkull",
  slash: "IconSlash",
  slashes: "IconSlashes",
  sleigh: "IconSleigh",
  slice: "IconSlice",
  slideshow: "IconSlideshow",
  "smart-home": "IconSmartHome",
  "smart-home-off": "IconSmartHomeOff",
  smoking: "IconSmoking",
  "smoking-no": "IconSmokingNo",
  snowboarding: "IconSnowboarding",
  snowflake: "IconSnowflake",
  "snowflake-off": "IconSnowflakeOff",
  snowman: "IconSnowman",
  "soccer-field": "IconSoccerField",
  social: "IconSocial",
  "social-off": "IconSocialOff",
  sock: "IconSock",
  sofa: "IconSofa",
  "sofa-off": "IconSofaOff",
  "solar-electricity": "IconSolarElectricity",
  "solar-panel": "IconSolarPanel",
  "solar-panel-2": "IconSolarPanel2",
  "sort-09": "IconSort09",
  "sort-90": "IconSort90",
  "sort-az": "IconSortAZ",
  "sort-ascending": "IconSortAscending",
  "sort-ascending-2": "IconSortAscending2",
  "sort-ascending-2-filled": "IconSortAscending2Filled",
  "sort-ascending-letters": "IconSortAscendingLetters",
  "sort-ascending-numbers": "IconSortAscendingNumbers",
  "sort-ascending-shapes": "IconSortAscendingShapes",
  "sort-ascending-shapes-filled": "IconSortAscendingShapesFilled",
  "sort-ascending-small-big": "IconSortAscendingSmallBig",
  "sort-descending": "IconSortDescending",
  "sort-descending-2": "IconSortDescending2",
  "sort-descending-2-filled": "IconSortDescending2Filled",
  "sort-descending-letters": "IconSortDescendingLetters",
  "sort-descending-numbers": "IconSortDescendingNumbers",
  "sort-descending-shapes": "IconSortDescendingShapes",
  "sort-descending-shapes-filled": "IconSortDescendingShapesFilled",
  "sort-descending-small-big": "IconSortDescendingSmallBig",
  "sort-za": "IconSortZA",
  sos: "IconSos",
  soup: "IconSoup",
  "soup-filled": "IconSoupFilled",
  "soup-off": "IconSoupOff",
  "source-code": "IconSourceCode",
  space: "IconSpace",
  "space-off": "IconSpaceOff",
  spaces: "IconSpaces",
  "spacing-horizontal": "IconSpacingHorizontal",
  "spacing-vertical": "IconSpacingVertical",
  spade: "IconSpade",
  "spade-filled": "IconSpadeFilled",
  sparkles: "IconSparkles",
  speakerphone: "IconSpeakerphone",
  speedboat: "IconSpeedboat",
  sphere: "IconSphere",
  "sphere-off": "IconSphereOff",
  "sphere-plus": "IconSpherePlus",
  spider: "IconSpider",
  spiral: "IconSpiral",
  "spiral-off": "IconSpiralOff",
  "sport-billard": "IconSportBillard",
  spray: "IconSpray",
  spy: "IconSpy",
  "spy-off": "IconSpyOff",
  sql: "IconSql",
  square: "IconSquare",
  "square-arrow-down": "IconSquareArrowDown",
  "square-arrow-down-filled": "IconSquareArrowDownFilled",
  "square-arrow-left": "IconSquareArrowLeft",
  "square-arrow-left-filled": "IconSquareArrowLeftFilled",
  "square-arrow-right": "IconSquareArrowRight",
  "square-arrow-right-filled": "IconSquareArrowRightFilled",
  "square-arrow-up": "IconSquareArrowUp",
  "square-arrow-up-filled": "IconSquareArrowUpFilled",
  "square-asterisk": "IconSquareAsterisk",
  "square-asterisk-filled": "IconSquareAsteriskFilled",
  "square-check": "IconSquareCheck",
  "square-check-filled": "IconSquareCheckFilled",
  "square-chevron-down": "IconSquareChevronDown",
  "square-chevron-down-filled": "IconSquareChevronDownFilled",
  "square-chevron-left": "IconSquareChevronLeft",
  "square-chevron-left-filled": "IconSquareChevronLeftFilled",
  "square-chevron-right": "IconSquareChevronRight",
  "square-chevron-right-filled": "IconSquareChevronRightFilled",
  "square-chevron-up": "IconSquareChevronUp",
  "square-chevron-up-filled": "IconSquareChevronUpFilled",
  "square-chevrons-down": "IconSquareChevronsDown",
  "square-chevrons-down-filled": "IconSquareChevronsDownFilled",
  "square-chevrons-left": "IconSquareChevronsLeft",
  "square-chevrons-left-filled": "IconSquareChevronsLeftFilled",
  "square-chevrons-right": "IconSquareChevronsRight",
  "square-chevrons-right-filled": "IconSquareChevronsRightFilled",
  "square-chevrons-up": "IconSquareChevronsUp",
  "square-chevrons-up-filled": "IconSquareChevronsUpFilled",
  "square-dot": "IconSquareDot",
  "square-dot-filled": "IconSquareDotFilled",
  "square-f-0": "IconSquareF0",
  "square-f-0-filled": "IconSquareF0Filled",
  "square-f-1": "IconSquareF1",
  "square-f-1-filled": "IconSquareF1Filled",
  "square-f-2": "IconSquareF2",
  "square-f-2-filled": "IconSquareF2Filled",
  "square-f-3": "IconSquareF3",
  "square-f-3-filled": "IconSquareF3Filled",
  "square-f-4": "IconSquareF4",
  "square-f-4-filled": "IconSquareF4Filled",
  "square-f-5": "IconSquareF5",
  "square-f-5-filled": "IconSquareF5Filled",
  "square-f-6": "IconSquareF6",
  "square-f-6-filled": "IconSquareF6Filled",
  "square-f-7": "IconSquareF7",
  "square-f-7-filled": "IconSquareF7Filled",
  "square-f-8": "IconSquareF8",
  "square-f-8-filled": "IconSquareF8Filled",
  "square-f-9": "IconSquareF9",
  "square-f-9-filled": "IconSquareF9Filled",
  "square-filled": "IconSquareFilled",
  "square-forbid": "IconSquareForbid",
  "square-forbid-2": "IconSquareForbid2",
  "square-half": "IconSquareHalf",
  "square-key": "IconSquareKey",
  "square-letter-a": "IconSquareLetterA",
  "square-letter-a-filled": "IconSquareLetterAFilled",
  "square-letter-b": "IconSquareLetterB",
  "square-letter-b-filled": "IconSquareLetterBFilled",
  "square-letter-c": "IconSquareLetterC",
  "square-letter-c-filled": "IconSquareLetterCFilled",
  "square-letter-d": "IconSquareLetterD",
  "square-letter-d-filled": "IconSquareLetterDFilled",
  "square-letter-e": "IconSquareLetterE",
  "square-letter-e-filled": "IconSquareLetterEFilled",
  "square-letter-f": "IconSquareLetterF",
  "square-letter-f-filled": "IconSquareLetterFFilled",
  "square-letter-g": "IconSquareLetterG",
  "square-letter-g-filled": "IconSquareLetterGFilled",
  "square-letter-h": "IconSquareLetterH",
  "square-letter-h-filled": "IconSquareLetterHFilled",
  "square-letter-i": "IconSquareLetterI",
  "square-letter-i-filled": "IconSquareLetterIFilled",
  "square-letter-j": "IconSquareLetterJ",
  "square-letter-j-filled": "IconSquareLetterJFilled",
  "square-letter-k": "IconSquareLetterK",
  "square-letter-k-filled": "IconSquareLetterKFilled",
  "square-letter-l": "IconSquareLetterL",
  "square-letter-l-filled": "IconSquareLetterLFilled",
  "square-letter-m": "IconSquareLetterM",
  "square-letter-m-filled": "IconSquareLetterMFilled",
  "square-letter-n": "IconSquareLetterN",
  "square-letter-n-filled": "IconSquareLetterNFilled",
  "square-letter-o": "IconSquareLetterO",
  "square-letter-o-filled": "IconSquareLetterOFilled",
  "square-letter-p": "IconSquareLetterP",
  "square-letter-p-filled": "IconSquareLetterPFilled",
  "square-letter-q": "IconSquareLetterQ",
  "square-letter-q-filled": "IconSquareLetterQFilled",
  "square-letter-r": "IconSquareLetterR",
  "square-letter-r-filled": "IconSquareLetterRFilled",
  "square-letter-s": "IconSquareLetterS",
  "square-letter-s-filled": "IconSquareLetterSFilled",
  "square-letter-t": "IconSquareLetterT",
  "square-letter-t-filled": "IconSquareLetterTFilled",
  "square-letter-u": "IconSquareLetterU",
  "square-letter-u-filled": "IconSquareLetterUFilled",
  "square-letter-v": "IconSquareLetterV",
  "square-letter-v-filled": "IconSquareLetterVFilled",
  "square-letter-w": "IconSquareLetterW",
  "square-letter-w-filled": "IconSquareLetterWFilled",
  "square-letter-x": "IconSquareLetterX",
  "square-letter-x-filled": "IconSquareLetterXFilled",
  "square-letter-y": "IconSquareLetterY",
  "square-letter-y-filled": "IconSquareLetterYFilled",
  "square-letter-z": "IconSquareLetterZ",
  "square-letter-z-filled": "IconSquareLetterZFilled",
  "square-minus": "IconSquareMinus",
  "square-minus-filled": "IconSquareMinusFilled",
  "square-number-0": "IconSquareNumber0",
  "square-number-0-filled": "IconSquareNumber0Filled",
  "square-number-1": "IconSquareNumber1",
  "square-number-1-filled": "IconSquareNumber1Filled",
  "square-number-2": "IconSquareNumber2",
  "square-number-2-filled": "IconSquareNumber2Filled",
  "square-number-3": "IconSquareNumber3",
  "square-number-3-filled": "IconSquareNumber3Filled",
  "square-number-4": "IconSquareNumber4",
  "square-number-4-filled": "IconSquareNumber4Filled",
  "square-number-5": "IconSquareNumber5",
  "square-number-5-filled": "IconSquareNumber5Filled",
  "square-number-6": "IconSquareNumber6",
  "square-number-6-filled": "IconSquareNumber6Filled",
  "square-number-7": "IconSquareNumber7",
  "square-number-7-filled": "IconSquareNumber7Filled",
  "square-number-8": "IconSquareNumber8",
  "square-number-8-filled": "IconSquareNumber8Filled",
  "square-number-9": "IconSquareNumber9",
  "square-number-9-filled": "IconSquareNumber9Filled",
  "square-off": "IconSquareOff",
  "square-percentage": "IconSquarePercentage",
  "square-plus": "IconSquarePlus",
  "square-plus-2": "IconSquarePlus2",
  "square-root": "IconSquareRoot",
  "square-root-2": "IconSquareRoot2",
  "square-rotated": "IconSquareRotated",
  "square-rotated-filled": "IconSquareRotatedFilled",
  "square-rotated-forbid": "IconSquareRotatedForbid",
  "square-rotated-forbid-2": "IconSquareRotatedForbid2",
  "square-rotated-off": "IconSquareRotatedOff",
  "square-rounded": "IconSquareRounded",
  "square-rounded-arrow-down": "IconSquareRoundedArrowDown",
  "square-rounded-arrow-down-filled": "IconSquareRoundedArrowDownFilled",
  "square-rounded-arrow-left": "IconSquareRoundedArrowLeft",
  "square-rounded-arrow-left-filled": "IconSquareRoundedArrowLeftFilled",
  "square-rounded-arrow-right": "IconSquareRoundedArrowRight",
  "square-rounded-arrow-right-filled": "IconSquareRoundedArrowRightFilled",
  "square-rounded-arrow-up": "IconSquareRoundedArrowUp",
  "square-rounded-arrow-up-filled": "IconSquareRoundedArrowUpFilled",
  "square-rounded-check": "IconSquareRoundedCheck",
  "square-rounded-check-filled": "IconSquareRoundedCheckFilled",
  "square-rounded-chevron-down": "IconSquareRoundedChevronDown",
  "square-rounded-chevron-down-filled": "IconSquareRoundedChevronDownFilled",
  "square-rounded-chevron-left": "IconSquareRoundedChevronLeft",
  "square-rounded-chevron-left-filled": "IconSquareRoundedChevronLeftFilled",
  "square-rounded-chevron-right": "IconSquareRoundedChevronRight",
  "square-rounded-chevron-right-filled": "IconSquareRoundedChevronRightFilled",
  "square-rounded-chevron-up": "IconSquareRoundedChevronUp",
  "square-rounded-chevron-up-filled": "IconSquareRoundedChevronUpFilled",
  "square-rounded-chevrons-down": "IconSquareRoundedChevronsDown",
  "square-rounded-chevrons-down-filled": "IconSquareRoundedChevronsDownFilled",
  "square-rounded-chevrons-left": "IconSquareRoundedChevronsLeft",
  "square-rounded-chevrons-left-filled": "IconSquareRoundedChevronsLeftFilled",
  "square-rounded-chevrons-right": "IconSquareRoundedChevronsRight",
  "square-rounded-chevrons-right-filled":
    "IconSquareRoundedChevronsRightFilled",
  "square-rounded-chevrons-up": "IconSquareRoundedChevronsUp",
  "square-rounded-chevrons-up-filled": "IconSquareRoundedChevronsUpFilled",
  "square-rounded-filled": "IconSquareRoundedFilled",
  "square-rounded-letter-a": "IconSquareRoundedLetterA",
  "square-rounded-letter-a-filled": "IconSquareRoundedLetterAFilled",
  "square-rounded-letter-b": "IconSquareRoundedLetterB",
  "square-rounded-letter-b-filled": "IconSquareRoundedLetterBFilled",
  "square-rounded-letter-c": "IconSquareRoundedLetterC",
  "square-rounded-letter-c-filled": "IconSquareRoundedLetterCFilled",
  "square-rounded-letter-d": "IconSquareRoundedLetterD",
  "square-rounded-letter-d-filled": "IconSquareRoundedLetterDFilled",
  "square-rounded-letter-e": "IconSquareRoundedLetterE",
  "square-rounded-letter-e-filled": "IconSquareRoundedLetterEFilled",
  "square-rounded-letter-f": "IconSquareRoundedLetterF",
  "square-rounded-letter-f-filled": "IconSquareRoundedLetterFFilled",
  "square-rounded-letter-g": "IconSquareRoundedLetterG",
  "square-rounded-letter-g-filled": "IconSquareRoundedLetterGFilled",
  "square-rounded-letter-h": "IconSquareRoundedLetterH",
  "square-rounded-letter-h-filled": "IconSquareRoundedLetterHFilled",
  "square-rounded-letter-i": "IconSquareRoundedLetterI",
  "square-rounded-letter-i-filled": "IconSquareRoundedLetterIFilled",
  "square-rounded-letter-j": "IconSquareRoundedLetterJ",
  "square-rounded-letter-j-filled": "IconSquareRoundedLetterJFilled",
  "square-rounded-letter-k": "IconSquareRoundedLetterK",
  "square-rounded-letter-k-filled": "IconSquareRoundedLetterKFilled",
  "square-rounded-letter-l": "IconSquareRoundedLetterL",
  "square-rounded-letter-l-filled": "IconSquareRoundedLetterLFilled",
  "square-rounded-letter-m": "IconSquareRoundedLetterM",
  "square-rounded-letter-m-filled": "IconSquareRoundedLetterMFilled",
  "square-rounded-letter-n": "IconSquareRoundedLetterN",
  "square-rounded-letter-n-filled": "IconSquareRoundedLetterNFilled",
  "square-rounded-letter-o": "IconSquareRoundedLetterO",
  "square-rounded-letter-o-filled": "IconSquareRoundedLetterOFilled",
  "square-rounded-letter-p": "IconSquareRoundedLetterP",
  "square-rounded-letter-p-filled": "IconSquareRoundedLetterPFilled",
  "square-rounded-letter-q": "IconSquareRoundedLetterQ",
  "square-rounded-letter-q-filled": "IconSquareRoundedLetterQFilled",
  "square-rounded-letter-r": "IconSquareRoundedLetterR",
  "square-rounded-letter-r-filled": "IconSquareRoundedLetterRFilled",
  "square-rounded-letter-s": "IconSquareRoundedLetterS",
  "square-rounded-letter-s-filled": "IconSquareRoundedLetterSFilled",
  "square-rounded-letter-t": "IconSquareRoundedLetterT",
  "square-rounded-letter-t-filled": "IconSquareRoundedLetterTFilled",
  "square-rounded-letter-u": "IconSquareRoundedLetterU",
  "square-rounded-letter-u-filled": "IconSquareRoundedLetterUFilled",
  "square-rounded-letter-v": "IconSquareRoundedLetterV",
  "square-rounded-letter-v-filled": "IconSquareRoundedLetterVFilled",
  "square-rounded-letter-w": "IconSquareRoundedLetterW",
  "square-rounded-letter-w-filled": "IconSquareRoundedLetterWFilled",
  "square-rounded-letter-x": "IconSquareRoundedLetterX",
  "square-rounded-letter-x-filled": "IconSquareRoundedLetterXFilled",
  "square-rounded-letter-y": "IconSquareRoundedLetterY",
  "square-rounded-letter-y-filled": "IconSquareRoundedLetterYFilled",
  "square-rounded-letter-z": "IconSquareRoundedLetterZ",
  "square-rounded-letter-z-filled": "IconSquareRoundedLetterZFilled",
  "square-rounded-minus": "IconSquareRoundedMinus",
  "square-rounded-minus-2": "IconSquareRoundedMinus2",
  "square-rounded-minus-filled": "IconSquareRoundedMinusFilled",
  "square-rounded-number-0": "IconSquareRoundedNumber0",
  "square-rounded-number-0-filled": "IconSquareRoundedNumber0Filled",
  "square-rounded-number-1": "IconSquareRoundedNumber1",
  "square-rounded-number-1-filled": "IconSquareRoundedNumber1Filled",
  "square-rounded-number-2": "IconSquareRoundedNumber2",
  "square-rounded-number-2-filled": "IconSquareRoundedNumber2Filled",
  "square-rounded-number-3": "IconSquareRoundedNumber3",
  "square-rounded-number-3-filled": "IconSquareRoundedNumber3Filled",
  "square-rounded-number-4": "IconSquareRoundedNumber4",
  "square-rounded-number-4-filled": "IconSquareRoundedNumber4Filled",
  "square-rounded-number-5": "IconSquareRoundedNumber5",
  "square-rounded-number-5-filled": "IconSquareRoundedNumber5Filled",
  "square-rounded-number-6": "IconSquareRoundedNumber6",
  "square-rounded-number-6-filled": "IconSquareRoundedNumber6Filled",
  "square-rounded-number-7": "IconSquareRoundedNumber7",
  "square-rounded-number-7-filled": "IconSquareRoundedNumber7Filled",
  "square-rounded-number-8": "IconSquareRoundedNumber8",
  "square-rounded-number-8-filled": "IconSquareRoundedNumber8Filled",
  "square-rounded-number-9": "IconSquareRoundedNumber9",
  "square-rounded-number-9-filled": "IconSquareRoundedNumber9Filled",
  "square-rounded-percentage": "IconSquareRoundedPercentage",
  "square-rounded-plus": "IconSquareRoundedPlus",
  "square-rounded-plus-2": "IconSquareRoundedPlus2",
  "square-rounded-plus-filled": "IconSquareRoundedPlusFilled",
  "square-rounded-x": "IconSquareRoundedX",
  "square-rounded-x-filled": "IconSquareRoundedXFilled",
  "square-toggle": "IconSquareToggle",
  "square-toggle-horizontal": "IconSquareToggleHorizontal",
  "square-x": "IconSquareX",
  "square-x-filled": "IconSquareXFilled",
  squares: "IconSquares",
  "squares-diagonal": "IconSquaresDiagonal",
  "squares-filled": "IconSquaresFilled",
  "squares-selected": "IconSquaresSelected",
  stack: "IconStack",
  "stack-2": "IconStack2",
  "stack-2-filled": "IconStack2Filled",
  "stack-3": "IconStack3",
  "stack-3-filled": "IconStack3Filled",
  "stack-back": "IconStackBack",
  "stack-backward": "IconStackBackward",
  "stack-filled": "IconStackFilled",
  "stack-forward": "IconStackForward",
  "stack-front": "IconStackFront",
  "stack-middle": "IconStackMiddle",
  "stack-pop": "IconStackPop",
  "stack-push": "IconStackPush",
  stairs: "IconStairs",
  "stairs-down": "IconStairsDown",
  "stairs-up": "IconStairsUp",
  star: "IconStar",
  "star-filled": "IconStarFilled",
  "star-half": "IconStarHalf",
  "star-half-filled": "IconStarHalfFilled",
  "star-off": "IconStarOff",
  stars: "IconStars",
  "stars-filled": "IconStarsFilled",
  "stars-off": "IconStarsOff",
  "status-change": "IconStatusChange",
  steam: "IconSteam",
  "steering-wheel": "IconSteeringWheel",
  "steering-wheel-filled": "IconSteeringWheelFilled",
  "steering-wheel-off": "IconSteeringWheelOff",
  "step-into": "IconStepInto",
  "step-out": "IconStepOut",
  "stereo-glasses": "IconStereoGlasses",
  stethoscope: "IconStethoscope",
  "stethoscope-off": "IconStethoscopeOff",
  sticker: "IconSticker",
  "sticker-2": "IconSticker2",
  storm: "IconStorm",
  "storm-off": "IconStormOff",
  stretching: "IconStretching",
  "stretching-2": "IconStretching2",
  strikethrough: "IconStrikethrough",
  submarine: "IconSubmarine",
  subscript: "IconSubscript",
  subtask: "IconSubtask",
  sum: "IconSum",
  "sum-off": "IconSumOff",
  sun: "IconSun",
  "sun-electricity": "IconSunElectricity",
  "sun-filled": "IconSunFilled",
  "sun-high": "IconSunHigh",
  "sun-low": "IconSunLow",
  "sun-moon": "IconSunMoon",
  "sun-off": "IconSunOff",
  "sun-wind": "IconSunWind",
  sunglasses: "IconSunglasses",
  "sunglasses-filled": "IconSunglassesFilled",
  sunrise: "IconSunrise",
  sunset: "IconSunset",
  "sunset-2": "IconSunset2",
  superscript: "IconSuperscript",
  svg: "IconSvg",
  swimming: "IconSwimming",
  swipe: "IconSwipe",
  "swipe-down": "IconSwipeDown",
  "swipe-down-filled": "IconSwipeDownFilled",
  "swipe-left": "IconSwipeLeft",
  "swipe-left-filled": "IconSwipeLeftFilled",
  "swipe-right": "IconSwipeRight",
  "swipe-right-filled": "IconSwipeRightFilled",
  "swipe-up": "IconSwipeUp",
  "swipe-up-filled": "IconSwipeUpFilled",
  switch: "IconSwitch",
  "switch-2": "IconSwitch2",
  "switch-3": "IconSwitch3",
  "switch-horizontal": "IconSwitchHorizontal",
  "switch-vertical": "IconSwitchVertical",
  sword: "IconSword",
  "sword-off": "IconSwordOff",
  swords: "IconSwords",
  table: "IconTable",
  "table-alias": "IconTableAlias",
  "table-column": "IconTableColumn",
  "table-down": "IconTableDown",
  "table-export": "IconTableExport",
  "table-filled": "IconTableFilled",
  "table-heart": "IconTableHeart",
  "table-import": "IconTableImport",
  "table-minus": "IconTableMinus",
  "table-off": "IconTableOff",
  "table-options": "IconTableOptions",
  "table-plus": "IconTablePlus",
  "table-row": "IconTableRow",
  "table-share": "IconTableShare",
  "table-shortcut": "IconTableShortcut",
  tag: "IconTag",
  "tag-filled": "IconTagFilled",
  "tag-off": "IconTagOff",
  "tag-starred": "IconTagStarred",
  tags: "IconTags",
  "tags-filled": "IconTagsFilled",
  "tags-off": "IconTagsOff",
  "tallymark-1": "IconTallymark1",
  "tallymark-2": "IconTallymark2",
  "tallymark-3": "IconTallymark3",
  "tallymark-4": "IconTallymark4",
  tallymarks: "IconTallymarks",
  tank: "IconTank",
  target: "IconTarget",
  "target-arrow": "IconTargetArrow",
  "target-off": "IconTargetOff",
  tax: "IconTax",
  "tax-euro": "IconTaxEuro",
  "tax-pound": "IconTaxPound",
  teapot: "IconTeapot",
  telescope: "IconTelescope",
  "telescope-off": "IconTelescopeOff",
  temperature: "IconTemperature",
  "temperature-celsius": "IconTemperatureCelsius",
  "temperature-fahrenheit": "IconTemperatureFahrenheit",
  "temperature-minus": "IconTemperatureMinus",
  "temperature-off": "IconTemperatureOff",
  "temperature-plus": "IconTemperaturePlus",
  "temperature-snow": "IconTemperatureSnow",
  "temperature-sun": "IconTemperatureSun",
  template: "IconTemplate",
  "template-off": "IconTemplateOff",
  tent: "IconTent",
  "tent-off": "IconTentOff",
  terminal: "IconTerminal",
  "terminal-2": "IconTerminal2",
  "test-pipe": "IconTestPipe",
  "test-pipe-2": "IconTestPipe2",
  "test-pipe-2-filled": "IconTestPipe2Filled",
  "test-pipe-off": "IconTestPipeOff",
  tex: "IconTex",
  "text-caption": "IconTextCaption",
  "text-color": "IconTextColor",
  "text-decrease": "IconTextDecrease",
  "text-direction-ltr": "IconTextDirectionLtr",
  "text-direction-rtl": "IconTextDirectionRtl",
  "text-grammar": "IconTextGrammar",
  "text-increase": "IconTextIncrease",
  "text-orientation": "IconTextOrientation",
  "text-plus": "IconTextPlus",
  "text-recognition": "IconTextRecognition",
  "text-resize": "IconTextResize",
  "text-scan-2": "IconTextScan2",
  "text-size": "IconTextSize",
  "text-spellcheck": "IconTextSpellcheck",
  "text-wrap": "IconTextWrap",
  "text-wrap-column": "IconTextWrapColumn",
  "text-wrap-disabled": "IconTextWrapDisabled",
  texture: "IconTexture",
  theater: "IconTheater",
  thermometer: "IconThermometer",
  "thumb-down": "IconThumbDown",
  "thumb-down-filled": "IconThumbDownFilled",
  "thumb-down-off": "IconThumbDownOff",
  "thumb-up": "IconThumbUp",
  "thumb-up-filled": "IconThumbUpFilled",
  "thumb-up-off": "IconThumbUpOff",
  "tic-tac": "IconTicTac",
  ticket: "IconTicket",
  "ticket-off": "IconTicketOff",
  tie: "IconTie",
  tilde: "IconTilde",
  "tilt-shift": "IconTiltShift",
  "tilt-shift-filled": "IconTiltShiftFilled",
  "tilt-shift-off": "IconTiltShiftOff",
  "time-duration-0": "IconTimeDuration0",
  "time-duration-10": "IconTimeDuration10",
  "time-duration-15": "IconTimeDuration15",
  "time-duration-30": "IconTimeDuration30",
  "time-duration-45": "IconTimeDuration45",
  "time-duration-5": "IconTimeDuration5",
  "time-duration-60": "IconTimeDuration60",
  "time-duration-90": "IconTimeDuration90",
  "time-duration-off": "IconTimeDurationOff",
  timeline: "IconTimeline",
  "timeline-event": "IconTimelineEvent",
  "timeline-event-exclamation": "IconTimelineEventExclamation",
  "timeline-event-filled": "IconTimelineEventFilled",
  "timeline-event-minus": "IconTimelineEventMinus",
  "timeline-event-plus": "IconTimelineEventPlus",
  "timeline-event-text": "IconTimelineEventText",
  "timeline-event-x": "IconTimelineEventX",
  timezone: "IconTimezone",
  "tip-jar": "IconTipJar",
  "tip-jar-euro": "IconTipJarEuro",
  "tip-jar-pound": "IconTipJarPound",
  tir: "IconTir",
  "toggle-left": "IconToggleLeft",
  "toggle-left-filled": "IconToggleLeftFilled",
  "toggle-right": "IconToggleRight",
  "toggle-right-filled": "IconToggleRightFilled",
  "toilet-paper": "IconToiletPaper",
  "toilet-paper-off": "IconToiletPaperOff",
  toml: "IconToml",
  tool: "IconTool",
  tools: "IconTools",
  "tools-kitchen": "IconToolsKitchen",
  "tools-kitchen-2": "IconToolsKitchen2",
  "tools-kitchen-2-off": "IconToolsKitchen2Off",
  "tools-kitchen-3": "IconToolsKitchen3",
  "tools-kitchen-off": "IconToolsKitchenOff",
  "tools-off": "IconToolsOff",
  tooltip: "IconTooltip",
  "topology-bus": "IconTopologyBus",
  "topology-complex": "IconTopologyComplex",
  "topology-full": "IconTopologyFull",
  "topology-full-hierarchy": "IconTopologyFullHierarchy",
  "topology-ring": "IconTopologyRing",
  "topology-ring-2": "IconTopologyRing2",
  "topology-ring-3": "IconTopologyRing3",
  "topology-star": "IconTopologyStar",
  "topology-star-2": "IconTopologyStar2",
  "topology-star-3": "IconTopologyStar3",
  "topology-star-ring": "IconTopologyStarRing",
  "topology-star-ring-2": "IconTopologyStarRing2",
  "topology-star-ring-3": "IconTopologyStarRing3",
  torii: "IconTorii",
  tornado: "IconTornado",
  tournament: "IconTournament",
  tower: "IconTower",
  "tower-off": "IconTowerOff",
  track: "IconTrack",
  tractor: "IconTractor",
  trademark: "IconTrademark",
  "traffic-cone": "IconTrafficCone",
  "traffic-cone-off": "IconTrafficConeOff",
  "traffic-lights": "IconTrafficLights",
  "traffic-lights-off": "IconTrafficLightsOff",
  train: "IconTrain",
  "transaction-bitcoin": "IconTransactionBitcoin",
  "transaction-dollar": "IconTransactionDollar",
  "transaction-euro": "IconTransactionEuro",
  "transaction-pound": "IconTransactionPound",
  "transaction-rupee": "IconTransactionRupee",
  "transaction-yen": "IconTransactionYen",
  "transaction-yuan": "IconTransactionYuan",
  transfer: "IconTransfer",
  "transfer-in": "IconTransferIn",
  "transfer-out": "IconTransferOut",
  "transfer-vertical": "IconTransferVertical",
  transform: "IconTransform",
  "transform-filled": "IconTransformFilled",
  "transform-point": "IconTransformPoint",
  "transform-point-bottom-left": "IconTransformPointBottomLeft",
  "transform-point-bottom-right": "IconTransformPointBottomRight",
  "transform-point-top-left": "IconTransformPointTopLeft",
  "transform-point-top-right": "IconTransformPointTopRight",
  "transition-bottom": "IconTransitionBottom",
  "transition-bottom-filled": "IconTransitionBottomFilled",
  "transition-left": "IconTransitionLeft",
  "transition-left-filled": "IconTransitionLeftFilled",
  "transition-right": "IconTransitionRight",
  "transition-right-filled": "IconTransitionRightFilled",
  "transition-top": "IconTransitionTop",
  "transition-top-filled": "IconTransitionTopFilled",
  trash: "IconTrash",
  "trash-filled": "IconTrashFilled",
  "trash-off": "IconTrashOff",
  "trash-x": "IconTrashX",
  "trash-x-filled": "IconTrashXFilled",
  treadmill: "IconTreadmill",
  tree: "IconTree",
  trees: "IconTrees",
  trekking: "IconTrekking",
  "trending-down": "IconTrendingDown",
  "trending-down-2": "IconTrendingDown2",
  "trending-down-3": "IconTrendingDown3",
  "trending-up": "IconTrendingUp",
  "trending-up-2": "IconTrendingUp2",
  "trending-up-3": "IconTrendingUp3",
  triangle: "IconTriangle",
  "triangle-filled": "IconTriangleFilled",
  "triangle-inverted": "IconTriangleInverted",
  "triangle-inverted-filled": "IconTriangleInvertedFilled",
  "triangle-minus": "IconTriangleMinus",
  "triangle-minus-2": "IconTriangleMinus2",
  "triangle-off": "IconTriangleOff",
  "triangle-plus": "IconTrianglePlus",
  "triangle-plus-2": "IconTrianglePlus2",
  "triangle-square-circle": "IconTriangleSquareCircle",
  "triangle-square-circle-filled": "IconTriangleSquareCircleFilled",
  triangles: "IconTriangles",
  trident: "IconTrident",
  trolley: "IconTrolley",
  trophy: "IconTrophy",
  "trophy-filled": "IconTrophyFilled",
  "trophy-off": "IconTrophyOff",
  trowel: "IconTrowel",
  truck: "IconTruck",
  "truck-delivery": "IconTruckDelivery",
  "truck-loading": "IconTruckLoading",
  "truck-off": "IconTruckOff",
  "truck-return": "IconTruckReturn",
  txt: "IconTxt",
  typeface: "IconTypeface",
  typography: "IconTypography",
  "typography-off": "IconTypographyOff",
  "u-turn-left": "IconUTurnLeft",
  "u-turn-right": "IconUTurnRight",
  ufo: "IconUfo",
  "ufo-off": "IconUfoOff",
  umbrella: "IconUmbrella",
  "umbrella-2": "IconUmbrella2",
  "umbrella-closed": "IconUmbrellaClosed",
  "umbrella-closed-2": "IconUmbrellaClosed2",
  "umbrella-filled": "IconUmbrellaFilled",
  "umbrella-off": "IconUmbrellaOff",
  underline: "IconUnderline",
  universe: "IconUniverse",
  unlink: "IconUnlink",
  upload: "IconUpload",
  urgent: "IconUrgent",
  usb: "IconUsb",
  user: "IconUser",
  "user-bitcoin": "IconUserBitcoin",
  "user-bolt": "IconUserBolt",
  "user-cancel": "IconUserCancel",
  "user-check": "IconUserCheck",
  "user-circle": "IconUserCircle",
  "user-code": "IconUserCode",
  "user-cog": "IconUserCog",
  "user-dollar": "IconUserDollar",
  "user-down": "IconUserDown",
  "user-edit": "IconUserEdit",
  "user-exclamation": "IconUserExclamation",
  "user-filled": "IconUserFilled",
  "user-heart": "IconUserHeart",
  "user-hexagon": "IconUserHexagon",
  "user-minus": "IconUserMinus",
  "user-off": "IconUserOff",
  "user-pause": "IconUserPause",
  "user-pentagon": "IconUserPentagon",
  "user-pin": "IconUserPin",
  "user-plus": "IconUserPlus",
  "user-question": "IconUserQuestion",
  "user-scan": "IconUserScan",
  "user-screen": "IconUserScreen",
  "user-search": "IconUserSearch",
  "user-share": "IconUserShare",
  "user-shield": "IconUserShield",
  "user-square": "IconUserSquare",
  "user-square-rounded": "IconUserSquareRounded",
  "user-star": "IconUserStar",
  "user-up": "IconUserUp",
  "user-x": "IconUserX",
  users: "IconUsers",
  "users-group": "IconUsersGroup",
  "users-minus": "IconUsersMinus",
  "users-plus": "IconUsersPlus",
  "uv-index": "IconUvIndex",
  "ux-circle": "IconUxCircle",
  vaccine: "IconVaccine",
  "vaccine-bottle": "IconVaccineBottle",
  "vaccine-bottle-off": "IconVaccineBottleOff",
  "vaccine-off": "IconVaccineOff",
  "vacuum-cleaner": "IconVacuumCleaner",
  variable: "IconVariable",
  "variable-minus": "IconVariableMinus",
  "variable-off": "IconVariableOff",
  "variable-plus": "IconVariablePlus",
  vector: "IconVector",
  "vector-bezier": "IconVectorBezier",
  "vector-bezier-2": "IconVectorBezier2",
  "vector-bezier-arc": "IconVectorBezierArc",
  "vector-bezier-circle": "IconVectorBezierCircle",
  "vector-off": "IconVectorOff",
  "vector-spline": "IconVectorSpline",
  "vector-triangle": "IconVectorTriangle",
  "vector-triangle-off": "IconVectorTriangleOff",
  venus: "IconVenus",
  versions: "IconVersions",
  "versions-filled": "IconVersionsFilled",
  "versions-off": "IconVersionsOff",
  video: "IconVideo",
  "video-minus": "IconVideoMinus",
  "video-off": "IconVideoOff",
  "video-plus": "IconVideoPlus",
  "view-360": "IconView360",
  "view-360-arrow": "IconView360Arrow",
  "view-360-number": "IconView360Number",
  "view-360-off": "IconView360Off",
  viewfinder: "IconViewfinder",
  "viewfinder-off": "IconViewfinderOff",
  "viewport-narrow": "IconViewportNarrow",
  "viewport-short": "IconViewportShort",
  "viewport-tall": "IconViewportTall",
  "viewport-wide": "IconViewportWide",
  vinyl: "IconVinyl",
  vip: "IconVip",
  "vip-off": "IconVipOff",
  virus: "IconVirus",
  "virus-off": "IconVirusOff",
  "virus-search": "IconVirusSearch",
  vocabulary: "IconVocabulary",
  "vocabulary-off": "IconVocabularyOff",
  volcano: "IconVolcano",
  volume: "IconVolume",
  "volume-2": "IconVolume2",
  "volume-3": "IconVolume3",
  "volume-off": "IconVolumeOff",
  vs: "IconVs",
  walk: "IconWalk",
  wall: "IconWall",
  "wall-off": "IconWallOff",
  wallet: "IconWallet",
  "wallet-off": "IconWalletOff",
  wallpaper: "IconWallpaper",
  "wallpaper-off": "IconWallpaperOff",
  wand: "IconWand",
  "wand-off": "IconWandOff",
  wash: "IconWash",
  "wash-dry": "IconWashDry",
  "wash-dry-1": "IconWashDry1",
  "wash-dry-2": "IconWashDry2",
  "wash-dry-3": "IconWashDry3",
  "wash-dry-a": "IconWashDryA",
  "wash-dry-dip": "IconWashDryDip",
  "wash-dry-f": "IconWashDryF",
  "wash-dry-flat": "IconWashDryFlat",
  "wash-dry-hang": "IconWashDryHang",
  "wash-dry-off": "IconWashDryOff",
  "wash-dry-p": "IconWashDryP",
  "wash-dry-shade": "IconWashDryShade",
  "wash-dry-w": "IconWashDryW",
  "wash-dryclean": "IconWashDryclean",
  "wash-dryclean-off": "IconWashDrycleanOff",
  "wash-eco": "IconWashEco",
  "wash-gentle": "IconWashGentle",
  "wash-hand": "IconWashHand",
  "wash-machine": "IconWashMachine",
  "wash-off": "IconWashOff",
  "wash-press": "IconWashPress",
  "wash-temperature-1": "IconWashTemperature1",
  "wash-temperature-2": "IconWashTemperature2",
  "wash-temperature-3": "IconWashTemperature3",
  "wash-temperature-4": "IconWashTemperature4",
  "wash-temperature-5": "IconWashTemperature5",
  "wash-temperature-6": "IconWashTemperature6",
  "wash-tumble-dry": "IconWashTumbleDry",
  "wash-tumble-off": "IconWashTumbleOff",
  waterpolo: "IconWaterpolo",
  "wave-saw-tool": "IconWaveSawTool",
  "wave-sine": "IconWaveSine",
  "wave-square": "IconWaveSquare",
  "waves-electricity": "IconWavesElectricity",
  webhook: "IconWebhook",
  "webhook-off": "IconWebhookOff",
  weight: "IconWeight",
  wheel: "IconWheel",
  wheelchair: "IconWheelchair",
  "wheelchair-off": "IconWheelchairOff",
  whirl: "IconWhirl",
  wifi: "IconWifi",
  "wifi-0": "IconWifi0",
  "wifi-1": "IconWifi1",
  "wifi-2": "IconWifi2",
  "wifi-off": "IconWifiOff",
  wind: "IconWind",
  "wind-electricity": "IconWindElectricity",
  "wind-off": "IconWindOff",
  windmill: "IconWindmill",
  "windmill-filled": "IconWindmillFilled",
  "windmill-off": "IconWindmillOff",
  window: "IconWindow",
  "window-maximize": "IconWindowMaximize",
  "window-minimize": "IconWindowMinimize",
  "window-off": "IconWindowOff",
  windsock: "IconWindsock",
  wiper: "IconWiper",
  "wiper-wash": "IconWiperWash",
  woman: "IconWoman",
  "woman-filled": "IconWomanFilled",
  wood: "IconWood",
  world: "IconWorld",
  "world-bolt": "IconWorldBolt",
  "world-cancel": "IconWorldCancel",
  "world-check": "IconWorldCheck",
  "world-code": "IconWorldCode",
  "world-cog": "IconWorldCog",
  "world-dollar": "IconWorldDollar",
  "world-down": "IconWorldDown",
  "world-download": "IconWorldDownload",
  "world-exclamation": "IconWorldExclamation",
  "world-heart": "IconWorldHeart",
  "world-latitude": "IconWorldLatitude",
  "world-longitude": "IconWorldLongitude",
  "world-minus": "IconWorldMinus",
  "world-off": "IconWorldOff",
  "world-pause": "IconWorldPause",
  "world-pin": "IconWorldPin",
  "world-plus": "IconWorldPlus",
  "world-question": "IconWorldQuestion",
  "world-search": "IconWorldSearch",
  "world-share": "IconWorldShare",
  "world-star": "IconWorldStar",
  "world-up": "IconWorldUp",
  "world-upload": "IconWorldUpload",
  "world-www": "IconWorldWww",
  "world-x": "IconWorldX",
  "wrecking-ball": "IconWreckingBall",
  writing: "IconWriting",
  "writing-off": "IconWritingOff",
  "writing-sign": "IconWritingSign",
  "writing-sign-off": "IconWritingSignOff",
  x: "IconX",
  "xbox-a": "IconXboxA",
  "xbox-a-filled": "IconXboxAFilled",
  "xbox-b": "IconXboxB",
  "xbox-b-filled": "IconXboxBFilled",
  "xbox-x": "IconXboxX",
  "xbox-x-filled": "IconXboxXFilled",
  "xbox-y": "IconXboxY",
  "xbox-y-filled": "IconXboxYFilled",
  xd: "IconXd",
  xxx: "IconXxx",
  "yin-yang": "IconYinYang",
  "yin-yang-filled": "IconYinYangFilled",
  yoga: "IconYoga",
  zeppelin: "IconZeppelin",
  "zeppelin-filled": "IconZeppelinFilled",
  "zeppelin-off": "IconZeppelinOff",
  zip: "IconZip",
  "zodiac-aquarius": "IconZodiacAquarius",
  "zodiac-aries": "IconZodiacAries",
  "zodiac-cancer": "IconZodiacCancer",
  "zodiac-capricorn": "IconZodiacCapricorn",
  "zodiac-gemini": "IconZodiacGemini",
  "zodiac-leo": "IconZodiacLeo",
  "zodiac-libra": "IconZodiacLibra",
  "zodiac-pisces": "IconZodiacPisces",
  "zodiac-sagittarius": "IconZodiacSagittarius",
  "zodiac-scorpio": "IconZodiacScorpio",
  "zodiac-taurus": "IconZodiacTaurus",
  "zodiac-virgo": "IconZodiacVirgo",
  zoom: "IconZoom",
  "zoom-cancel": "IconZoomCancel",
  "zoom-cancel-filled": "IconZoomCancelFilled",
  "zoom-check": "IconZoomCheck",
  "zoom-check-filled": "IconZoomCheckFilled",
  "zoom-code": "IconZoomCode",
  "zoom-code-filled": "IconZoomCodeFilled",
  "zoom-exclamation": "IconZoomExclamation",
  "zoom-exclamation-filled": "IconZoomExclamationFilled",
  "zoom-filled": "IconZoomFilled",
  "zoom-in": "IconZoomIn",
  "zoom-in-area": "IconZoomInArea",
  "zoom-in-area-filled": "IconZoomInAreaFilled",
  "zoom-in-filled": "IconZoomInFilled",
  "zoom-money": "IconZoomMoney",
  "zoom-money-filled": "IconZoomMoneyFilled",
  "zoom-out": "IconZoomOut",
  "zoom-out-area": "IconZoomOutArea",
  "zoom-out-area-filled": "IconZoomOutAreaFilled",
  "zoom-out-filled": "IconZoomOutFilled",
  "zoom-pan": "IconZoomPan",
  "zoom-pan-filled": "IconZoomPanFilled",
  "zoom-question": "IconZoomQuestion",
  "zoom-question-filled": "IconZoomQuestionFilled",
  "zoom-replace": "IconZoomReplace",
  "zoom-reset": "IconZoomReset",
  "zoom-scan": "IconZoomScan",
  "zoom-scan-filled": "IconZoomScanFilled",
  zzz: "IconZzz",
  "zzz-off": "IconZzzOff",
} as const;
